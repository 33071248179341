import localStorage from 'utils/localStorage';

const getPermutive = (): Array<string> | null => {
  if (!__CLIENT__) {
    return null;
  }
  return localStorage.getItem<Array<string>>('_pdfps', []);
};

export default getPermutive;
