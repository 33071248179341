const translations$1 = {
  en_AU: {
    Facebook: 'Facebook',
    Google: 'Google',
    'or connect with': 'or connect with',
    'Sign Up to Activate Device': 'Sign Up to Activate Device',
    'Region Not Supported': 'Region Not Supported',
    'Log In to Activate Device': 'Log In to Activate Device',
    '“Alexa, play z one hundred on iHeartRadio”': '“Alexa, play z one hundred on iHeartRadio”',
    '“Alexa, play Post Malone radio on iHeartRadio”': '“Alexa, play Post Malone radio on iHeartRadio”',
    '“Alexa, play Stuff You Should Know Podcast on iHeartRadio”': '“Alexa, play Stuff You Should Know Podcast on iHeartRadio”',
    'Your iHeartRadio account is now linked!': 'Your iHeartRadio account is now linked!',
    'Try these popular phrases from other listeners:': 'Try these popular phrases from other listeners:',
    'Please enter your activate code.': 'Please enter your activate code.',
    'Check your activation code and try again, or get a new activation code from your device.': 'Check your activation code and try again, or get a new activation code from your device.',
    'An unknown error occurred.': 'An unknown error occurred.',
    Activate: 'Activate',
    'Log in as a different user': 'Log in as a different user',
    'Activation Code': 'Activation Code',
    'Your iHeartRadio account is now available on your device.': 'Your iHeartRadio account is now available on your device.',
    'Do not sell or share my personal information': '',
    'Log In': 'Log In',
    'Wrong email or password. Please try again with a different email address or password': '',
    'Too many repeated log in attempts. Your account is locked temporarily. Please wait and try again later': '',
    'Sorry, an error occurred. Please try again later.': 'Sorry, an error occurred. Please try again later.',
    "Don't have an account?": "Don't have an account?",
    'Sign up': 'Sign up',
    'Forgot Password?': 'Forgot Password?',
    Email: 'Email',
    Password: 'Password',
    'Sorry for the inconvenience, but we do not allow account sign-up for your current location.': 'Sorry for the inconvenience, but we do not allow account sign-up for your current location.',
    'Visit our Help Center': 'Visit our Help Center',
    'Return to Log In': 'Return to Log In',
    Male: 'Male',
    Female: 'Female',
    'Prefer not to say': 'Prefer not to say',
    Other: 'Other',
    '8 to 32 characters': '',
    'an upper case and lower case letter': '',
    'a number (0-9)': '',
    Error: '',
    Success: '',
    Optional: '',
    'Password must have eight to thiry two characters': '',
    "Password doesn't have eight to thirty two characters": '',
    'Password has eight to thirty two characters': '',
    'Password must have an uppercase and lower case letter': '',
    "Password doesn't have an upper case and lower case letter": '',
    'Password has an upper case and lower case letter': '',
    'Password must have a number between zero and nine': '',
    "Password doesn't have a number between zero and nine": '',
    'password has a number between zero and nine': '',
    'Please fill out all form fields': 'Please fill out all form fields',
    'Username already exists.': 'Username already exists.',
    'Password is too weak': '',
    "Password shouldn't have common words or passwords": '',
    'Password can only have English letters, numbers, spaces, and special characters from: . ! ? - _ , ; : /  ( ) [ ] ~ @ # $ % ^ & * + = \\': '',
    'Password can only have English letters, numbers, spaces, and special characters from: dot, exclamation point, question mark, dash, underscore, comma, semicolon, colon, forward slash, backslash, open parenthesis, close parenthesis, open bracket, close bracket, tilde, at sign, number sign, dollar sign, percent sign, caret, ampersand, asterisk, plus sign, equal sign, apostrophe, quotation mark, grave accent, vertical bar, open curly bracket, close curly bracket, less than sign, and greater than sign.': '',
    'Invalid Email Address': 'Invalid Email Address',
    'Invalid password': '',
    'Password must have:': '',
    'Birth Year': 'Birth Year',
    'By creating an account, you also agree to our {termsOfService} and {privacyPolicy}.': 'By creating an account, you also agree to our {termsOfService} and {privacyPolicy}.',
    'Terms of Service': 'Terms of Service',
    'Privacy Policy': 'Privacy Policy',
    'Sign Up': 'Sign Up',
    'Postal Code': 'Postal Code',
    'ZIP code': 'ZIP code',
    'Invalid Postal Code': 'Invalid Postal Code',
    'Invalid ZIP Code': 'Invalid ZIP Code',
    'Sorry, you are not eligible to register for iHeartRadio': 'Sorry, you are not eligible to register for iHeartRadio',
    'Have an account?': 'Have an account?'
  },
  en_NZ: {
    Facebook: 'Facebook',
    Google: 'Google',
    'or connect with': 'or connect with',
    'Sign Up to Activate Device': 'Sign Up to Activate Device',
    'Region Not Supported': 'Region Not Supported',
    'Log In to Activate Device': 'Log In to Activate Device',
    '“Alexa, play z one hundred on iHeartRadio”': '“Alexa, play z one hundred on iHeartRadio”',
    '“Alexa, play Post Malone radio on iHeartRadio”': '“Alexa, play Post Malone radio on iHeartRadio”',
    '“Alexa, play Stuff You Should Know Podcast on iHeartRadio”': '“Alexa, play Stuff You Should Know Podcast on iHeartRadio”',
    'Your iHeartRadio account is now linked!': 'Your iHeartRadio account is now linked!',
    'Try these popular phrases from other listeners:': 'Try these popular phrases from other listeners:',
    'Please enter your activate code.': 'Please enter your activate code.',
    'Check your activation code and try again, or get a new activation code from your device.': 'Check your activation code and try again, or get a new activation code from your device.',
    'An unknown error occurred.': 'An unknown error occurred.',
    Activate: 'Activate',
    'Log in as a different user': 'Log in as a different user',
    'Activation Code': 'Activation Code',
    'Your iHeartRadio account is now available on your device.': 'Your iHeartRadio account is now available on your device.',
    'Do not sell or share my personal information': '',
    'Log In': 'Log In',
    'Wrong email or password. Please try again with a different email address or password': '',
    'Too many repeated log in attempts. Your account is locked temporarily. Please wait and try again later': '',
    'Sorry, an error occurred. Please try again later.': 'Sorry, an error occurred. Please try again later.',
    "Don't have an account?": "Don't have an account?",
    'Sign up': 'Sign up',
    'Forgot Password?': 'Forgot Password?',
    Email: 'Email',
    Password: 'Password',
    'Sorry for the inconvenience, but we do not allow account sign-up for your current location.': 'Sorry for the inconvenience, but we do not allow account sign-up for your current location.',
    'Visit our Help Center': 'Visit our Help Center',
    'Return to Log In': 'Return to Log In',
    Male: 'Male',
    Female: 'Female',
    'Prefer not to say': 'Prefer not to say',
    Other: 'Other',
    '8 to 32 characters': '',
    'an upper case and lower case letter': '',
    'a number (0-9)': '',
    Error: '',
    Success: '',
    Optional: '',
    'Password must have eight to thiry two characters': '',
    "Password doesn't have eight to thirty two characters": '',
    'Password has eight to thirty two characters': '',
    'Password must have an uppercase and lower case letter': '',
    "Password doesn't have an upper case and lower case letter": '',
    'Password has an upper case and lower case letter': '',
    'Password must have a number between zero and nine': '',
    "Password doesn't have a number between zero and nine": '',
    'password has a number between zero and nine': '',
    'Please fill out all form fields': 'Please fill out all form fields',
    'Username already exists.': 'Username already exists.',
    'Password is too weak': '',
    "Password shouldn't have common words or passwords": '',
    'Password can only have English letters, numbers, spaces, and special characters from: . ! ? - _ , ; : /  ( ) [ ] ~ @ # $ % ^ & * + = \\': '',
    'Password can only have English letters, numbers, spaces, and special characters from: dot, exclamation point, question mark, dash, underscore, comma, semicolon, colon, forward slash, backslash, open parenthesis, close parenthesis, open bracket, close bracket, tilde, at sign, number sign, dollar sign, percent sign, caret, ampersand, asterisk, plus sign, equal sign, apostrophe, quotation mark, grave accent, vertical bar, open curly bracket, close curly bracket, less than sign, and greater than sign.': '',
    'Invalid Email Address': 'Invalid Email Address',
    'Invalid password': '',
    'Password must have:': '',
    'Birth Year': 'Birth Year',
    'By creating an account, you also agree to our {termsOfService} and {privacyPolicy}.': 'By creating an account, you also agree to our {termsOfService} and {privacyPolicy}.',
    'Terms of Service': 'Terms of Service',
    'Privacy Policy': 'Privacy Policy',
    'Sign Up': 'Sign Up',
    'Postal Code': 'Postal Code',
    'ZIP code': 'ZIP code',
    'Invalid Postal Code': 'Invalid Postal Code',
    'Invalid ZIP Code': 'Invalid ZIP Code',
    'Sorry, you are not eligible to register for iHeartRadio': 'Sorry, you are not eligible to register for iHeartRadio',
    'Have an account?': 'Have an account?'
  },
  es: {
    Facebook: 'Facebook',
    Google: 'Google',
    'or connect with': 'o conéctate con',
    'Sign Up to Activate Device': 'Regístrate para activar el dispositivo',
    'Region Not Supported': 'Región no soportada',
    'Log In to Activate Device': 'Inicia sesión para activar el dispositivo',
    '“Alexa, play z one hundred on iHeartRadio”': '"Alexa, toca z one hundred en iHeartRadio"',
    '“Alexa, play Post Malone radio on iHeartRadio”': '"Alexa, toca la radio de Post Malone en iHeartRadio"',
    '“Alexa, play Stuff You Should Know Podcast on iHeartRadio”': 'Alexa, toca Stuff You Should Know Podcast en iHeartRadio"',
    'Your iHeartRadio account is now linked!': 'Tu cuenta de iHeartRadio está ahora asociada!',
    'Try these popular phrases from other listeners:': 'Intenta estas frases populares de otros usuarios:',
    'Please enter your activate code.': 'Por favor ingresa tu código de activación',
    'Check your activation code and try again, or get a new activation code from your device.': 'Revisa tu código de activación e intenta de nuevo, u obtén un nuevo código de activación en tu dispositivo',
    'An unknown error occurred.': 'Ocurrió un error desconocido',
    Activate: 'Activar',
    'Log in as a different user': 'Inicia sesión con un usuario diferente',
    'Activation Code': 'Código de activación',
    'Your iHeartRadio account is now available on your device.': 'Tu cuenta de iHeartRadio está ahora disponible en tu dispositivo.',
    'Do not sell or share my personal information': 'No vender mi información personal',
    'Log In': 'Iniciar sesión',
    'Wrong email or password. Please try again with a different email address or password': 'Correo o contraseña incorrectos. Por favor intenta nuevamente con un correo o contraseña diferente',
    'Too many repeated log in attempts. Your account is locked temporarily. Please wait and try again later': 'Muchos intentos de inicio de sesión. Tu cuenta ha sido bloqueada temporalmente. Por favor espera e intenta de nuevo más tarde. ',
    'Sorry, an error occurred. Please try again later.': 'Lo sentimos, ocurrió un error. Por favor, inténtalo de nuevo más tarde.',
    "Don't have an account?": '¿No tienes una cuenta?',
    'Sign up': 'Regístrate',
    'Forgot Password?': '¿Olvidaste tu contraseña?',
    Email: 'Correo electrónico',
    Password: 'Contraseña',
    'Sorry for the inconvenience, but we do not allow account sign-up for your current location.': 'Lamentamos el inconveniente, pero no aceptamos registro de usuario en tu ubicación actual.',
    'Visit our Help Center': 'Visita nuestro centro de ayuda',
    'Return to Log In': 'Regresar a inicio de sesión',
    Male: 'Masculino',
    Female: 'Femenino ',
    'Prefer not to say': 'Prefiero no decir',
    Other: 'Otro',
    '8 to 32 characters': '8 a 32 caracteres',
    'an upper case and lower case letter': 'una mayúscula y una minúscula',
    'a number (0-9)': 'un número (0-9)',
    Error: 'Error',
    Success: 'Correcto',
    Optional: 'Opcional',
    'Password must have eight to thiry two characters': 'La contraseña debe tener de 8 a 32 caracteres',
    "Password doesn't have eight to thirty two characters": 'La contraseña no tiene de 8 a 32 caracteres',
    'Password has eight to thirty two characters': 'La contraseña tiene de 8 a 32 caracteres',
    'Password must have an uppercase and lower case letter': 'La contraseña debe tener una mayúscula y una minúscula',
    "Password doesn't have an upper case and lower case letter": 'La contraseña no tiene una mayúscula ni una minúsula',
    'Password has an upper case and lower case letter': 'La contraseña tiene una mayúscula y una minúscula',
    'Password must have a number between zero and nine': 'La contraseña debe tener un número entre cero y nuevo.',
    "Password doesn't have a number between zero and nine": 'La contraseña no tiene un número entre cero y nueve. ',
    'password has a number between zero and nine': 'La contraseña tiene un número entre cero y nueve',
    'Please fill out all form fields': 'Por favor llena todos los campos del formulario',
    'Username already exists.': 'El nombre de usuario ya existe.',
    'Password is too weak': 'La contraseña es muy débil',
    "Password shouldn't have common words or passwords": 'La contraseña no debe tener palabras comunes.',
    'Password can only have English letters, numbers, spaces, and special characters from: . ! ? - _ , ; : /  ( ) [ ] ~ @ # $ % ^ & * + = \\': 'La contraseña solo puede tener letras, números, espacios y los siguientes caracteres especiales: . ! ? - _ , ; : / ( ) [ ] ~ @ # $ % ^ & * + = \\',
    'Password can only have English letters, numbers, spaces, and special characters from: dot, exclamation point, question mark, dash, underscore, comma, semicolon, colon, forward slash, backslash, open parenthesis, close parenthesis, open bracket, close bracket, tilde, at sign, number sign, dollar sign, percent sign, caret, ampersand, asterisk, plus sign, equal sign, apostrophe, quotation mark, grave accent, vertical bar, open curly bracket, close curly bracket, less than sign, and greater than sign.': 'La contraseña solo puede tener letras, números, espacios y los siguientes caracteres especiales: punto, signo de exclamación, signo de interrogación, guion, guion bajo, coma, punto y coma, dos puntos, barra oblicua, barra invertida, apertura de paréntesis, cierre de paréntesis, tilde, arroba, signo de número, signo de moneda, signo de porcentaje, intercalación, ampersand, asterisco, signo de más, signo de igual, apóstrofe, comillas, acento grave, barra vertical, apertura de corchete, cierre de corchete, signo menor que y signo mayor que.',
    'Invalid Email Address': 'Correo electrónico no válido',
    'Invalid password': 'Contraseña inválida',
    'Password must have:': 'La contraseña debe tener:',
    'Birth Year': 'Año de nac',
    'By creating an account, you also agree to our {termsOfService} and {privacyPolicy}.': 'Al crear una cuenta, también aceptas nuestros {termsOfService} y {privacyPolicy}.',
    'Terms of Service': 'Términos de servicio',
    'Privacy Policy': 'Política de Privacidad',
    'Sign Up': 'Regístrate',
    'Postal Code': 'Código Postal',
    'ZIP code': 'Código postal',
    'Invalid Postal Code': 'Código postal no válido',
    'Invalid ZIP Code': 'Código postal no válido',
    'Sorry, you are not eligible to register for iHeartRadio': 'Lo sentimos, usted no es elegible para registrarse en iHeartRadio',
    'Have an account?': '¿Tienes una cuenta?'
  },
  fr: {
    Facebook: 'Facebook',
    Google: 'Google',
    'or connect with': 'ou connectez-vous avec',
    'Sign Up to Activate Device': "Inscrivez-vous pour activer l'appareil",
    'Region Not Supported': 'Région non prise en charge',
    'Log In to Activate Device': "Connectez-vous pour activer l'appareil",
    '“Alexa, play z one hundred on iHeartRadio”': '« Alexa, joue z one hundred sur iHeartRadio »',
    '“Alexa, play Post Malone radio on iHeartRadio”': '« Alexa, joue Post Malone sur iHeartRadio »',
    '“Alexa, play Stuff You Should Know Podcast on iHeartRadio”': '« Alexa, joue le balado Stuff You Should Know sur iHeartRadio »',
    'Your iHeartRadio account is now linked!': 'Votre compte iHeartRadio a été lié!',
    'Try these popular phrases from other listeners:': "Essayez ces phrases populaires utilisateurs par d'autres utilisateurs :",
    'Please enter your activate code.': "Veuillez entrer votre code d'activation.",
    'Check your activation code and try again, or get a new activation code from your device.': "Vérifiez votre code d'activation et essayez de nouveau, ou demandez un nouveau code d'activation de votre appareil.",
    'An unknown error occurred.': 'Une erreur inconnue est survenue.',
    Activate: 'Activer',
    'Log in as a different user': 'Se connecter comme autre utilisateur',
    'Activation Code': "Code d'activation",
    'Your iHeartRadio account is now available on your device.': 'Votre compte iHeartRadio est maintenant disponible sur votre appareil.',
    'Do not sell or share my personal information': 'Ne pas vendre mon information personnelle',
    'Log In': 'Connexion',
    'Wrong email or password. Please try again with a different email address or password': 'Adresse courriel ou mot de passe invalide. Veuillez réessayer avec une autre adresse courriel ou un autre mot de passe ',
    'Too many repeated log in attempts. Your account is locked temporarily. Please wait and try again later': "Trop d'essais de connexion répétés. Votre compte est temporairement verrouillé. Veuillez attendre et réessayer plus tard.",
    'Sorry, an error occurred. Please try again later.': "Désolés, une erreur s'est produite. Veuillez réessayer plus tard.",
    "Don't have an account?": "Vous n'avez pas de compte?",
    'Sign up': 'Abonnez-vous',
    'Forgot Password?': 'Mot de passe oublié?',
    Email: 'Adresse courriel',
    Password: 'Mot de passe',
    'Sorry for the inconvenience, but we do not allow account sign-up for your current location.': "Désolés de l'inconvénient, mais nous ne permettons pas la création de comptes pour votre emplacement. ",
    'Visit our Help Center': "Consulter notre Centre d'aide",
    'Return to Log In': 'Revenir à la connexion',
    Male: 'Homme',
    Female: 'Femme',
    'Prefer not to say': 'Je préfère ne pas répondre',
    Other: 'Autre',
    '8 to 32 characters': '8 à 32 caractères',
    'an upper case and lower case letter': 'une lettre majuscule et une minuscule',
    'a number (0-9)': 'un chiffre (0-9)',
    Error: 'Erreur',
    Success: 'Succès',
    Optional: 'Optionel',
    'Password must have eight to thiry two characters': 'Le mot de passe doit comporter de huit à trente-deux caractères',
    "Password doesn't have eight to thirty two characters": 'Le mot de passe ne comporte pas de huit à trente-deux caractères',
    'Password has eight to thirty two characters': 'Le mot de passe comporte de huit à trente-deux caractères',
    'Password must have an uppercase and lower case letter': 'Le mot de passe doit comprendre une lettre majuscule et une minuscule',
    "Password doesn't have an upper case and lower case letter": 'Le mot de passe ne comprend pas une lettre majuscule et une minuscule',
    'Password has an upper case and lower case letter': 'Le mot de passe comprend une lettre majuscule et une minuscule',
    'Password must have a number between zero and nine': 'Le mot de passe doit comprendre un chiffre entre zéro et neuf',
    "Password doesn't have a number between zero and nine": 'Le mot de passe ne comprend pas un chiffre entre zéro et neuf',
    'password has a number between zero and nine': 'Le mot de passe comprend un chiffre entre zéro et neuf',
    'Please fill out all form fields': 'Veuillez remplir tous les champs du formulaire ',
    'Username already exists.': "Nom d'usager déjà pris.",
    'Password is too weak': "Le mot de passe n'est pas assez sécuritaire",
    "Password shouldn't have common words or passwords": 'Le mot de passe ne devrait pas être un mot ou un mot de passe commun',
    'Password can only have English letters, numbers, spaces, and special characters from: . ! ? - _ , ; : /  ( ) [ ] ~ @ # $ % ^ & * + = \\': 'Le mot de passe ne peut contenir que des lettres latines, des chiffres, des espaces et des caractères spéciaux de la liste suivante : . ! ? - _ , ; : / ( ) [ ] ~ @ # $ % ^ & * + = \\',
    'Password can only have English letters, numbers, spaces, and special characters from: dot, exclamation point, question mark, dash, underscore, comma, semicolon, colon, forward slash, backslash, open parenthesis, close parenthesis, open bracket, close bracket, tilde, at sign, number sign, dollar sign, percent sign, caret, ampersand, asterisk, plus sign, equal sign, apostrophe, quotation mark, grave accent, vertical bar, open curly bracket, close curly bracket, less than sign, and greater than sign.': 'Le mot de passe ne peut contenir que des lettres latines, des chiffres, des espaces et des caractères spéciaux de la liste suivante : point, point d\'exclamation, point d\'interrogation, tiret, trait de soulignement, virgule, point-virgule, deux-points, barre oblique avant, barre oblique arrière, parenthèse ouverte, parenthèse fermée, crochet ouvert, crochet fermé, dièse, arobase, signe de dollar, signe de pourcentage, caret, esperluette, astérisque, signe "plus", signe "égal", apostrophe, guillemet, accent grave, barre verticale, accolade ouverte, accolade fermée, signe "moins que" et signe "plus que".',
    'Invalid Email Address': 'Adresse courriel invalide',
    'Invalid password': 'Mot de passe non valide',
    'Password must have:': 'Le mot de passe doit comprendre :',
    'Birth Year': 'Année de naissance',
    'By creating an account, you also agree to our {termsOfService} and {privacyPolicy}.': 'En créant ce compte, vous acceptez également nos {termsOfService} et {privacyPolicy}.',
    'Terms of Service': "Conditions d'utilisation",
    'Privacy Policy': 'Politique de confidentialité',
    'Sign Up': 'Abonnez-vous',
    'Postal Code': 'Code postal',
    'ZIP code': 'Code postal',
    'Invalid Postal Code': 'Code postal invalide',
    'Invalid ZIP Code': 'Code postal invalide',
    'Sorry, you are not eligible to register for iHeartRadio': "Désolés, vous n'êtes pas éligible pour un compte iHeartRadio",
    'Have an account?': 'Vous avez déjà un compte?'
  },
  options: {
    plural_rule: 'n > 1',
    plural_number: '2'
  }
};

const translations = {
  en_AU: {
    Facebook: 'Facebook',
    Google: 'Google',
    'Region Not Supported': 'Region Not Supported',
    'Do not sell or share my personal information': '',
    'Log In': 'Log In',
    'Sorry, an error occurred. Please try again later.': 'Sorry, an error occurred. Please try again later.',
    "Don't have an account?": "Don't have an account?",
    'Sign up': 'Sign up',
    'Forgot Password?': 'Forgot Password?',
    Email: 'Email',
    Password: 'Password',
    'Sorry for the inconvenience, but we do not allow account sign-up for your current location.': 'Sorry for the inconvenience, but we do not allow account sign-up for your current location.',
    'Visit our Help Center': 'Visit our Help Center',
    'Return to Log In': 'Return to Log In',
    Male: 'Male',
    Female: 'Female',
    'Prefer not to say': 'Prefer not to say',
    Other: 'Other',
    '8 to 32 characters': '',
    'an upper case and lower case letter': '',
    'a number (0-9)': '',
    Error: '',
    Success: '',
    'Password must have eight to thiry two characters': '',
    "Password doesn't have eight to thirty two characters": '',
    'Password has eight to thirty two characters': '',
    'Password must have an uppercase and lower case letter': '',
    "Password doesn't have an upper case and lower case letter": '',
    'Password has an upper case and lower case letter': '',
    'Password must have a number between zero and nine': '',
    "Password doesn't have a number between zero and nine": '',
    'password has a number between zero and nine': '',
    'Please fill out all form fields': 'Please fill out all form fields',
    "Password shouldn't have common words or passwords": '',
    'Password can only have English letters, numbers, spaces, and special characters from: . ! ? - _ , ; : /  ( ) [ ] ~ @ # $ % ^ & * + = \\': '',
    'Invalid Email Address': 'Invalid Email Address',
    'Birth Year': 'Birth Year',
    'Terms of Service': 'Terms of Service',
    'Privacy Policy': 'Privacy Policy',
    'Sign Up': 'Sign Up',
    'Postal Code': 'Postal Code',
    'Invalid Postal Code': 'Invalid Postal Code',
    'Invalid ZIP Code': 'Invalid ZIP Code',
    'Have an account?': 'Have an account?',
    'Login unsuccessful. Please try again later.': 'Login unsuccessful. Please try again later.',
    'Update successful.': 'Update successful.',
    'Your account has been linked to {provider}.': 'Your account has been linked to {provider}.',
    'This {accountType} account is already linked to an iHeartRadio account': 'This {accountType} account is already linked to an iHeartRadio account',
    '{accountType} account linking failed. Please try again later.': '{accountType} account linking failed. Please try again later.',
    'Your account has been unlinked from {provider}.': 'Your account has been unlinked from {provider}.',
    '{accountType} account unlinking failed. Please try again later.': '{accountType} account unlinking failed. Please try again later.',
    'Login unsuccessful. Window closed before completion. Please try again.': 'Login unsuccessful. Window closed before completion. Please try again.',
    'Do You Have a Popup Blocker?': 'Do You Have a Popup Blocker?',
    "To log in with your Facebook or Google account, ensure you've allowed popups from www.iheart.com, refresh, and then try again.": "To log in with your Facebook or Google account, ensure you've allowed popups from www.iheart.com, refresh, and then try again.",
    'Feat. {artistNames} and more': 'Feat. {artistNames} and more',
    'Add to Playlist': 'Add to Playlist',
    'Episode Info': 'Episode Info',
    Lyrics: 'Lyrics',
    'Rename your Favorites Radio station now.': 'Rename your Favourites Radio station now.',
    'This song is not available to start your station.': 'This song is not available to start your station.',
    'Play this on web with All Access': 'Play this on web with All Access',
    'Subscribe today for unlimited access to playlists': 'Subscribe today for unlimited access to playlists',
    'Play the whole album with iHeart All Access.': 'Play the whole album with iHeart All Access.',
    'Listen History': 'Listen History',
    'Custom Stations Limit Exceeded': 'Custom Stations Limit Exceeded',
    'Please delete some stations from your account under {listenHistoryLink} before creating new stations.': 'Please delete some stations from your account under {listenHistoryLink} before creating new stations.',
    'Sorry, this station has run out of songs to play.': 'Sorry, this station has run out of songs to play.',
    Oops: 'Oops',
    "We don't have enough info to create a custom station right now. We'll let our DJs know.": "We don't have enough info to create a custom station right now. We'll let our DJs know.",
    'Replay failed': 'Replay failed',
    'An unknown error occurred. Please try again later.': 'An unknown error occurred. Please try again later.',
    'Update Account': 'Update Account',
    'Please enter a valid email': 'Please enter a valid email',
    'New Email': 'New Email',
    'Please enter the same email again': 'Please enter the same email again',
    'Confirm New Email': 'Confirm New Email',
    'Password must be 6 - 32 characters': 'Password must be 6 - 32 characters',
    'New Password (6-32 Characters)': 'New Password (6-32 Characters)',
    Confirm: 'Confirm',
    Cancel: 'Cancel',
    'Cannot add duplicate tracks to a playlist': 'Cannot add duplicate tracks to a playlist',
    'Added {contentType} to {playlistName}': 'Added {contentType} to {playlistName}',
    'Added song to {playlistName}': 'Added song to {playlistName}',
    Ok: 'Ok',
    'Success!': 'Success!',
    'Your email and password have been added to your account.': 'Your email and password have been added to your account.',
    'Your email has been saved.': 'Your email has been saved.',
    'Your contact information has been saved.': 'Your contact information has been saved.',
    'Try Again': 'Try Again',
    'For assistance, please visit our {helplink}.': 'For assistance, please visit our {helplink}.',
    'Help Link': 'Help Link',
    'Oops!': 'Oops!',
    'Get Started': 'Get Started',
    'You can now enjoy the full benefits of iHeart All Access for Family.': 'You can now enjoy the full benefits of iHeart All Access for Family.',
    'Welcome to the Family!': 'Welcome to the Family!',
    'Your password has been saved.': 'Your password has been saved.',
    'Station Not Available': 'Station Not Available',
    "We're sorry! This station is not available in your country.": "We're sorry! This station is not available in your country.",
    'Updated {updateDateAndTime}': 'Updated {updateDateAndTime}',
    'Please complete the CAPTCHA': 'Please complete the CAPTCHA',
    'Check Your Email': '',
    'Forgot Password': 'Forgot Password',
    'Enter your email address below to log in by email or reset your password.': 'Enter your email address below to log in by email or reset your password.',
    'Enter your email address below to reset your password.': 'Enter your email address below to reset your password.',
    "If an account exists, you'll receive a password reset link shortly. Check your spam folder if it takes more than a minute or two.": '',
    Okay: '',
    'Back to login': 'Back to login',
    Submit: 'Submit',
    'Or, {backToLoginLink}': 'Or, {backToLoginLink}',
    Gender: 'Gender',
    'Music and radio on demand': 'Music and radio on demand',
    'Please log in first.': 'Please log in first.',
    Login: 'Login',
    'To listen to thousands of live radio and artist stations, create a free account.': 'To listen to thousands of live radio and artist stations, create a free account.',
    'To listen to thousands of live radio stations, create a free account.': 'To listen to thousands of live radio stations, create a free account.',
    'Connect with your favorites': 'Connect with your favourites',
    'To easily find your favorite and recently listened to stations, create a free account.': 'To easily find your favourite and recently listened to stations, create a free account.',
    'Access your stations': 'Access your stations',
    'To view recently listened to stations and songs, create a free account.': 'To view recently listened to stations and songs, create a free account.',
    "See what you've been listening to": "See what you've been listening to",
    'To access your favorites anytime, anywhere, create a free account.': 'To access your favourites anytime, anywhere, create a free account.',
    'Save stations you love': 'Save stations you love',
    'To hear more music you like, create a free account.': 'To hear more music you like, create a free account.',
    'Personalize your listening': 'Personalize your listening',
    'To listen to this artist and more, without interruption, create your free account.': 'To listen to this artist and more, without interruption, create your free account.',
    'Listen to {stationName}': 'Listen to {stationName}',
    'Listen to Artist Stations': 'Listen to Artist Stations',
    'To listen to music based on this song, create your free account.': 'To listen to music based on this song, create your free account.',
    'Listen to songs like {stationName}': 'Listen to songs like {stationName}',
    'Listen to songs like this': 'Listen to songs like this',
    'To listen to this podcast and more, create your free account.': 'To listen to this podcast and more, create your free account.',
    'Listen to Podcasts': 'Listen to Podcasts',
    'To hear all your favorite songs and artists on your own personal station, create a free account.': 'To hear all your favourite songs and artists on your own personal station, create a free account.',
    'Listen to {stationName}!': 'Listen to {stationName}!',
    'Listen to Your Favorites Radio!': 'Listen to Your Favourites Radio!',
    'To listen, create a free account.': 'To listen, create a free account.',
    'Listen to Favorites Radio!': 'Listen to Favourites Radio!',
    'All your favorite music. All your favorite stations. All free.': 'All your favourite music. All your favourite stations. All free.',
    'Create Your iHeart Account': 'Create Your iHeart Account',
    'Please create a free account.': 'Please create a free account.',
    'Register first': 'Register first',
    'Create a FREE account to add songs and albums to your playlist': 'Create a FREE account to add songs and albums to your playlist',
    'Welcome to the All Access FREE Preview Weekend. Create your FREE account and listen to millions of songs, live radio, playlists, and podcasts – enjoy All Access, FREE this weekend only.': 'Welcome to the All Access FREE Preview Weekend. Create your FREE account and listen to millions of songs, live radio, playlists, and podcasts – enjoy All Access, FREE this weekend only.',
    'Your Halloween treat is here': 'Your Halloween treat is here',
    "To join the Family Plan you've been invited to, please create a free account": "To join the Family Plan you've been invited to, please create a free account",
    'Create a free account to join your Family Plan': 'Create a free account to join your Family Plan',
    'Please correct all password errors': '',
    'Sorry, you are not eligible to register for iHeart': 'Sorry, you are not eligible to register for iHeart',
    'Postal Code (optional)': '',
    'Zip Code': 'Zip Code',
    'Please enter a valid birth year': 'Please enter a valid birth year',
    'Receive email updates on stations I listen to and more from iHeart.': 'Receive email updates on stations I listen to and more from iHeart.',
    'By creating an account, you also agree to our {termsOfServiceLink} and {privacyPolicyLink}.': 'By creating an account, you also agree to our {termsOfServiceLink} and {privacyPolicyLink}.',
    'iHeartRadio is provided by iHeartMedia and Bell Media. This app collects info to locate (e.g., IP address), identify (e.g., web activities), and profile (e.g., iHeartRadio use) you to enhance the site, analyze usage, and enable marketing (e.g., contextual advertising). By registering, you consent to the processing of your personal information for these purposes and agree to the {termsOfService}, {iHeartPolicy} and {bellPolicy}.': '',
    'Or Connect With': 'Or Connect With',
    'Login with Facebook': 'Login with Facebook',
    'Login with Google': 'Login with Google',
    'Added By iHeart': 'Added By iHeart',
    'Suggestions based on the songs you added.': 'Suggestions based on the songs you added.',
    'You have All Access': 'You have All Access',
    'Upgrade to All Access': 'Upgrade to All Access',
    'Want to keep enjoying these perks after the weekend?': 'Want to keep enjoying these perks after the weekend?',
    'Listen offline, unlimited skips, save and play songs from the radio.': 'Listen offline, unlimited skips, save and play songs from the radio.',
    'Learn More': 'Learn More',
    'Start 30 Day Free Trial': 'Start 30 Day Free Trial',
    'We use cookies.': '',
    'This website uses cookies for the purposes of enhancing the site, analyzing usage, and marketing, including contextual advertising. If you click “Accept All Cookies,” you also consent to the use of cookies and processing of your personal information by iHeartMedia and Bell Media to locate (e.g., IP address), identify (e.g., web activities), and profile (e.g., iHeartRadio use) you for the purpose of targeted advertising. {termsOfService}, {iHeartPolicy} and {bellPolicy}.': '',
    'Accept All Cookies': '',
    'Cookies and Advertising': '',
    'No Thanks': '',
    Agree: '',
    'You will lose unlimited skips, replay, and access to your playlist': 'You will lose unlimited skips, replay, and access to your playlist',
    'You will lose offline listening, unlimited playlists, and replay': 'You will lose offline listening, unlimited playlists, and replay',
    'Are you sure you want to cancel?': 'Are you sure you want to cancel?',
    'Your subscription ends on {date}': 'Your subscription ends on {date}',
    'Your playlists will be waiting for you should you decide to subscribe again.': 'Your playlists will be waiting for you should you decide to subscribe again.',
    'Keep Current Plan': 'Keep Current Plan',
    'Got It': 'Got It',
    'Cancel Plan': 'Cancel Plan',
    'Trending - Just Now': 'Trending - Just Now',
    'Help Center': 'Help Center',
    'There was an error updating your email': 'There was an error updating your email',
    'Update Contact Information': 'Update Contact Information',
    'Please enter your first name': 'Please enter your first name',
    'Add your first name': 'Add your first name',
    'First Name': 'First Name',
    'Please enter your last name': 'Please enter your last name',
    'Add your last name': 'Add your last name',
    'Last Name': 'Last Name',
    'Please enter a valid phone number': 'Please enter a valid phone number',
    'Phone Number': 'Phone Number',
    'Please enter a valid postal code': '',
    'Visit Our {helpCenterLink}': 'Visit Our {helpCenterLink}',
    'This email address is already associated with an iHeart account': 'This email address is already associated with an iHeart account',
    'Emails must match': 'Emails must match',
    'Change Email': 'Change Email',
    'Please enter the same value again': 'Please enter the same value again',
    'Please enter your password': 'Please enter your password',
    'Or, Visit Our {helpCenterLink}': 'Or, Visit Our {helpCenterLink}',
    "Password doesn't match our records": '',
    "Uh-oh passwords don't match": '',
    'Your new password cannot be the same as your current password': '',
    'Password can only have English letters, numbers, spaces, and special characters from: dot, exclamation point, question mark, dash, underscore, comma, semicolon, colon, forward slash, backslash, open parenthesis, close parenthesis, open bracket, close bracket, tilde, at sign, number sign, dollar sign, percent sign, caret, ampersand, asterisk, plus sign, equal sign, apostrophe, quotation mark, grave accent, vertical bar, open curly bracket, close curly bracket, less than sign, and greater than sign': '',
    'Update Password': '',
    'Old Password': 'Old Password',
    'New Password': 'New Password',
    'Confirm Password': 'Confirm Password',
    Update: '',
    'Are you sure you want to continue?': 'Are you sure you want to continue?',
    'Signed Out': 'Signed Out',
    'You have been signed out. Please log back in.': 'You have been signed out. Please log back in.',
    '© {fourDigitYear} iHeartMedia, Inc.': '© {fourDigitYear} iHeartMedia, Inc.',
    Help: 'Help',
    'Terms of Use': 'Terms of Use',
    'Playlist "{playlistName}" created': 'Playlist "{playlistName}" created',
    'Create unlimited playlists with iHeart All Access.': 'Create unlimited playlists with iHeart All Access.',
    'There was an error creating your playlist': 'There was an error creating your playlist',
    'Device Limit': 'Device Limit',
    "This stream stopped because you're listening on another device.": "This stream stopped because you're listening on another device.",
    'Listen on this device': 'Listen on this device',
    'Got it': 'Got it',
    'Album saved to Your Library': 'Album saved to Your Library',
    'Save Album': 'Save Album',
    'Saved {name} to Your Library': 'Saved {name} to Your Library',
    'Removed {name} from Your Library': 'Removed {name} from Your Library',
    'Follow Artist': 'Follow Artist',
    'Remove Artist': 'Remove Artist',
    'Not for Me': 'Not for Me',
    Delete: 'Delete',
    'Follow station': 'Follow station',
    'Unfollow station': 'Unfollow station',
    Connect: 'Connect',
    'Call ': 'Call ',
    'Text ': 'Text ',
    'Edit Playlist': 'Edit Playlist',
    'Delete Playlist': 'Delete Playlist',
    'Unfollow Playlist': 'Unfollow Playlist',
    'Follow Playlist': 'Follow Playlist',
    'Followed {title}': 'Followed {title}',
    'Unfollowed {title}': 'Unfollowed {title}',
    'Follow Podcast': 'Follow Podcast',
    'Unfollow Podcast': 'Unfollow Podcast',
    'No added songs yet': 'No added songs yet',
    'Tap ': 'Tap ',
    Save: 'Save',
    ' on the player to add a song to My Playlist': ' on the player to add a song to My Playlist',
    'Click on the': 'Click on the',
    'button next to a song': 'button next to a song',
    'and select Add to Playlist.': 'and select Add to Playlist.',
    'Find Music': 'Find Music',
    'Uh oh, we can’t find the page you’re looking for.': 'Uh oh, we can’t find the page you’re looking for.',
    'How about this page instead: ': 'How about this page instead: ',
    'Read more': 'Read more',
    Explicit: 'Explicit',
    E: '',
    'Invite sent to {email}': 'Invite sent to {email}',
    'Add family member...': 'Add family member...',
    'email@example.com': 'email@example.com',
    'Send Invite': 'Send Invite',
    You: 'You',
    Active: 'Active',
    '{email} has been removed.': '{email} has been removed.',
    'Remove Member': 'Remove Member',
    'Cancel Invite': 'Cancel Invite',
    'Resend Invite': 'Resend Invite',
    'Invite was sent on ': 'Invite was sent on ',
    'Active ✓': 'Active ✓',
    'Pending...': 'Pending...',
    'Invitees must be 14 years or older': 'Invitees must be 14 years or older',
    Owner: 'Owner',
    Following: 'Following',
    Follow: 'Follow',
    'Music, radio and podcasts, all free. Listen online or download the iHeart App.': 'Music, radio and podcasts, all free. Listen online or download the iHeart App.',
    'Connect with Facebook': 'Connect with Facebook',
    'Connect with Twitter': 'Connect with Twitter',
    'iHeart Instagram': 'iHeart Instagram',
    'iHeart Youtube': 'iHeart Youtube',
    Explore: 'Explore',
    iHeart: 'iHeart',
    'Live Radio': 'Live Radio',
    Podcasts: 'Podcasts',
    'On Demand Podcasts': 'On Demand Podcasts',
    'Artist Radio': 'Artist Radio',
    Playlists: 'Playlists',
    Genres: 'Genres',
    'Browse by Genre': 'Browse by Genre',
    Exclusives: 'Exclusives',
    News: 'News',
    'iHeart News': 'iHeart News',
    Features: 'Features',
    'Featured Exclusives on iHeart': 'Featured Exclusives on iHeart',
    Events: 'Events',
    'iHeart Events': 'iHeart Events',
    Contests: 'Win',
    'iHeart Contests': 'iHeart Contests',
    Photos: 'Photos',
    'iHeart Photos': 'iHeart Photos',
    Information: 'Information',
    About: 'About',
    'About iHeart': 'About iHeart',
    Advertise: 'Advertise',
    'Advertise on iHeart': 'Advertise on iHeart',
    Blog: 'Blog',
    'iHeart Blog': 'iHeart Blog',
    'Brand Guidelines': 'Brand Guidelines',
    'iHeart Logo and Guidelines': 'iHeart Logo and Guidelines',
    'Contest Guidelines': 'Contest Guidelines',
    'iHeart Contest Rules': 'iHeart Contest Rules',
    'Subscription Offers': 'Subscription Offers',
    Jobs: 'Jobs',
    'iHeart Jobs': 'iHeart Jobs',
    'Get the App': 'Get the App',
    Automotive: 'Automotive',
    'iHeart in your house': 'iHeart in your house',
    Home: 'Home',
    'iHeart in your car': 'iHeart in your car',
    Mobile: 'Mobile',
    'iHeart on your phone or tablet': 'iHeart on your phone or tablet',
    Wearables: 'Wearables',
    'iHeart wearables': 'iHeart wearables',
    'Your Library': 'Your Library',
    'Saved {name} to {savedStationsLink}': 'Saved {name} to {savedStationsLink}',
    'Removed {name} from {savedStationsLink}': 'Removed {name} from {savedStationsLink}',
    'Followed {podcastName}': 'Followed {podcastName}',
    'Unfollowed {podcastName}': 'Unfollowed {podcastName}',
    "We won't play this song again on this station.": "We won't play this song again on this station.",
    'Thanks for your feedback.': 'Thanks for your feedback.',
    'favorite songs': 'favourite songs',
    "We'll play more like it. Check out your {favoriteSongsLink} to see everything you've liked.": "We'll play more like it. Check out your {favoriteSongsLink} to see everything you've liked.",
    "We'll let our DJs know you've heard enough of this song.": "We'll let our DJs know you've heard enough of this song.",
    "Check out your {favoriteSongsLink} to see everything you've liked.": "Check out your {favoriteSongsLink} to see everything you've liked.",
    "Glad you like it. We'll let our DJs know.": "Glad you like it. We'll let our DJs know.",
    "OK, we'll adjust your music mix.": "OK, we'll adjust your music mix.",
    'Favorites Radio': 'Favourites Radio',
    'Great, we’ll add this song to your own {favoritesRadioLink}!': 'Great, we’ll add this song to your own {favoritesRadioLink}!',
    'Great, we’ll play you more songs like this': 'Great, we’ll play you more songs like this',
    'We appreciate you letting us know.': 'We appreciate you letting us know.',
    'Nice! You’ll hear this song more often.': 'Nice! You’ll hear this song more often.',
    "You've reached your skip limit.": "You've reached your skip limit.",
    "We're sorry, you can't skip any more songs right now. You can still thumb down a song. Want to know more? Visit {helpLink}": "We're sorry, you can't skip any more songs right now. You can still thumb down a song. Want to know more? Visit {helpLink}",
    'Report this issue.': 'Report this issue.',
    "There's an error with episodes for this show. {reportLink}": "There's an error with episodes for this show. {reportLink}",
    "There's an error with this content. {reportLink}": "There's an error with this content. {reportLink}",
    'Playlist followed and saved to {link}': 'Playlist followed and saved to {link}',
    'Playlist unfollowed and removed from {link}': 'Playlist unfollowed and removed from {link}',
    'Click here': 'Click here',
    'Having trouble logging into iHeart with your Facebook or Google account? {clickHereLink} for help.': 'Having trouble logging into iHeart with your Facebook or Google account? {clickHereLink} for help.',
    More: 'More',
    Subscribe: 'Subscribe',
    Upgrade: 'Upgrade',
    'Upgrade Now': 'Upgrade Now',
    Settings: 'Settings',
    'Log Out': 'Log Out',
    Account: 'Account',
    'Sponsor Content': 'Sponsor Content',
    'Mark As Unplayed': 'Mark As Unplayed',
    'Mark as Played': 'Mark as Played',
    'This station is not currently available': 'This station is not currently available',
    'Please pick a different station or listen to this station using our free iHeart app': 'Please pick a different station or listen to this station using our free iHeart app',
    'Listen in App': 'Listen in App',
    "You haven't thumbed up any songs yet.": "You haven't thumbed up any songs yet.",
    'Favorite Songs': 'Favourite Songs',
    NEW: 'NEW',
    'Welcome to': 'Welcome to',
    'Your Favorites Radio!': 'Your Favourites Radio!',
    'All your favorites and thumbs up in one station. The more you interact with iHeart, the better it gets.': 'All your favorites and thumbs up in one station. The more you interact with iHeart, the better it gets.',
    'Please name your station now:': 'Please name your station now:',
    'On Air': 'On Air',
    Share: 'Share',
    'Go To Album': 'Go To Album',
    'Go To Artist': 'Go To Artist',
    'Go To Station': 'Go To Station',
    'Unfollow Station': 'Unfollow Station',
    'Follow Station': 'Follow Station',
    'Go To Your Library': 'Go To Your Library',
    'Go To Playlist': 'Go To Playlist',
    'Share Episode': '',
    'Share from': '',
    Transcript: '',
    Pause: 'Pause',
    Stop: 'Stop',
    Play: 'Play',
    'Play Newest': 'Play Newest',
    'Create unlimited playlists. Try iHeart All Access.': 'Create unlimited playlists. Try iHeart All Access.',
    'My Music': 'My Music',
    'Theme Radio': 'Theme Radio',
    Playlist: 'Playlist',
    'Playlist by': 'Playlist by',
    Podcast: 'Podcast',
    'Thanks for listening!': 'Thanks for listening!',
    Rename: 'Rename',
    Edit: 'Edit',
    'Add to Another Playlist': 'Add to Another Playlist',
    Shuffle: 'Shuffle',
    'Added By You': 'Added By You',
    'Recently Played': 'Recently Played',
    'You like {station} on Facebook': 'You like {station} on Facebook',
    'You listened to {station}': 'You listened to {station}',
    'You are near {city}': 'You are near {city}',
    'You like {genre}': 'You like {genre}',
    'Just For You': 'Just For You',
    'Popular On {iHeart}': 'Popular On {iHeart}',
    'Discover how you can save and replay music on your favorite radio stations, or download and listen offline to millions of songs!': 'Discover how you can save and replay music on your favourite radio stations, or download and listen offline to millions of songs!',
    'iHeart Plus & All Access - Upgrade to a Free Trial': 'iHeart Plus & All Access - Upgrade to a Free Trial',
    'Playlist deleted': 'Playlist deleted',
    'Select a song to replay': 'Select a song to replay',
    'Save Playlist': 'Save Playlist',
    All: 'All',
    'Live Stations': 'Live Stations',
    Artists: 'Artists',
    Albums: 'Albums',
    Songs: 'Songs',
    'artists, stations or podcasts': 'artists, stations or podcasts',
    'stations or podcasts': 'stations or podcasts',
    podcasts: 'podcasts',
    search: 'search',
    'Album{bulletSeparator}{artistName}': 'Album{bulletSeparator}{artistName}',
    'Top Result': 'Top Result',
    'Search Results': 'Search Results',
    'Show More Results': 'Show More Results',
    'Search iHeart for artists, stations or podcasts.': 'Search iHeart for artists, stations or podcasts.',
    'Search iHeart for stations or podcasts.': 'Search iHeart for stations or podcasts.',
    'Search iHeart for podcasts.': 'Search iHeart for podcasts.',
    'There are no results for "{searchQuery}"': 'There are no results for "{searchQuery}"',
    'Check your spelling or try another search': 'Check your spelling or try another search',
    'Show all Live Stations': 'Show all Live Stations',
    'Show all Artists': 'Show all Artists',
    'Show all Albums': 'Show all Albums',
    'Show all Songs': 'Show all Songs',
    'Show all Playlists': 'Show all Playlists',
    'Show all Podcasts': 'Show all Podcasts',
    'Show all {title}': 'Show all {title}',
    'Embed Widget': 'Embed Widget',
    Twitter: 'Twitter',
    'See {moreTiles} of {itemCount}': 'See {moreTiles} of {itemCount}',
    'See All': 'See All',
    'See Less': 'See Less',
    'For You': 'For You',
    'Get The App': 'Get The App',
    'Connect with ': 'Connect with ',
    'on your favorite social network ': 'on your favourite social network ',
    ' Song': ' Song',
    'Keep Thumbing!': 'Keep Thumbing!',
    'Thumbing up songs personalizes the stations you listen to and adds them to Your Favorites Radio.': 'Thumbing up songs personalizes the stations you listen to and adds them to Your Favourites Radio.',
    '1 song': '1 song',
    'All your favorite songs and artists.': 'All your favourite songs and artists.',
    'Show Less': 'Show Less',
    'Show More': 'Show More',
    Schedule: 'Schedule',
    'Listen offline': 'Listen offline',
    'Unlimited skips': 'Unlimited skips',
    'Save songs from radio to playlists': 'Save songs from radio to playlists',
    'Instantly replay songs from radio': 'Instantly replay songs from radio',
    'Create unlimited playlists': 'Create unlimited playlists',
    'Play millions of songs on demand': 'Play millions of songs on demand',
    'Now you can create your own playlists with All Access!': 'Now you can create your own playlists with All Access!',
    'Start Free 30 Day Trial': 'Start Free 30 Day Trial',
    'Subscribe to All Access': 'Subscribe to All Access',
    'is now better than ever!': 'is now better than ever!',
    'All your favorite music, podcasts, and radio stations available for free. Listen to thousands of live radio stations or create your own artist stations and playlists. Get the latest music and trending news, from your favorite artists and bands.': 'All your favourite music, podcasts, and radio stations available for free. Listen to thousands of live radio stations or create your own artist stations and playlists. Get the latest music and trending news, from your favourite artists and bands.',
    'Stream thousands of the best live radio stations and custom artist stations for FREE - all in one app.': 'Stream thousands of the best live radio stations and custom artist stations for FREE - all in one app.',
    'Stream thousands of the best live radio stations for FREE, plus unlimited music on demand - all in one app.': 'Stream thousands of the best live radio stations for FREE, plus unlimited music on demand - all in one app.',
    'Stream thousands of the best live radio stations for FREE - all in one app.': 'Stream thousands of the best live radio stations for FREE - all in one app.',
    'All Genres': 'All Genres',
    Featured: 'Featured',
    Popular: 'Popular',
    'You are now logged in as {profileName}': 'You are now logged in as {profileName}',
    Artist: 'Artist',
    'Song by': '',
    Song: 'Song',
    'Live Station': '',
    'Album by': '',
    Album: '',
    'Email or password is invalid. Please try again.': 'Email or password is invalid. Please try again.',
    'There was an issue playing the audio, please check your connection and try again.': 'There was an issue playing the audio, please check your connection and try again.',
    'There was a connection problem. Please reconnect and try again.': 'There was a connection problem. Please reconnect and try again.',
    'See More': 'See More',
    'all access': 'all access',
    'Best Playlists': 'Best Playlists',
    'concert pictures': 'concert pictures',
    'create playlists': 'create playlists',
    'custom radio station': 'custom radio station',
    'event pictures': 'event pictures',
    'favorite music': 'favourite music',
    'Music Playlists': 'Music Playlists',
    'my music': 'my music',
    'Now Playing': 'Now Playing',
    'On Demand': 'On Demand',
    'Online Playlists': 'Online Playlists',
    'paid music': 'paid music',
    'paid songs': 'paid songs',
    'personal radio station': 'personal radio station',
    'personalized radio station': 'personalized radio station',
    'play songs': 'play songs',
    'Top Playlists': 'Top Playlists',
    'recent photos': 'recent photos',
    'recent pictures': 'recent pictures',
    'saved music': 'saved music',
    'saved songs': 'saved songs',
    'top playlists': 'top playlists',
    App: 'App',
    Bands: 'Bands',
    Best: 'Best',
    Discover: 'Discover',
    Download: 'Download',
    Free: 'Free',
    Influencers: 'Influencers',
    Influences: 'Influences',
    Internet: 'Internet',
    Listen: 'Listen',
    Live: 'Live',
    Music: 'Music',
    Online: 'Online',
    Personalized: 'Personalized',
    photos: 'photos',
    pictures: 'pictures',
    Radio: 'Radio',
    Related: 'Related',
    Similar: 'Similar',
    Station: 'Station',
    Stations: 'Stations',
    stream: 'stream',
    Streaming: 'Streaming',
    Talk: 'Talk',
    Top: 'Top',
    January: 'January',
    February: 'February',
    March: 'March',
    April: 'April',
    May: 'May',
    June: 'June',
    July: 'July',
    August: 'August',
    September: 'September',
    October: 'October',
    November: 'November',
    December: 'December',
    Jan: 'Jan',
    Feb: 'Feb',
    Mar: 'Mar',
    Apr: 'Apr',
    Jun: 'Jun',
    Jul: 'Jul',
    Aug: 'Aug',
    Sep: 'Sep',
    Oct: 'Oct',
    Nov: 'Nov',
    Dec: 'Dec',
    'Password must have': '',
    'iHeart: Listen to Free Radio Stations & Music Online | iHeart': 'iHeart: Listen to Free Radio Stations & Music Online | iHeart',
    '1 Song': '1 Song',
    'Listen to music you love! Create a custom radio station based on your favorite album by {artistName} on iHeartRadio!': 'Listen to music you love! Create a custom radio station based on your favourite album by {artistName} on iHeartRadio!',
    'Stream Free Music from Albums by {artistName}': 'Stream Free Music from Albums by {artistName}',
    'Create Artist Radio Stations': 'Create Artist Radio Stations',
    'Popular {genre} Artists': 'Popular {genre} Artists',
    'Artist Radio gives you the power to create custom stations from your favorite songs, albums or musical artists. Listen for free on iHeart!': 'Artist Radio gives you the power to create custom stations from your favorite songs, albums or musical artists. Listen for free on iHeart!',
    'Create a personalized custom music station from thousands of {name} artists you love, only on iHeart!': 'Create a personalized custom music station from thousands of {name} artists you love, only on iHeart!',
    'Create Custom Radio Stations on Artist Radio!': 'Create Custom Radio Stations on Artist Radio!',
    'Listen to the Best {name} Music for Free on iHeart': 'Listen to the Best {name} Music for Free on iHeart',
    '{artistName}': '{artistName}',
    '{name}, News, Music, Headlines, iHeartRadio, iHeart, Radio': '{name}, News, Music, Headlines, iHeartRadio, iHeart, Radio',
    'Latest {name} news from iHeart': 'Latest {name} news from iHeart',
    'Read the latest News on {name} Online': 'Read the latest News on {name} Online',
    Biography: 'Biography',
    'Similar Artists': 'Similar Artists',
    'Latest Release': 'Latest Release',
    'Popular On': 'Popular On',
    "This artist doesn't have any top songs.": "This artist doesn't have any top songs.",
    'Top Songs': 'Top Songs',
    'Stream Music from Artists Like {artistName}': 'Stream Music from Artists Like {artistName}',
    "Listen to music you'll love! Create a custom radio station from your favorite songs by {artistName} on iHeart.": "Listen to music you'll love! Create a custom radio station from your favorite songs by {artistName} on iHeart.",
    'Stream Free Songs by {artistName} & Similar Artists': 'Stream Free Songs by {artistName} & Similar Artists',
    'Almost there! Verify your zipcode to unlock your spot on the Family Plan': 'Almost there! Verify your zipcode to unlock your spot on the Family Plan',
    'In order to add you to the plan, we need to make sure that you live at the same address as the plan owner. Please input your zipcode below to verify and unlock your account.': 'In order to add you to the plan, we need to make sure that you live at the same address as the plan owner. Please input your zipcode below to verify and unlock your account.',
    'Household Zipcode': 'Household Zipcode',
    'e.g 90210...': 'e.g 90210...',
    'help site': 'help site',
    "It looks like you're already subscribed": "It looks like you're already subscribed",
    "You must cancel your active subscription before you can continue. You can find out how to cancel your subscription at {supportLink}. Once you've cancelled your current subscription plan you will need to wait until your current subscription plan ends.": "You must cancel your active subscription before you can continue. You can find out how to cancel your subscription at {supportLink}. Once you've cancelled your current subscription plan you will need to wait until your current subscription plan ends.",
    'If you have any questions, please {helplink} contact us.': 'If you have any questions, please {helplink} contact us.',
    'Please create a free account, or sign in': 'Please create a free account, or sign in',
    'To accept the Family Plan invitation, you must be logged in.': 'To accept the Family Plan invitation, you must be logged in.',
    'Welcome to Your Favorites Radio, a personalized station containing your favorite songs and artists. The more you thumb up and save stations on iHeart, the better your listening experience will be.': 'Welcome to Your Favorites Radio, a personalized station containing your favorite songs and artists. The more you thumb up and save stations on iHeart, the better your listening experience will be.',
    'Listen to {title}. Thumb up songs to add them to your own Your Favorites Radio station. Find it under My Stations.': 'Listen to {title}. Thumb up songs to add them to your own Your Favorites Radio station. Find it under My Stations.',
    'Featured Artists On This Station': 'Featured Artists On This Station',
    "{name}'s Favorites Radio": "{name}'s Favourites Radio",
    'iHeartRadio Mobile Apps': 'iHeartRadio Mobile Apps',
    'Made for You': 'Made for You',
    'Listen to Stations For You': 'Listen to Stations For You',
    'Change genre preferences {prefLink}': 'Change genre preferences {prefLink}',
    here: 'here',
    'iHeart: Listen to Free Radio Stations & Music Online': 'iHeart: Listen to Free Radio Stations & Music Online',
    'All Your Favorite Music. All Your Favorite Stations. All FREE. Listen to thousands of live stations or create your own artist stations. Let the music play!': 'All Your Favourite Music. All Your Favourite Stations. All FREE. Listen to thousands of live stations or create your own artist stations. Let the music play!',
    'Improve Your Recommendations': 'Improve Your Recommendations',
    'Change Preferences': 'Change Preferences',
    'Change Genre Preferences': 'Change Genre Preferences',
    'Find Stations by Genre': 'Find Stations by Genre',
    'Finding your ideal station is quick and easy. Search for stations by genre & stream them online for free on iHeart.': 'Finding your ideal station is quick and easy. Search for stations by genre & stream them online for free on iHeart.',
    'Discover Music and Radio Stations by Genre on iHeart': 'Discover Music and Radio Stations by Genre on iHeart',
    'Pick Genre': 'Pick Genre',
    'Tell us all the genres you like.': 'Tell us all the genres you like.',
    "We'll suggest stations just For You.": "We'll suggest stations just For You.",
    'Get Stations': 'Get Stations',
    'Find and stream {genreName} music stations for free, only on iHeart.': 'Find and stream {genreName} music stations for free, only on iHeart.',
    'Listen to {genreName} Radio Stations for Free': 'Listen to {genreName} Radio Stations for Free',
    'Find {genreName} Stations': 'Find {genreName} Stations',
    'Featured {genreName} Stations': 'Featured {genreName} Stations',
    'Top {genreName} Stations': 'Top {genreName} Stations',
    'Top {genreName} Artists': 'Top {genreName} Artists',
    'More Genres': 'More Genres',
    'Latest News': 'Latest News',
    'Partner Content': 'Partner Content',
    'All Cities': 'All Cities',
    'for {currentGenre}': 'for {currentGenre}',
    'in {currentMarket}': 'in {currentMarket}',
    'near you': 'near you',
    'Find top stations {genreFilterDescription} {marketFilterDescription} {noFiltersMessage} ({numStations})': 'Find top stations {genreFilterDescription} {marketFilterDescription} {noFiltersMessage} ({numStations})',
    'Listen to Live Radio': 'Listen to Live Radio',
    'No stations currently available for this filter': 'No stations currently available for this filter',
    'Listen to the Best Live Radio Stations in {countryAbbreviation}': 'Listen to the Best Live Radio Stations in {countryAbbreviation}',
    'Listen to {genreName} Radio Stations in {city}': 'Listen to {genreName} Radio Stations in {city}',
    'Listen to Top Radio Stations in {city}, Free': 'Listen to Top Radio Stations in {city}, Free',
    'Live {genreName} Radio Stations in {countryAbbreviation}': 'Live {genreName} Radio Stations in {countryAbbreviation}',
    'Listen to hundreds of the best live radio stations{countryName}, for free! Search for stations near you & around the country.': 'Listen to hundreds of the best live radio stations{countryName}, for free! Search for stations near you & around the country.',
    'Listen to the Best Live Radio{country} for Free on iHeart.': 'Listen to the Best Live Radio{country} for Free on iHeart.',
    'Stream the best live {genreName} radio stations in {city} online for free, only on iHeart!': 'Stream the best live {genreName} radio stations in {city} online for free, only on iHeart!',
    'Listen to Top {genreName} Radio Stations in {city}': 'Listen to Top {genreName} Radio Stations in {city}',
    'Listen to the best live radio stations in {city}. Stream online for free, only on iHeart!': 'Listen to the best live radio stations in {city}. Stream online for free, only on iHeart!',
    'Listen to Top Radio Stations in {city} for Free': 'Listen to Top Radio Stations in {city} for Free',
    'Stream the best live {genreName} radio stations in {countryAbbreviation} for free on iHeart.': 'Stream the best live {genreName} radio stations in {countryAbbreviation} for free on iHeart.',
    'Listen to Free, Live {genreName} Radio Stations in {countryAbbreviation} on iHeart': 'Listen to Free, Live {genreName} Radio Stations in {countryAbbreviation} on iHeart',
    'Contests and Promotions': 'Contests and Promotions',
    'If you like {stationName}...': 'If you like {stationName}...',
    'Similar Stations': 'Similar Stations',
    '{stationName}, {stateAbbreviation}, {city}, {genre}': '{stationName}, {stateAbbreviation}, {city}, {genre}',
    Genre: 'Genre',
    Location: 'Location',
    'On Air Schedule': 'On Air Schedule',
    'Share Song': 'Share Song',
    'Get the latest music news - rock, pop, hip-hop, rock, alternative, and country music news from iHeart. Get the latest music and trending news, from your favorite artists and bands.': 'Get the latest music news - rock, pop, hip-hop, rock, alternative, and country music news from iHeart. Get the latest music and trending news, from your favourite artists and bands.',
    'Breaking Music News, Celeb and Entertainment News and Gossip': 'Breaking Music News, Celeb and Entertainment News and Gossip',
    'Advertise With Us': 'Advertise With Us',
    'Discover thousands of top playlists with songs handcrafted by your favorite personalities and music experts on iHeartRadio.': 'Discover thousands of top playlists with songs handcrafted by your favourite personalities and music experts on iHeartRadio.',
    'Listen to the Best Online Music Playlists': 'Listen to the Best Online Music Playlists',
    'Recommended For You': 'Recommended For You',
    'Moods & Activities': 'Moods & Activities',
    Decades: 'Decades',
    'Featured Playlists': 'Featured Playlists',
    By: 'By',
    'Your songs shuffled with songs we think you’ll like. The more songs you add, the better our picks get.': 'Your songs shuffled with songs we think you’ll like. The more songs you add, the better our picks get.',
    'Introducing a new playlist experience': 'Introducing a new playlist experience',
    'Upgrade for this playlist': 'Upgrade for this playlist',
    'Song saved to Your Library': 'Song saved to Your Library',
    'Song removed from playlist': '',
    'Go to Artist': 'Go to Artist',
    'Go to Album': 'Go to Album',
    'Remove from Playlist': 'Remove from Playlist',
    SONG: 'SONG',
    'ALBUM TITLE': 'ALBUM TITLE',
    ARTIST: 'ARTIST',
    DURATION: 'DURATION',
    Done: 'Done',
    'Discover and stream the best {categoryTitle} playlists, handcrafted by the music experts at iHeart.': 'Discover and stream the best {categoryTitle} playlists, handcrafted by the music experts at iHeart.',
    '{categoryTitle}, {categoryTitle} Playlists, Stream {categoryTitle} Music, Play {categoryTitle} Songs, Top {categoryTitle} Playlists, Best { categoryTitle} Playlists': '{categoryTitle}, {categoryTitle} Playlists, Stream {categoryTitle} Music, Play {categoryTitle} Songs, Top {categoryTitle} Playlists, Best { categoryTitle} Playlists',
    'Top {categoryTitle} Playlists; Find Your Perfect Music Mix': 'Top {categoryTitle} Playlists; Find Your Perfect Music Mix',
    Host: 'Host',
    'iHeart Podcast Networks': 'iHeart Podcast Networks',
    '{name}, Blog, News, Music, Headlines, iHeartRadio, iHeart, Radio': '{name}, Blog, News, Music, Headlines, iHeartRadio, iHeart, Radio',
    '{name} Blog, News & Videos': '{name} Blog, News & Videos',
    'Go behind the scenes, get exclusive stories and learn more about the show!': 'Go behind the scenes, get exclusive stories and learn more about the show!',
    Played: 'Played',
    'Listen to the best {categoryName} radio shows, free and on demand, only on iHeart.': 'Listen to the best {categoryName} radio shows, free and on demand, only on iHeart.',
    'Listen to the Best Free {categoryName} Podcasts': 'Listen to the Best Free {categoryName} Podcasts',
    'On Demand, On-Demand, Show, Shows, Personalities, Programs, Listen, iHeartRadio, iHeart': 'On Demand, On-Demand, Show, Shows, Personalities, Programs, Listen, iHeartRadio, iHeart',
    'Discover Top Podcasts': 'Discover Top Podcasts',
    'Listen to the latest episodes from top podcasts. Get news, sports & lifestyle info from your favorite personalities for free!': 'Listen to the latest episodes from top podcasts. Get news, sports & lifestyle info from your favourite personalities for free!',
    'Listen to the Best Podcasts Online for Free on iHeart': 'Listen to the Best Podcasts Online for Free on iHeart',
    'Listen to the Best Podcasts & Shows Online, Free': 'Listen to the Best Podcasts & Shows Online, Free',
    'Talk, Show, Host, Personality, On Demand, Topic, Category, Health, News, Politics, Comedy, Station, Internet, Online, iHeartRadio, iHeart': 'Talk, Show, Host, Personality, On Demand, Topic, Category, Health, News, Politics, Comedy, Station, Internet, Online, iHeartRadio, iHeart',
    'Stream Top Podcasts': 'Stream Top Podcasts',
    'All Topics': 'All Topics',
    '{name} Podcasts': '{name} Podcasts',
    Topics: 'Topics',
    'All Episodes': 'All Episodes',
    "These episodes aren't here yet...": "These episodes aren't here yet...",
    "Follow this podcast to be notified when they're ready": "Follow this podcast to be notified when they're ready",
    'Share Podcast': '',
    'The Latest News': 'The Latest News',
    'Listen to {episodeTitle} | {showTitle} | Podcasts': 'Listen to {episodeTitle} | {showTitle} | Podcasts',
    '{episodeTitle},{showTitle},Talk,Radio,Listen,On Demand,iHeartRadio,iHeart': '{episodeTitle},{showTitle},Talk,Radio,Listen,On Demand,iHeartRadio,iHeart',
    '{title}, Talk, Radio, Listen, On Demand, iHeartRadio, iHeart': '{title}, Talk, Radio, Listen, On Demand, iHeartRadio, iHeart',
    Episodes: 'Episodes',
    'Episode Transcript': '',
    'Available transcripts are automatically generated. Complete accuracy is not guaranteed.': '',
    'Error Loading Transcript': '',
    'We had a problem rendering this page, please try refreshing your browser': '',
    'Refresh the page': '',
    'Report this issue': 'Report this issue',
    'Popular Podcasts': 'Popular Podcasts',
    'Stream the best podcasts from your favorite stations': 'Stream the best podcasts from your favourite stations',
    'Follow Us On': 'Follow Us On',
    'Show Links': 'Show Links',
    'Settings | See Your Music Profile': 'Settings | See Your Music Profile',
    'Log in and see which live radio stations and custom artist stations you love. You can also view your listening history. All free.': 'Log in and see which live radio stations and custom artist stations you love. You can also view your listening history. All free.',
    Connections: 'Connections',
    Unlink: 'Unlink',
    Link: 'Link',
    'Genre added.': 'Genre added.',
    'Genre removed.': 'Genre removed.',
    "You've added {genreName} to your genres.": "You've added {genreName} to your genres.",
    "You've removed {genreName} from your genres.": "You've removed {genreName} from your genres.",
    'Your preferences have been changed.': 'Your preferences have been changed.',
    'Not provided': 'Not provided',
    'Publish my listening activity to my Facebook music dashboard': 'Publish my listening activity to my Facebook music dashboard',
    'Update your password, genre selection, and modify your subscription below.': 'Update your password, genre selection, and modify your subscription below.',
    "You're currently signed in with a social account, add an email and password to have full access to your account": "You're currently signed in with a social account, add an email and password to have full access to your account",
    'Add New Email': 'Add New Email',
    'Contact Information': 'Contact Information',
    'Update your contact information to personalize your experience and easily enter iHeart contests and promotions.': 'Update your contact information to personalize your experience and easily enter iHeart contests and promotions.',
    'First and Last Name': 'First and Last Name',
    'Add your full name': 'Add your full name',
    'Add your postal code': '',
    'My Genres': 'My Genres',
    'Tell us all your favorite genres so we can make iHeart perfect for you.': 'Tell us all your favorite genres so we can make iHeart perfect for you.',
    'View Recommendations': 'View Recommendations',
    'Billing History': 'Billing History',
    'Subscribe Again': 'Subscribe Again',
    'Update Payment': 'Update Payment',
    Unknown: 'Unknown',
    'Unknown Plan Description': 'Unknown Plan Description',
    'iHeart Family Plan': 'iHeart Family Plan',
    'You are enjoying an unlimited music experience with an iHeart Family Plan.': 'You are enjoying an unlimited music experience with an iHeart Family Plan.',
    'iHeart All Access': 'iHeart All Access',
    'You are enjoying an unlimited music experience by subscribing to iHeart All Access.': 'You are enjoying an unlimited music experience by subscribing to iHeart All Access.',
    'iHeart Plus': 'iHeart Plus',
    'You are subscribed to iHeart Plus. Switch to iHeart All Access to enjoy an unlimited music experience on your computer.': 'You are subscribed to iHeart Plus. Switch to iHeart All Access to enjoy an unlimited music experience on your computer.',
    'iHeart Free': 'iHeart Free',
    'Subscribe to enjoy unlimited music.': 'Subscribe to enjoy unlimited music.',
    'Your subscription ends on {subscriptionEndsString}.': 'Your subscription ends on {subscriptionEndsString}.',
    'Family Members': 'Family Members',
    Subscription: 'Subscription',
    'Your Favorite Songs | Log In & See': 'Your Favourite Songs | Log In & See',
    'Log in or sign up and find the songs you love here, only on iHeart.': 'Log in or sign up and find the songs you love here, only on iHeart.',
    'iHeart Codes Coupons and Special Offers for Unlimited Music': 'iHeart Codes Coupons and Special Offers for Unlimited Music',
    'Find iHeart Radio special offers, codes and coupons for unlimited music on demand and all access to iHeart Plus. These special offers provide access to millions of songs, thousands of radio and artists stations with unlimited skips and offline listening!': 'Find iHeart Radio special offers, codes and coupons for unlimited music on demand and all access to iHeart Plus. These special offers provide access to millions of songs, thousands of radio and artists stations with unlimited skips and offline listening!',
    'There was an error validating your request.': 'There was an error validating your request.',
    'Please enter a valid email address': 'Please enter a valid email address',
    'Please fix the following errors:': 'Please fix the following errors:',
    'Start Your Free 30 Day Trial': 'Start Your Free 30 Day Trial',
    'Start Your Family Plan': 'Start Your Family Plan',
    'Sign up for iHeart All Access Annual Plan': 'Sign up for iHeart All Access Annual Plan',
    View: 'View',
    'Offer Terms': 'Offer Terms',
    'Email Address': 'Email Address',
    'Enter email address': 'Enter email address',
    'Card Details': 'Card Details',
    'Zip / Postal Code': 'Zip / Postal Code',
    'Enter Here': 'Enter Here',
    'Yes, please automatically renew my subscription annually (subject to {termsLink})': 'Yes, please automatically renew my subscription annually (subject to {termsLink})',
    'terms & conditions': 'terms & conditions',
    'Charges will appear on your credit card statement as "IHEART BILLING"': 'Charges will appear on your credit card statement as "IHEART BILLING"',
    'You can cancel at any time by going to "Settings" and following the cancellation instructions. By clicking "Subscribe" below, you agree to the conditions above and the iHeart ': 'You can cancel at any time by going to "Settings" and following the cancellation instructions. By clicking "Subscribe" below, you agree to the conditions above and the iHeart ',
    Processing: 'Processing',
    'Enter your email address below to log in by email or reset your password': '',
    email: 'email',
    Send: 'Send',
    'Don’t use common passwords, like “Password123!”': '',
    'Your password reset link is invalid or expired. Please try': '',
    'resetting your password': '',
    'again.': '',
    'Create a New Password': 'Create a New Password',
    'Your password reset link has expired': '',
    'An unknown error occured. Please try again later': '',
    'Your password has been updated': 'Your password has been updated',
    'Confirm New Password': '',
    Create: 'Create',
    'Custom radio stations based off your favorite artists and bands.': 'Custom radio stations based off your favourite artists and bands.',
    'View More Artists': 'View More Artists',
    'Bring iHeart with you anywhere': 'Bring iHeart with you anywhere',
    'The free iHeart app is available on over 200 platforms, including Web, iOS, Android, Alexa, automobiles and more.': 'The free iHeart app is available on over 200 platforms, including Web, iOS, Android, Alexa, automobiles and more.',
    'Download on the': 'Download on the',
    'Download on': 'Download on',
    'View All Apps': 'View All Apps',
    'Upcoming Events': 'Upcoming Events',
    'Listen to Your Favorite Music, Podcasts, and Radio Stations for Free! – iHeart': 'Listen to Your Favorite Music, Podcasts, and Radio Stations for Free! – iHeart',
    'iHeart. All your favorite music, podcasts, and radio stations available for free. Listen to thousands of live radio stations or create your own artist stations and playlists. Get the latest music and trending news, from your favorite artists and bands.': 'iHeart. All your favorite music, podcasts, and radio stations available for free. Listen to thousands of live radio stations or create your own artist stations and playlists. Get the latest music and trending news, from your favorite artists and bands.',
    'Get Started Free': 'Get Started Free',
    'All your favorite music, radio and podcasts, all free.': 'All your favourite music, radio and podcasts, all free.',
    'Your browser does not support video playback': 'Your browser does not support video playback',
    'The best live stations from around the country, any time, any place.': 'The best live stations from around the country, any time, any place.',
    'View More Live Stations': 'View More Live Stations',
    'New on iHeart': 'New on iHeart',
    'Latest Music News': 'Latest Music News',
    'View More Music News': 'View More Music News',
    'Popular and exclusive podcasts in a variety of genres and topics.': 'Popular and exclusive podcasts in a variety of genres and topics.',
    'View More Podcasts': 'View More Podcasts',
    '{count} song{plural}': '{count} song{plural}',
    'Browse Artists': 'Browse Artists',
    'Need to find a great new album?': 'Need to find a great new album?',
    'Any albums you save will show up here': 'Any albums you save will show up here',
    'Need to find an artist you love?': 'Need to find an artist you love?',
    'Any artists you follow will show up here': 'Any artists you follow will show up here',
    'Access your music library and create unlimited playlists with iHeart All Access': 'Access your music library and create unlimited playlists with iHeart All Access',
    'song{pluralSongs}': 'song{pluralSongs}',
    by: 'by',
    'Browse Playlists': 'Browse Playlists',
    'Need a playlist to fit your mood?': 'Need a playlist to fit your mood?',
    'Any playlists you follow will show up here': 'Any playlists you follow will show up here',
    'Browse Podcasts': 'Browse Podcasts',
    'Need a great new podcast to start?': 'Need a great new podcast to start?',
    'Any podcasts you follow will show up here': 'Any podcasts you follow will show up here',
    'Any radio, music, or podcasts you listen to will show up here': 'Any radio, music, or podcasts you listen to will show up here',
    'Browse Stations For You': 'Browse Stations For You',
    'Any podcasts you listen to will show up here': 'Any podcasts you listen to will show up here',
    'Need help finding something new?': 'Need help finding something new?',
    'Remove station': 'Remove station',
    '{name} removed from Your Library': '{name} removed from Your Library',
    'Followed Stations': 'Followed Stations',
    'Browse Live Radio': 'Browse Live Radio',
    'Browse Artist Radio': 'Browse Artist Radio',
    'Need help finding stations to save?': 'Need help finding stations to save?',
    'Your followed live and artist radio stations will show up here': 'Your followed live and artist radio stations will show up here',
    '{albumName} removed from Your Library': '{albumName} removed from Your Library',
    Remove: 'Remove',
    '{artistName} removed from Your Library': '{artistName} removed from Your Library',
    'ARTIST IN YOUR LIBRARY': 'ARTIST IN YOUR LIBRARY',
    'Add to playlist': 'Add to playlist',
    'Play Now': 'Play Now',
    '{songTitle} removed from Your Library': '{songTitle} removed from Your Library',
    'Need to find a new song?': 'Need to find a new song?',
    'Any song you save will show up here': 'Any song you save will show up here',
    'Playlist Radio': 'Playlist Radio',
    'View All': 'View All',
    Copied: 'Copied',
    'Read more on iHeartRadio': 'Read more on iHeartRadio',
    'Link Copied!': 'Link Copied!',
    'Copy Link': 'Copy Link',
    'Code Copied!': 'Code Copied!',
    Embed: 'Embed',
    'Follow on iHeartRadio': 'Follow on iHeartRadio',
    'Playback not supported by device or browser.': 'Playback not supported by device or browser.',
    'Method {method} not supported.': 'Method {method} not supported.',
    'View iHeart.com': 'View iHeart.com',
    "Oops, that wasn't supposed to happen.": "Oops, that wasn't supposed to happen.",
    "Oops, this feature isn't supported yet.": "Oops, this feature isn't supported yet.",
    'Oops! We had problems playing this stream.': 'Oops! We had problems playing this stream.',
    'This station is not currently available in your country.': 'This station is not currently available in your country.',
    Retry: 'Retry',
    'View Troubleshooting': 'View Troubleshooting',
    hello: 'hello',
    'my friend': 'my friend',
    'Share this live station': 'Share this live station',
    'Create your own on iHeart': 'Create your own on iHeart',
    "We're sorry! This playlist is not available in your country.": "We're sorry! This playlist is not available in your country.",
    'Playlist Not Available': 'Playlist Not Available',
    'This playlist is unsupported in your current country.': 'This playlist is unsupported in your current country.',
    'Playback Unsupported In Current Country': 'Playback Unsupported In Current Country',
    'Featured artists in this playlist': 'Featured artists in this playlist',
    'View Playlist': 'View Playlist',
    'Share this episode': 'Share this episode',
    'Listen to more episodes': 'Listen to more episodes',
    'This show has no episodes available yet.': '',
    'Share this podcast': 'Share this podcast'
  },
  en_NZ: {
    Facebook: 'Facebook',
    Google: 'Google',
    'Region Not Supported': 'Region Not Supported',
    'Do not sell or share my personal information': '',
    'Log In': 'Log In',
    'Sorry, an error occurred. Please try again later.': 'Sorry, an error occurred. Please try again later.',
    "Don't have an account?": "Don't have an account?",
    'Sign up': 'Sign up',
    'Forgot Password?': 'Forgot Password?',
    Email: 'Email',
    Password: 'Password',
    'Sorry for the inconvenience, but we do not allow account sign-up for your current location.': 'Sorry for the inconvenience, but we do not allow account sign-up for your current location.',
    'Visit our Help Center': 'Visit our Help Center',
    'Return to Log In': 'Return to Log In',
    Male: 'Male',
    Female: 'Female',
    'Prefer not to say': 'Prefer not to say',
    Other: 'Other',
    '8 to 32 characters': '',
    'an upper case and lower case letter': '',
    'a number (0-9)': '',
    Error: '',
    Success: '',
    'Password must have eight to thiry two characters': '',
    "Password doesn't have eight to thirty two characters": '',
    'Password has eight to thirty two characters': '',
    'Password must have an uppercase and lower case letter': '',
    "Password doesn't have an upper case and lower case letter": '',
    'Password has an upper case and lower case letter': '',
    'Password must have a number between zero and nine': '',
    "Password doesn't have a number between zero and nine": '',
    'password has a number between zero and nine': '',
    'Please fill out all form fields': 'Please fill out all form fields',
    "Password shouldn't have common words or passwords": '',
    'Password can only have English letters, numbers, spaces, and special characters from: . ! ? - _ , ; : /  ( ) [ ] ~ @ # $ % ^ & * + = \\': '',
    'Invalid Email Address': 'Invalid Email Address',
    'Birth Year': 'Birth Year',
    'Terms of Service': 'Terms of Service',
    'Privacy Policy': 'Privacy Policy',
    'Sign Up': 'Sign Up',
    'Postal Code': 'Postal Code',
    'Invalid Postal Code': 'Invalid Postal Code',
    'Invalid ZIP Code': 'Invalid ZIP Code',
    'Have an account?': 'Have an account?',
    'Login unsuccessful. Please try again later.': 'Login unsuccessful. Please try again later.',
    'Update successful.': 'Update successful.',
    'Your account has been linked to {provider}.': 'Your account has been linked to {provider}.',
    'This {accountType} account is already linked to an iHeartRadio account': 'This {accountType} account is already linked to an iHeartRadio account',
    '{accountType} account linking failed. Please try again later.': '{accountType} account linking failed. Please try again later.',
    'Your account has been unlinked from {provider}.': 'Your account has been unlinked from {provider}.',
    '{accountType} account unlinking failed. Please try again later.': '{accountType} account unlinking failed. Please try again later.',
    'Login unsuccessful. Window closed before completion. Please try again.': 'Login unsuccessful. Window closed before completion. Please try again.',
    'Do You Have a Popup Blocker?': 'Do You Have a Popup Blocker?',
    "To log in with your Facebook or Google account, ensure you've allowed popups from www.iheart.com, refresh, and then try again.": "To log in with your Facebook or Google account, ensure you've allowed popups from www.iheart.com, refresh, and then try again.",
    'Feat. {artistNames} and more': 'Feat. {artistNames} and more',
    'Add to Playlist': 'Add to Playlist',
    'Episode Info': 'Episode Info',
    Lyrics: 'Lyrics',
    'Rename your Favorites Radio station now.': 'Rename your Favourites Radio station now.',
    'This song is not available to start your station.': 'This song is not available to start your station.',
    'Play this on web with All Access': 'Play this on web with All Access',
    'Subscribe today for unlimited access to playlists': 'Subscribe today for unlimited access to playlists',
    'Play the whole album with iHeart All Access.': 'Play the whole album with iHeart All Access.',
    'Listen History': 'Listen History',
    'Custom Stations Limit Exceeded': 'Custom Stations Limit Exceeded',
    'Please delete some stations from your account under {listenHistoryLink} before creating new stations.': 'Please delete some stations from your account under {listenHistoryLink} before creating new stations.',
    'Sorry, this station has run out of songs to play.': 'Sorry, this station has run out of songs to play.',
    Oops: 'Oops',
    "We don't have enough info to create a custom station right now. We'll let our DJs know.": "We don't have enough info to create a custom station right now. We'll let our DJs know.",
    'Replay failed': 'Replay failed',
    'An unknown error occurred. Please try again later.': 'An unknown error occurred. Please try again later.',
    'Update Account': 'Update Account',
    'Please enter a valid email': 'Please enter a valid email',
    'New Email': 'New Email',
    'Please enter the same email again': 'Please enter the same email again',
    'Confirm New Email': 'Confirm New Email',
    'Password must be 6 - 32 characters': 'Password must be 6 - 32 characters',
    'New Password (6-32 Characters)': 'New Password (6-32 Characters)',
    Confirm: 'Confirm',
    Cancel: 'Cancel',
    'Cannot add duplicate tracks to a playlist': 'Cannot add duplicate tracks to a playlist',
    'Added {contentType} to {playlistName}': 'Added {contentType} to {playlistName}',
    'Added song to {playlistName}': 'Added song to {playlistName}',
    Ok: 'Ok',
    'Success!': 'Success!',
    'Your email and password have been added to your account.': 'Your email and password have been added to your account.',
    'Your email has been saved.': 'Your email has been saved.',
    'Your contact information has been saved.': 'Your contact information has been saved.',
    'Try Again': 'Try Again',
    'For assistance, please visit our {helplink}.': 'For assistance, please visit our {helplink}.',
    'Help Link': 'Help Link',
    'Oops!': 'Oops!',
    'Get Started': 'Get Started',
    'You can now enjoy the full benefits of iHeart All Access for Family.': 'You can now enjoy the full benefits of iHeart All Access for Family.',
    'Welcome to the Family!': 'Welcome to the Family!',
    'Your password has been saved.': 'Your password has been saved.',
    'Station Not Available': 'Station Not Available',
    "We're sorry! This station is not available in your country.": "We're sorry! This station is not available in your country.",
    'Updated {updateDateAndTime}': 'Updated {updateDateAndTime}',
    'Please complete the CAPTCHA': 'Please complete the CAPTCHA',
    'Check Your Email': '',
    'Forgot Password': 'Forgot Password',
    'Enter your email address below to log in by email or reset your password.': 'Enter your email address below to log in by email or reset your password.',
    'Enter your email address below to reset your password.': 'Enter your email address below to reset your password.',
    "If an account exists, you'll receive a password reset link shortly. Check your spam folder if it takes more than a minute or two.": '',
    Okay: '',
    'Back to login': 'Back to login',
    Submit: 'Submit',
    'Or, {backToLoginLink}': 'Or, {backToLoginLink}',
    Gender: 'Gender',
    'Music and radio on demand': 'Music and radio on demand',
    'Please log in first.': 'Please log in first.',
    Login: 'Login',
    'To listen to thousands of live radio and artist stations, create a free account.': 'To listen to thousands of live radio and artist stations, create a free account.',
    'To listen to thousands of live radio stations, create a free account.': 'To listen to thousands of live radio stations, create a free account.',
    'Connect with your favorites': 'Connect with your favourites',
    'To easily find your favorite and recently listened to stations, create a free account.': 'To easily find your favourite and recently listened to stations, create a free account.',
    'Access your stations': 'Access your stations',
    'To view recently listened to stations and songs, create a free account.': 'To view recently listened to stations and songs, create a free account.',
    "See what you've been listening to": "See what you've been listening to",
    'To access your favorites anytime, anywhere, create a free account.': 'To access your favourites anytime, anywhere, create a free account.',
    'Save stations you love': 'Save stations you love',
    'To hear more music you like, create a free account.': 'To hear more music you like, create a free account.',
    'Personalize your listening': 'Personalize your listening',
    'To listen to this artist and more, without interruption, create your free account.': 'To listen to this artist and more, without interruption, create your free account.',
    'Listen to {stationName}': 'Listen to {stationName}',
    'Listen to Artist Stations': 'Listen to Artist Stations',
    'To listen to music based on this song, create your free account.': 'To listen to music based on this song, create your free account.',
    'Listen to songs like {stationName}': 'Listen to songs like {stationName}',
    'Listen to songs like this': 'Listen to songs like this',
    'To listen to this podcast and more, create your free account.': 'To listen to this podcast and more, create your free account.',
    'Listen to Podcasts': 'Listen to Podcasts',
    'To hear all your favorite songs and artists on your own personal station, create a free account.': 'To hear all your favourite songs and artists on your own personal station, create a free account.',
    'Listen to {stationName}!': 'Listen to {stationName}!',
    'Listen to Your Favorites Radio!': 'Listen to Your Favourites Radio!',
    'To listen, create a free account.': 'To listen, create a free account.',
    'Listen to Favorites Radio!': 'Listen to Favourites Radio!',
    'All your favorite music. All your favorite stations. All free.': 'All your favourite music. All your favourite stations. All free.',
    'Create Your iHeart Account': 'Create Your iHeart Account',
    'Please create a free account.': 'Please create a free account.',
    'Register first': 'Register first',
    'Create a FREE account to add songs and albums to your playlist': 'Create a FREE account to add songs and albums to your playlist',
    'Welcome to the All Access FREE Preview Weekend. Create your FREE account and listen to millions of songs, live radio, playlists, and podcasts – enjoy All Access, FREE this weekend only.': 'Welcome to the All Access FREE Preview Weekend. Create your FREE account and listen to millions of songs, live radio, playlists, and podcasts – enjoy All Access, FREE this weekend only.',
    'Your Halloween treat is here': 'Your Halloween treat is here',
    "To join the Family Plan you've been invited to, please create a free account": "To join the Family Plan you've been invited to, please create a free account",
    'Create a free account to join your Family Plan': 'Create a free account to join your Family Plan',
    'Please correct all password errors': '',
    'Sorry, you are not eligible to register for iHeart': 'Sorry, you are not eligible to register for iHeart',
    'Postal Code (optional)': '',
    'Zip Code': 'Zip Code',
    'Please enter a valid birth year': 'Please enter a valid birth year',
    'Receive email updates on stations I listen to and more from iHeart.': 'Receive email updates on stations I listen to and more from iHeart.',
    'By creating an account, you also agree to our {termsOfServiceLink} and {privacyPolicyLink}.': 'By creating an account, you also agree to our {termsOfServiceLink} and {privacyPolicyLink}.',
    'iHeartRadio is provided by iHeartMedia and Bell Media. This app collects info to locate (e.g., IP address), identify (e.g., web activities), and profile (e.g., iHeartRadio use) you to enhance the site, analyze usage, and enable marketing (e.g., contextual advertising). By registering, you consent to the processing of your personal information for these purposes and agree to the {termsOfService}, {iHeartPolicy} and {bellPolicy}.': '',
    'Or Connect With': 'Or Connect With',
    'Login with Facebook': 'Login with Facebook',
    'Login with Google': 'Login with Google',
    'Added By iHeart': 'Added By iHeart',
    'Suggestions based on the songs you added.': 'Suggestions based on the songs you added.',
    'You have All Access': 'You have All Access',
    'Upgrade to All Access': 'Upgrade to All Access',
    'Want to keep enjoying these perks after the weekend?': 'Want to keep enjoying these perks after the weekend?',
    'Listen offline, unlimited skips, save and play songs from the radio.': 'Listen offline, unlimited skips, save and play songs from the radio.',
    'Learn More': 'Learn More',
    'Start 30 Day Free Trial': 'Start 30 Day Free Trial',
    'We use cookies.': '',
    'This website uses cookies for the purposes of enhancing the site, analyzing usage, and marketing, including contextual advertising. If you click “Accept All Cookies,” you also consent to the use of cookies and processing of your personal information by iHeartMedia and Bell Media to locate (e.g., IP address), identify (e.g., web activities), and profile (e.g., iHeartRadio use) you for the purpose of targeted advertising. {termsOfService}, {iHeartPolicy} and {bellPolicy}.': '',
    'Accept All Cookies': '',
    'Cookies and Advertising': '',
    'No Thanks': '',
    Agree: '',
    'You will lose unlimited skips, replay, and access to your playlist': 'You will lose unlimited skips, replay, and access to your playlist',
    'You will lose offline listening, unlimited playlists, and replay': 'You will lose offline listening, unlimited playlists, and replay',
    'Are you sure you want to cancel?': 'Are you sure you want to cancel?',
    'Your subscription ends on {date}': 'Your subscription ends on {date}',
    'Your playlists will be waiting for you should you decide to subscribe again.': 'Your playlists will be waiting for you should you decide to subscribe again.',
    'Keep Current Plan': 'Keep Current Plan',
    'Got It': 'Got It',
    'Cancel Plan': 'Cancel Plan',
    'Trending - Just Now': 'Trending - Just Now',
    'Help Center': 'Help Center',
    'There was an error updating your email': 'There was an error updating your email',
    'Update Contact Information': 'Update Contact Information',
    'Please enter your first name': 'Please enter your first name',
    'Add your first name': 'Add your first name',
    'First Name': 'First Name',
    'Please enter your last name': 'Please enter your last name',
    'Add your last name': 'Add your last name',
    'Last Name': 'Last Name',
    'Please enter a valid phone number': 'Please enter a valid phone number',
    'Phone Number': 'Phone Number',
    'Please enter a valid postal code': '',
    'Visit Our {helpCenterLink}': 'Visit Our {helpCenterLink}',
    'This email address is already associated with an iHeart account': 'This email address is already associated with an iHeart account',
    'Emails must match': 'Emails must match',
    'Change Email': 'Change Email',
    'Please enter the same value again': 'Please enter the same value again',
    'Please enter your password': 'Please enter your password',
    'Or, Visit Our {helpCenterLink}': 'Or, Visit Our {helpCenterLink}',
    "Password doesn't match our records": '',
    "Uh-oh passwords don't match": '',
    'Your new password cannot be the same as your current password': '',
    'Password can only have English letters, numbers, spaces, and special characters from: dot, exclamation point, question mark, dash, underscore, comma, semicolon, colon, forward slash, backslash, open parenthesis, close parenthesis, open bracket, close bracket, tilde, at sign, number sign, dollar sign, percent sign, caret, ampersand, asterisk, plus sign, equal sign, apostrophe, quotation mark, grave accent, vertical bar, open curly bracket, close curly bracket, less than sign, and greater than sign': '',
    'Update Password': '',
    'Old Password': 'Old Password',
    'New Password': 'New Password',
    'Confirm Password': 'Confirm Password',
    Update: '',
    'Are you sure you want to continue?': 'Are you sure you want to continue?',
    'Signed Out': 'Signed Out',
    'You have been signed out. Please log back in.': 'You have been signed out. Please log back in.',
    '© {fourDigitYear} iHeartMedia, Inc.': '© {fourDigitYear} iHeartMedia, Inc.',
    Help: 'Help',
    'Terms of Use': 'Terms of Use',
    'Playlist "{playlistName}" created': 'Playlist "{playlistName}" created',
    'Create unlimited playlists with iHeart All Access.': 'Create unlimited playlists with iHeart All Access.',
    'There was an error creating your playlist': 'There was an error creating your playlist',
    'Device Limit': 'Device Limit',
    "This stream stopped because you're listening on another device.": "This stream stopped because you're listening on another device.",
    'Listen on this device': 'Listen on this device',
    'Got it': 'Got it',
    'Album saved to Your Library': 'Album saved to Your Library',
    'Save Album': 'Save Album',
    'Saved {name} to Your Library': 'Saved {name} to Your Library',
    'Removed {name} from Your Library': 'Removed {name} from Your Library',
    'Follow Artist': 'Follow Artist',
    'Remove Artist': 'Remove Artist',
    'Not for Me': 'Not for Me',
    Delete: 'Delete',
    'Follow station': 'Follow station',
    'Unfollow station': 'Unfollow station',
    Connect: 'Connect',
    'Call ': 'Call ',
    'Text ': 'Text ',
    'Edit Playlist': 'Edit Playlist',
    'Delete Playlist': 'Delete Playlist',
    'Unfollow Playlist': 'Unfollow Playlist',
    'Follow Playlist': 'Follow Playlist',
    'Followed {title}': 'Followed {title}',
    'Unfollowed {title}': 'Unfollowed {title}',
    'Follow Podcast': 'Follow Podcast',
    'Unfollow Podcast': 'Unfollow Podcast',
    'No added songs yet': 'No added songs yet',
    'Tap ': 'Tap ',
    Save: 'Save',
    ' on the player to add a song to My Playlist': ' on the player to add a song to My Playlist',
    'Click on the': 'Click on the',
    'button next to a song': 'button next to a song',
    'and select Add to Playlist.': 'and select Add to Playlist.',
    'Find Music': 'Find Music',
    'Uh oh, we can’t find the page you’re looking for.': 'Uh oh, we can’t find the page you’re looking for.',
    'How about this page instead: ': 'How about this page instead: ',
    'Read more': 'Read more',
    Explicit: 'Explicit',
    E: '',
    'Invite sent to {email}': 'Invite sent to {email}',
    'Add family member...': 'Add family member...',
    'email@example.com': 'email@example.com',
    'Send Invite': 'Send Invite',
    You: 'You',
    Active: 'Active',
    '{email} has been removed.': '{email} has been removed.',
    'Remove Member': 'Remove Member',
    'Cancel Invite': 'Cancel Invite',
    'Resend Invite': 'Resend Invite',
    'Invite was sent on ': 'Invite was sent on ',
    'Active ✓': 'Active ✓',
    'Pending...': 'Pending...',
    'Invitees must be 14 years or older': 'Invitees must be 14 years or older',
    Owner: 'Owner',
    Following: 'Following',
    Follow: 'Follow',
    'Music, radio and podcasts, all free. Listen online or download the iHeart App.': 'Music, radio and podcasts, all free. Listen online or download the iHeart App.',
    'Connect with Facebook': 'Connect with Facebook',
    'Connect with Twitter': 'Connect with Twitter',
    'iHeart Instagram': 'iHeart Instagram',
    'iHeart Youtube': 'iHeart Youtube',
    Explore: 'Explore',
    iHeart: 'iHeart',
    'Live Radio': 'Live Radio',
    Podcasts: 'Podcasts',
    'On Demand Podcasts': 'On Demand Podcasts',
    'Artist Radio': 'Artist Radio',
    Playlists: 'Playlists',
    Genres: 'Genres',
    'Browse by Genre': 'Browse by Genre',
    Exclusives: 'Exclusives',
    News: 'News',
    'iHeart News': 'iHeart News',
    Features: 'Features',
    'Featured Exclusives on iHeart': 'Featured Exclusives on iHeart',
    Events: 'Events',
    'iHeart Events': 'iHeart Events',
    Contests: 'Win',
    'iHeart Contests': 'iHeart Contests',
    Photos: 'Photos',
    'iHeart Photos': 'iHeart Photos',
    Information: 'Information',
    About: 'About',
    'About iHeart': 'About iHeart',
    Advertise: 'Advertise',
    'Advertise on iHeart': 'Advertise on iHeart',
    Blog: 'Blog',
    'iHeart Blog': 'iHeart Blog',
    'Brand Guidelines': 'Brand Guidelines',
    'iHeart Logo and Guidelines': 'iHeart Logo and Guidelines',
    'Contest Guidelines': 'Contest Guidelines',
    'iHeart Contest Rules': 'iHeart Contest Rules',
    'Subscription Offers': 'Subscription Offers',
    Jobs: 'Jobs',
    'iHeart Jobs': 'iHeart Jobs',
    'Get the App': 'Get the App',
    Automotive: 'Automotive',
    'iHeart in your house': 'iHeart in your house',
    Home: 'Home',
    'iHeart in your car': 'iHeart in your car',
    Mobile: 'Mobile',
    'iHeart on your phone or tablet': 'iHeart on your phone or tablet',
    Wearables: 'Wearables',
    'iHeart wearables': 'iHeart wearables',
    'Your Library': 'Your Library',
    'Saved {name} to {savedStationsLink}': 'Saved {name} to {savedStationsLink}',
    'Removed {name} from {savedStationsLink}': 'Removed {name} from {savedStationsLink}',
    'Followed {podcastName}': 'Followed {podcastName}',
    'Unfollowed {podcastName}': 'Unfollowed {podcastName}',
    "We won't play this song again on this station.": "We won't play this song again on this station.",
    'Thanks for your feedback.': 'Thanks for your feedback.',
    'favorite songs': 'favourite songs',
    "We'll play more like it. Check out your {favoriteSongsLink} to see everything you've liked.": "We'll play more like it. Check out your {favoriteSongsLink} to see everything you've liked.",
    "We'll let our DJs know you've heard enough of this song.": "We'll let our DJs know you've heard enough of this song.",
    "Check out your {favoriteSongsLink} to see everything you've liked.": "Check out your {favoriteSongsLink} to see everything you've liked.",
    "Glad you like it. We'll let our DJs know.": "Glad you like it. We'll let our DJs know.",
    "OK, we'll adjust your music mix.": "OK, we'll adjust your music mix.",
    'Favorites Radio': 'Favourites Radio',
    'Great, we’ll add this song to your own {favoritesRadioLink}!': 'Great, we’ll add this song to your own {favoritesRadioLink}!',
    'Great, we’ll play you more songs like this': 'Great, we’ll play you more songs like this',
    'We appreciate you letting us know.': 'We appreciate you letting us know.',
    'Nice! You’ll hear this song more often.': 'Nice! You’ll hear this song more often.',
    "You've reached your skip limit.": "You've reached your skip limit.",
    "We're sorry, you can't skip any more songs right now. You can still thumb down a song. Want to know more? Visit {helpLink}": "We're sorry, you can't skip any more songs right now. You can still thumb down a song. Want to know more? Visit {helpLink}",
    'Report this issue.': 'Report this issue.',
    "There's an error with episodes for this show. {reportLink}": "There's an error with episodes for this show. {reportLink}",
    "There's an error with this content. {reportLink}": "There's an error with this content. {reportLink}",
    'Playlist followed and saved to {link}': 'Playlist followed and saved to {link}',
    'Playlist unfollowed and removed from {link}': 'Playlist unfollowed and removed from {link}',
    'Click here': 'Click here',
    'Having trouble logging into iHeart with your Facebook or Google account? {clickHereLink} for help.': 'Having trouble logging into iHeart with your Facebook or Google account? {clickHereLink} for help.',
    More: 'More',
    Subscribe: 'Subscribe',
    Upgrade: 'Upgrade',
    'Upgrade Now': 'Upgrade Now',
    Settings: 'Settings',
    'Log Out': 'Log Out',
    Account: 'Account',
    'Sponsor Content': 'Sponsor Content',
    'Mark As Unplayed': 'Mark As Unplayed',
    'Mark as Played': 'Mark as Played',
    'This station is not currently available': 'This station is not currently available',
    'Please pick a different station or listen to this station using our free iHeart app': 'Please pick a different station or listen to this station using our free iHeart app',
    'Listen in App': 'Listen in App',
    "You haven't thumbed up any songs yet.": "You haven't thumbed up any songs yet.",
    'Favorite Songs': 'Favourite Songs',
    NEW: 'NEW',
    'Welcome to': 'Welcome to',
    'Your Favorites Radio!': 'Your Favourites Radio!',
    'All your favorites and thumbs up in one station. The more you interact with iHeart, the better it gets.': 'All your favorites and thumbs up in one station. The more you interact with iHeart, the better it gets.',
    'Please name your station now:': 'Please name your station now:',
    'On Air': 'On Air',
    Share: 'Share',
    'Go To Album': 'Go To Album',
    'Go To Artist': 'Go To Artist',
    'Go To Station': 'Go To Station',
    'Unfollow Station': 'Unfollow Station',
    'Follow Station': 'Follow Station',
    'Go To Your Library': 'Go To Your Library',
    'Go To Playlist': 'Go To Playlist',
    'Share Episode': '',
    'Share from': '',
    Transcript: '',
    Pause: 'Pause',
    Stop: 'Stop',
    Play: 'Play',
    'Play Newest': 'Play Newest',
    'Create unlimited playlists. Try iHeart All Access.': 'Create unlimited playlists. Try iHeart All Access.',
    'My Music': 'My Music',
    'Theme Radio': 'Theme Radio',
    Playlist: 'Playlist',
    'Playlist by': 'Playlist by',
    Podcast: 'Podcast',
    'Thanks for listening!': 'Thanks for listening!',
    Rename: 'Rename',
    Edit: 'Edit',
    'Add to Another Playlist': 'Add to Another Playlist',
    Shuffle: 'Shuffle',
    'Added By You': 'Added By You',
    'Recently Played': 'Recently Played',
    'You like {station} on Facebook': 'You like {station} on Facebook',
    'You listened to {station}': 'You listened to {station}',
    'You are near {city}': 'You are near {city}',
    'You like {genre}': 'You like {genre}',
    'Just For You': 'Just For You',
    'Popular On {iHeart}': 'Popular On {iHeart}',
    'Discover how you can save and replay music on your favorite radio stations, or download and listen offline to millions of songs!': 'Discover how you can save and replay music on your favourite radio stations, or download and listen offline to millions of songs!',
    'iHeart Plus & All Access - Upgrade to a Free Trial': 'iHeart Plus & All Access - Upgrade to a Free Trial',
    'Playlist deleted': 'Playlist deleted',
    'Select a song to replay': 'Select a song to replay',
    'Save Playlist': 'Save Playlist',
    All: 'All',
    'Live Stations': 'Live Stations',
    Artists: 'Artists',
    Albums: 'Albums',
    Songs: 'Songs',
    'artists, stations or podcasts': 'artists, stations or podcasts',
    'stations or podcasts': 'stations or podcasts',
    podcasts: 'podcasts',
    search: 'search',
    'Album{bulletSeparator}{artistName}': 'Album{bulletSeparator}{artistName}',
    'Top Result': 'Top Result',
    'Search Results': 'Search Results',
    'Show More Results': 'Show More Results',
    'Search iHeart for artists, stations or podcasts.': 'Search iHeart for artists, stations or podcasts.',
    'Search iHeart for stations or podcasts.': 'Search iHeart for stations or podcasts.',
    'Search iHeart for podcasts.': 'Search iHeart for podcasts.',
    'There are no results for "{searchQuery}"': 'There are no results for "{searchQuery}"',
    'Check your spelling or try another search': 'Check your spelling or try another search',
    'Show all Live Stations': 'Show all Live Stations',
    'Show all Artists': 'Show all Artists',
    'Show all Albums': 'Show all Albums',
    'Show all Songs': 'Show all Songs',
    'Show all Playlists': 'Show all Playlists',
    'Show all Podcasts': 'Show all Podcasts',
    'Show all {title}': 'Show all {title}',
    'Embed Widget': 'Embed Widget',
    Twitter: 'Twitter',
    'See {moreTiles} of {itemCount}': 'See {moreTiles} of {itemCount}',
    'See All': 'See All',
    'See Less': 'See Less',
    'For You': 'For You',
    'Get The App': 'Get The App',
    'Connect with ': 'Connect with ',
    'on your favorite social network ': 'on your favourite social network ',
    ' Song': ' Song',
    'Keep Thumbing!': 'Keep Thumbing!',
    'Thumbing up songs personalizes the stations you listen to and adds them to Your Favorites Radio.': 'Thumbing up songs personalizes the stations you listen to and adds them to Your Favourites Radio.',
    '1 song': '1 song',
    'All your favorite songs and artists.': 'All your favourite songs and artists.',
    'Show Less': 'Show Less',
    'Show More': 'Show More',
    Schedule: 'Schedule',
    'Listen offline': 'Listen offline',
    'Unlimited skips': 'Unlimited skips',
    'Save songs from radio to playlists': 'Save songs from radio to playlists',
    'Instantly replay songs from radio': 'Instantly replay songs from radio',
    'Create unlimited playlists': 'Create unlimited playlists',
    'Play millions of songs on demand': 'Play millions of songs on demand',
    'Now you can create your own playlists with All Access!': 'Now you can create your own playlists with All Access!',
    'Start Free 30 Day Trial': 'Start Free 30 Day Trial',
    'Subscribe to All Access': 'Subscribe to All Access',
    'is now better than ever!': 'is now better than ever!',
    'All your favorite music, podcasts, and radio stations available for free. Listen to thousands of live radio stations or create your own artist stations and playlists. Get the latest music and trending news, from your favorite artists and bands.': 'All your favourite music, podcasts, and radio stations available for free. Listen to thousands of live radio stations or create your own artist stations and playlists. Get the latest music and trending news, from your favourite artists and bands.',
    'Stream thousands of the best live radio stations and custom artist stations for FREE - all in one app.': 'Stream thousands of the best live radio stations and custom artist stations for FREE - all in one app.',
    'Stream thousands of the best live radio stations for FREE, plus unlimited music on demand - all in one app.': 'Stream thousands of the best live radio stations for FREE, plus unlimited music on demand - all in one app.',
    'Stream thousands of the best live radio stations for FREE - all in one app.': 'Stream thousands of the best live radio stations for FREE - all in one app.',
    'All Genres': 'All Genres',
    Featured: 'Featured',
    Popular: 'Popular',
    'You are now logged in as {profileName}': 'You are now logged in as {profileName}',
    Artist: 'Artist',
    'Song by': '',
    Song: 'Song',
    'Live Station': '',
    'Album by': '',
    Album: '',
    'Email or password is invalid. Please try again.': 'Email or password is invalid. Please try again.',
    'There was an issue playing the audio, please check your connection and try again.': 'There was an issue playing the audio, please check your connection and try again.',
    'There was a connection problem. Please reconnect and try again.': 'There was a connection problem. Please reconnect and try again.',
    'See More': 'See More',
    'all access': 'all access',
    'Best Playlists': 'Best Playlists',
    'concert pictures': 'concert pictures',
    'create playlists': 'create playlists',
    'custom radio station': 'custom radio station',
    'event pictures': 'event pictures',
    'favorite music': 'favourite music',
    'Music Playlists': 'Music Playlists',
    'my music': 'my music',
    'Now Playing': 'Now Playing',
    'On Demand': 'On Demand',
    'Online Playlists': 'Online Playlists',
    'paid music': 'paid music',
    'paid songs': 'paid songs',
    'personal radio station': 'personal radio station',
    'personalized radio station': 'personalized radio station',
    'play songs': 'play songs',
    'Top Playlists': 'Top Playlists',
    'recent photos': 'recent photos',
    'recent pictures': 'recent pictures',
    'saved music': 'saved music',
    'saved songs': 'saved songs',
    'top playlists': 'top playlists',
    App: 'App',
    Bands: 'Bands',
    Best: 'Best',
    Discover: 'Discover',
    Download: 'Download',
    Free: 'Free',
    Influencers: 'Influencers',
    Influences: 'Influences',
    Internet: 'Internet',
    Listen: 'Listen',
    Live: 'Live',
    Music: 'Music',
    Online: 'Online',
    Personalized: 'Personalized',
    photos: 'photos',
    pictures: 'pictures',
    Radio: 'Radio',
    Related: 'Related',
    Similar: 'Similar',
    Station: 'Station',
    Stations: 'Stations',
    stream: 'stream',
    Streaming: 'Streaming',
    Talk: 'Talk',
    Top: 'Top',
    January: 'January',
    February: 'February',
    March: 'March',
    April: 'April',
    May: 'May',
    June: 'June',
    July: 'July',
    August: 'August',
    September: 'September',
    October: 'October',
    November: 'November',
    December: 'December',
    Jan: 'Jan',
    Feb: 'Feb',
    Mar: 'Mar',
    Apr: 'Apr',
    Jun: 'Jun',
    Jul: 'Jul',
    Aug: 'Aug',
    Sep: 'Sep',
    Oct: 'Oct',
    Nov: 'Nov',
    Dec: 'Dec',
    'Password must have': '',
    'iHeart: Listen to Free Radio Stations & Music Online | iHeart': 'iHeart: Listen to Free Radio Stations & Music Online | iHeart',
    '1 Song': '1 Song',
    'Listen to music you love! Create a custom radio station based on your favorite album by {artistName} on iHeartRadio!': 'Listen to music you love! Create a custom radio station based on your favourite album by {artistName} on iHeartRadio!',
    'Stream Free Music from Albums by {artistName}': 'Stream Free Music from Albums by {artistName}',
    'Create Artist Radio Stations': 'Create Artist Radio Stations',
    'Popular {genre} Artists': 'Popular {genre} Artists',
    'Artist Radio gives you the power to create custom stations from your favorite songs, albums or musical artists. Listen for free on iHeart!': 'Artist Radio gives you the power to create custom stations from your favorite songs, albums or musical artists. Listen for free on iHeart!',
    'Create a personalized custom music station from thousands of {name} artists you love, only on iHeart!': 'Create a personalized custom music station from thousands of {name} artists you love, only on iHeart!',
    'Create Custom Radio Stations on Artist Radio!': 'Create Custom Radio Stations on Artist Radio!',
    'Listen to the Best {name} Music for Free on iHeart': 'Listen to the Best {name} Music for Free on iHeart',
    '{artistName}': '{artistName}',
    '{name}, News, Music, Headlines, iHeartRadio, iHeart, Radio': '{name}, News, Music, Headlines, iHeartRadio, iHeart, Radio',
    'Latest {name} news from iHeart': 'Latest {name} news from iHeart',
    'Read the latest News on {name} Online': 'Read the latest News on {name} Online',
    Biography: 'Biography',
    'Similar Artists': 'Similar Artists',
    'Latest Release': 'Latest Release',
    'Popular On': 'Popular On',
    "This artist doesn't have any top songs.": "This artist doesn't have any top songs.",
    'Top Songs': 'Top Songs',
    'Stream Music from Artists Like {artistName}': 'Stream Music from Artists Like {artistName}',
    "Listen to music you'll love! Create a custom radio station from your favorite songs by {artistName} on iHeart.": "Listen to music you'll love! Create a custom radio station from your favorite songs by {artistName} on iHeart.",
    'Stream Free Songs by {artistName} & Similar Artists': 'Stream Free Songs by {artistName} & Similar Artists',
    'Almost there! Verify your zipcode to unlock your spot on the Family Plan': 'Almost there! Verify your zipcode to unlock your spot on the Family Plan',
    'In order to add you to the plan, we need to make sure that you live at the same address as the plan owner. Please input your zipcode below to verify and unlock your account.': 'In order to add you to the plan, we need to make sure that you live at the same address as the plan owner. Please input your zipcode below to verify and unlock your account.',
    'Household Zipcode': 'Household Zipcode',
    'e.g 90210...': 'e.g 90210...',
    'help site': 'help site',
    "It looks like you're already subscribed": "It looks like you're already subscribed",
    "You must cancel your active subscription before you can continue. You can find out how to cancel your subscription at {supportLink}. Once you've cancelled your current subscription plan you will need to wait until your current subscription plan ends.": "You must cancel your active subscription before you can continue. You can find out how to cancel your subscription at {supportLink}. Once you've cancelled your current subscription plan you will need to wait until your current subscription plan ends.",
    'If you have any questions, please {helplink} contact us.': 'If you have any questions, please {helplink} contact us.',
    'Please create a free account, or sign in': 'Please create a free account, or sign in',
    'To accept the Family Plan invitation, you must be logged in.': 'To accept the Family Plan invitation, you must be logged in.',
    'Welcome to Your Favorites Radio, a personalized station containing your favorite songs and artists. The more you thumb up and save stations on iHeart, the better your listening experience will be.': 'Welcome to Your Favorites Radio, a personalized station containing your favorite songs and artists. The more you thumb up and save stations on iHeart, the better your listening experience will be.',
    'Listen to {title}. Thumb up songs to add them to your own Your Favorites Radio station. Find it under My Stations.': 'Listen to {title}. Thumb up songs to add them to your own Your Favorites Radio station. Find it under My Stations.',
    'Featured Artists On This Station': 'Featured Artists On This Station',
    "{name}'s Favorites Radio": "{name}'s Favourites Radio",
    'iHeartRadio Mobile Apps': 'iHeartRadio Mobile Apps',
    'Made for You': 'Made for You',
    'Listen to Stations For You': 'Listen to Stations For You',
    'Change genre preferences {prefLink}': 'Change genre preferences {prefLink}',
    here: 'here',
    'iHeart: Listen to Free Radio Stations & Music Online': 'iHeart: Listen to Free Radio Stations & Music Online',
    'All Your Favorite Music. All Your Favorite Stations. All FREE. Listen to thousands of live stations or create your own artist stations. Let the music play!': 'All Your Favourite Music. All Your Favourite Stations. All FREE. Listen to thousands of live stations or create your own artist stations. Let the music play!',
    'Improve Your Recommendations': 'Improve Your Recommendations',
    'Change Preferences': 'Change Preferences',
    'Change Genre Preferences': 'Change Genre Preferences',
    'Find Stations by Genre': 'Find Stations by Genre',
    'Finding your ideal station is quick and easy. Search for stations by genre & stream them online for free on iHeart.': 'Finding your ideal station is quick and easy. Search for stations by genre & stream them online for free on iHeart.',
    'Discover Music and Radio Stations by Genre on iHeart': 'Discover Music and Radio Stations by Genre on iHeart',
    'Pick Genre': 'Pick Genre',
    'Tell us all the genres you like.': 'Tell us all the genres you like.',
    "We'll suggest stations just For You.": "We'll suggest stations just For You.",
    'Get Stations': 'Get Stations',
    'Find and stream {genreName} music stations for free, only on iHeart.': 'Find and stream {genreName} music stations for free, only on iHeart.',
    'Listen to {genreName} Radio Stations for Free': 'Listen to {genreName} Radio Stations for Free',
    'Find {genreName} Stations': 'Find {genreName} Stations',
    'Featured {genreName} Stations': 'Featured {genreName} Stations',
    'Top {genreName} Stations': 'Top {genreName} Stations',
    'Top {genreName} Artists': 'Top {genreName} Artists',
    'More Genres': 'More Genres',
    'Latest News': 'Latest News',
    'Partner Content': 'Partner Content',
    'All Cities': 'All Cities',
    'for {currentGenre}': 'for {currentGenre}',
    'in {currentMarket}': 'in {currentMarket}',
    'near you': 'near you',
    'Find top stations {genreFilterDescription} {marketFilterDescription} {noFiltersMessage} ({numStations})': 'Find top stations {genreFilterDescription} {marketFilterDescription} {noFiltersMessage} ({numStations})',
    'Listen to Live Radio': 'Listen to Live Radio',
    'No stations currently available for this filter': 'No stations currently available for this filter',
    'Listen to the Best Live Radio Stations in {countryAbbreviation}': 'Listen to the Best Live Radio Stations in {countryAbbreviation}',
    'Listen to {genreName} Radio Stations in {city}': 'Listen to {genreName} Radio Stations in {city}',
    'Listen to Top Radio Stations in {city}, Free': 'Listen to Top Radio Stations in {city}, Free',
    'Live {genreName} Radio Stations in {countryAbbreviation}': 'Live {genreName} Radio Stations in {countryAbbreviation}',
    'Listen to hundreds of the best live radio stations{countryName}, for free! Search for stations near you & around the country.': 'Listen to hundreds of the best live radio stations{countryName}, for free! Search for stations near you & around the country.',
    'Listen to the Best Live Radio{country} for Free on iHeart.': 'Listen to the Best Live Radio{country} for Free on iHeart.',
    'Stream the best live {genreName} radio stations in {city} online for free, only on iHeart!': 'Stream the best live {genreName} radio stations in {city} online for free, only on iHeart!',
    'Listen to Top {genreName} Radio Stations in {city}': 'Listen to Top {genreName} Radio Stations in {city}',
    'Listen to the best live radio stations in {city}. Stream online for free, only on iHeart!': 'Listen to the best live radio stations in {city}. Stream online for free, only on iHeart!',
    'Listen to Top Radio Stations in {city} for Free': 'Listen to Top Radio Stations in {city} for Free',
    'Stream the best live {genreName} radio stations in {countryAbbreviation} for free on iHeart.': 'Stream the best live {genreName} radio stations in {countryAbbreviation} for free on iHeart.',
    'Listen to Free, Live {genreName} Radio Stations in {countryAbbreviation} on iHeart': 'Listen to Free, Live {genreName} Radio Stations in {countryAbbreviation} on iHeart',
    'Contests and Promotions': 'Contests and Promotions',
    'If you like {stationName}...': 'If you like {stationName}...',
    'Similar Stations': 'Similar Stations',
    '{stationName}, {stateAbbreviation}, {city}, {genre}': '{stationName}, {stateAbbreviation}, {city}, {genre}',
    Genre: 'Genre',
    Location: 'Location',
    'On Air Schedule': 'On Air Schedule',
    'Share Song': 'Share Song',
    'Get the latest music news - rock, pop, hip-hop, rock, alternative, and country music news from iHeart. Get the latest music and trending news, from your favorite artists and bands.': 'Get the latest music news - rock, pop, hip-hop, rock, alternative, and country music news from iHeart. Get the latest music and trending news, from your favourite artists and bands.',
    'Breaking Music News, Celeb and Entertainment News and Gossip': 'Breaking Music News, Celeb and Entertainment News and Gossip',
    'Advertise With Us': 'Advertise With Us',
    'Discover thousands of top playlists with songs handcrafted by your favorite personalities and music experts on iHeartRadio.': 'Discover thousands of top playlists with songs handcrafted by your favourite personalities and music experts on iHeartRadio.',
    'Listen to the Best Online Music Playlists': 'Listen to the Best Online Music Playlists',
    'Recommended For You': 'Recommended For You',
    'Moods & Activities': 'Moods & Activities',
    Decades: 'Decades',
    'Featured Playlists': 'Featured Playlists',
    By: 'By',
    'Your songs shuffled with songs we think you’ll like. The more songs you add, the better our picks get.': 'Your songs shuffled with songs we think you’ll like. The more songs you add, the better our picks get.',
    'Introducing a new playlist experience': 'Introducing a new playlist experience',
    'Upgrade for this playlist': 'Upgrade for this playlist',
    'Song saved to Your Library': 'Song saved to Your Library',
    'Song removed from playlist': '',
    'Go to Artist': 'Go to Artist',
    'Go to Album': 'Go to Album',
    'Remove from Playlist': 'Remove from Playlist',
    SONG: 'SONG',
    'ALBUM TITLE': 'ALBUM TITLE',
    ARTIST: 'ARTIST',
    DURATION: 'DURATION',
    Done: 'Done',
    'Discover and stream the best {categoryTitle} playlists, handcrafted by the music experts at iHeart.': 'Discover and stream the best {categoryTitle} playlists, handcrafted by the music experts at iHeart.',
    '{categoryTitle}, {categoryTitle} Playlists, Stream {categoryTitle} Music, Play {categoryTitle} Songs, Top {categoryTitle} Playlists, Best { categoryTitle} Playlists': '{categoryTitle}, {categoryTitle} Playlists, Stream {categoryTitle} Music, Play {categoryTitle} Songs, Top {categoryTitle} Playlists, Best { categoryTitle} Playlists',
    'Top {categoryTitle} Playlists; Find Your Perfect Music Mix': 'Top {categoryTitle} Playlists; Find Your Perfect Music Mix',
    Host: 'Host',
    'iHeart Podcast Networks': 'iHeart Podcast Networks',
    '{name}, Blog, News, Music, Headlines, iHeartRadio, iHeart, Radio': '{name}, Blog, News, Music, Headlines, iHeartRadio, iHeart, Radio',
    '{name} Blog, News & Videos': '{name} Blog, News & Videos',
    'Go behind the scenes, get exclusive stories and learn more about the show!': 'Go behind the scenes, get exclusive stories and learn more about the show!',
    Played: 'Played',
    'Listen to the best {categoryName} radio shows, free and on demand, only on iHeart.': 'Listen to the best {categoryName} radio shows, free and on demand, only on iHeart.',
    'Listen to the Best Free {categoryName} Podcasts': 'Listen to the Best Free {categoryName} Podcasts',
    'On Demand, On-Demand, Show, Shows, Personalities, Programs, Listen, iHeartRadio, iHeart': 'On Demand, On-Demand, Show, Shows, Personalities, Programs, Listen, iHeartRadio, iHeart',
    'Discover Top Podcasts': 'Discover Top Podcasts',
    'Listen to the latest episodes from top podcasts. Get news, sports & lifestyle info from your favorite personalities for free!': 'Listen to the latest episodes from top podcasts. Get news, sports & lifestyle info from your favourite personalities for free!',
    'Listen to the Best Podcasts Online for Free on iHeart': 'Listen to the Best Podcasts Online for Free on iHeart',
    'Listen to the Best Podcasts & Shows Online, Free': 'Listen to the Best Podcasts & Shows Online, Free',
    'Talk, Show, Host, Personality, On Demand, Topic, Category, Health, News, Politics, Comedy, Station, Internet, Online, iHeartRadio, iHeart': 'Talk, Show, Host, Personality, On Demand, Topic, Category, Health, News, Politics, Comedy, Station, Internet, Online, iHeartRadio, iHeart',
    'Stream Top Podcasts': 'Stream Top Podcasts',
    'All Topics': 'All Topics',
    '{name} Podcasts': '{name} Podcasts',
    Topics: 'Topics',
    'All Episodes': 'All Episodes',
    "These episodes aren't here yet...": "These episodes aren't here yet...",
    "Follow this podcast to be notified when they're ready": "Follow this podcast to be notified when they're ready",
    'Share Podcast': '',
    'The Latest News': 'The Latest News',
    'Listen to {episodeTitle} | {showTitle} | Podcasts': 'Listen to {episodeTitle} | {showTitle} | Podcasts',
    '{episodeTitle},{showTitle},Talk,Radio,Listen,On Demand,iHeartRadio,iHeart': '{episodeTitle},{showTitle},Talk,Radio,Listen,On Demand,iHeartRadio,iHeart',
    '{title}, Talk, Radio, Listen, On Demand, iHeartRadio, iHeart': '{title}, Talk, Radio, Listen, On Demand, iHeartRadio, iHeart',
    Episodes: 'Episodes',
    'Episode Transcript': '',
    'Available transcripts are automatically generated. Complete accuracy is not guaranteed.': '',
    'Error Loading Transcript': '',
    'We had a problem rendering this page, please try refreshing your browser': '',
    'Refresh the page': '',
    'Report this issue': 'Report this issue',
    'Popular Podcasts': 'Popular Podcasts',
    'Stream the best podcasts from your favorite stations': 'Stream the best podcasts from your favourite stations',
    'Follow Us On': 'Follow Us On',
    'Show Links': 'Show Links',
    'Settings | See Your Music Profile': 'Settings | See Your Music Profile',
    'Log in and see which live radio stations and custom artist stations you love. You can also view your listening history. All free.': 'Log in and see which live radio stations and custom artist stations you love. You can also view your listening history. All free.',
    Connections: 'Connections',
    Unlink: 'Unlink',
    Link: 'Link',
    'Genre added.': 'Genre added.',
    'Genre removed.': 'Genre removed.',
    "You've added {genreName} to your genres.": "You've added {genreName} to your genres.",
    "You've removed {genreName} from your genres.": "You've removed {genreName} from your genres.",
    'Your preferences have been changed.': 'Your preferences have been changed.',
    'Not provided': 'Not provided',
    'Publish my listening activity to my Facebook music dashboard': 'Publish my listening activity to my Facebook music dashboard',
    'Update your password, genre selection, and modify your subscription below.': 'Update your password, genre selection, and modify your subscription below.',
    "You're currently signed in with a social account, add an email and password to have full access to your account": "You're currently signed in with a social account, add an email and password to have full access to your account",
    'Add New Email': 'Add New Email',
    'Contact Information': 'Contact Information',
    'Update your contact information to personalize your experience and easily enter iHeart contests and promotions.': 'Update your contact information to personalize your experience and easily enter iHeart contests and promotions.',
    'First and Last Name': 'First and Last Name',
    'Add your full name': 'Add your full name',
    'Add your postal code': '',
    'My Genres': 'My Genres',
    'Tell us all your favorite genres so we can make iHeart perfect for you.': 'Tell us all your favorite genres so we can make iHeart perfect for you.',
    'View Recommendations': 'View Recommendations',
    'Billing History': 'Billing History',
    'Subscribe Again': 'Subscribe Again',
    'Update Payment': 'Update Payment',
    Unknown: 'Unknown',
    'Unknown Plan Description': 'Unknown Plan Description',
    'iHeart Family Plan': 'iHeart Family Plan',
    'You are enjoying an unlimited music experience with an iHeart Family Plan.': 'You are enjoying an unlimited music experience with an iHeart Family Plan.',
    'iHeart All Access': 'iHeart All Access',
    'You are enjoying an unlimited music experience by subscribing to iHeart All Access.': 'You are enjoying an unlimited music experience by subscribing to iHeart All Access.',
    'iHeart Plus': 'iHeart Plus',
    'You are subscribed to iHeart Plus. Switch to iHeart All Access to enjoy an unlimited music experience on your computer.': 'You are subscribed to iHeart Plus. Switch to iHeart All Access to enjoy an unlimited music experience on your computer.',
    'iHeart Free': 'iHeart Free',
    'Subscribe to enjoy unlimited music.': 'Subscribe to enjoy unlimited music.',
    'Your subscription ends on {subscriptionEndsString}.': 'Your subscription ends on {subscriptionEndsString}.',
    'Family Members': 'Family Members',
    Subscription: 'Subscription',
    'Your Favorite Songs | Log In & See': 'Your Favourite Songs | Log In & See',
    'Log in or sign up and find the songs you love here, only on iHeart.': 'Log in or sign up and find the songs you love here, only on iHeart.',
    'iHeart Codes Coupons and Special Offers for Unlimited Music': 'iHeart Codes Coupons and Special Offers for Unlimited Music',
    'Find iHeart Radio special offers, codes and coupons for unlimited music on demand and all access to iHeart Plus. These special offers provide access to millions of songs, thousands of radio and artists stations with unlimited skips and offline listening!': 'Find iHeart Radio special offers, codes and coupons for unlimited music on demand and all access to iHeart Plus. These special offers provide access to millions of songs, thousands of radio and artists stations with unlimited skips and offline listening!',
    'There was an error validating your request.': 'There was an error validating your request.',
    'Please enter a valid email address': 'Please enter a valid email address',
    'Please fix the following errors:': 'Please fix the following errors:',
    'Start Your Free 30 Day Trial': 'Start Your Free 30 Day Trial',
    'Start Your Family Plan': 'Start Your Family Plan',
    'Sign up for iHeart All Access Annual Plan': 'Sign up for iHeart All Access Annual Plan',
    View: 'View',
    'Offer Terms': 'Offer Terms',
    'Email Address': 'Email Address',
    'Enter email address': 'Enter email address',
    'Card Details': 'Card Details',
    'Zip / Postal Code': 'Zip / Postal Code',
    'Enter Here': 'Enter Here',
    'Yes, please automatically renew my subscription annually (subject to {termsLink})': 'Yes, please automatically renew my subscription annually (subject to {termsLink})',
    'terms & conditions': 'terms & conditions',
    'Charges will appear on your credit card statement as "IHEART BILLING"': 'Charges will appear on your credit card statement as "IHEART BILLING"',
    'You can cancel at any time by going to "Settings" and following the cancellation instructions. By clicking "Subscribe" below, you agree to the conditions above and the iHeart ': 'You can cancel at any time by going to "Settings" and following the cancellation instructions. By clicking "Subscribe" below, you agree to the conditions above and the iHeart ',
    Processing: 'Processing',
    'Enter your email address below to log in by email or reset your password': '',
    email: 'email',
    Send: 'Send',
    'Don’t use common passwords, like “Password123!”': '',
    'Your password reset link is invalid or expired. Please try': '',
    'resetting your password': '',
    'again.': '',
    'Create a New Password': 'Create a New Password',
    'Your password reset link has expired': '',
    'An unknown error occured. Please try again later': '',
    'Your password has been updated': 'Your password has been updated',
    'Confirm New Password': '',
    Create: 'Create',
    'Custom radio stations based off your favorite artists and bands.': 'Custom radio stations based off your favourite artists and bands.',
    'View More Artists': 'View More Artists',
    'Bring iHeart with you anywhere': 'Bring iHeart with you anywhere',
    'The free iHeart app is available on over 200 platforms, including Web, iOS, Android, Alexa, automobiles and more.': 'The free iHeart app is available on over 200 platforms, including Web, iOS, Android, Alexa, automobiles and more.',
    'Download on the': 'Download on the',
    'Download on': 'Download on',
    'View All Apps': 'View All Apps',
    'Upcoming Events': 'Upcoming Events',
    'Listen to Your Favorite Music, Podcasts, and Radio Stations for Free! – iHeart': 'Listen to Your Favorite Music, Podcasts, and Radio Stations for Free! – iHeart',
    'iHeart. All your favorite music, podcasts, and radio stations available for free. Listen to thousands of live radio stations or create your own artist stations and playlists. Get the latest music and trending news, from your favorite artists and bands.': 'iHeart. All your favorite music, podcasts, and radio stations available for free. Listen to thousands of live radio stations or create your own artist stations and playlists. Get the latest music and trending news, from your favorite artists and bands.',
    'Get Started Free': 'Get Started Free',
    'All your favorite music, radio and podcasts, all free.': 'All your favourite music, radio and podcasts, all free.',
    'Your browser does not support video playback': 'Your browser does not support video playback',
    'The best live stations from around the country, any time, any place.': 'The best live stations from around the country, any time, any place.',
    'View More Live Stations': 'View More Live Stations',
    'New on iHeart': 'New on iHeart',
    'Latest Music News': 'Latest Music News',
    'View More Music News': 'View More Music News',
    'Popular and exclusive podcasts in a variety of genres and topics.': 'Popular and exclusive podcasts in a variety of genres and topics.',
    'View More Podcasts': 'View More Podcasts',
    '{count} song{plural}': '{count} song{plural}',
    'Browse Artists': 'Browse Artists',
    'Need to find a great new album?': 'Need to find a great new album?',
    'Any albums you save will show up here': 'Any albums you save will show up here',
    'Need to find an artist you love?': 'Need to find an artist you love?',
    'Any artists you follow will show up here': 'Any artists you follow will show up here',
    'Access your music library and create unlimited playlists with iHeart All Access': 'Access your music library and create unlimited playlists with iHeart All Access',
    'song{pluralSongs}': 'song{pluralSongs}',
    by: 'by',
    'Browse Playlists': 'Browse Playlists',
    'Need a playlist to fit your mood?': 'Need a playlist to fit your mood?',
    'Any playlists you follow will show up here': 'Any playlists you follow will show up here',
    'Browse Podcasts': 'Browse Podcasts',
    'Need a great new podcast to start?': 'Need a great new podcast to start?',
    'Any podcasts you follow will show up here': 'Any podcasts you follow will show up here',
    'Any radio, music, or podcasts you listen to will show up here': 'Any radio, music, or podcasts you listen to will show up here',
    'Browse Stations For You': 'Browse Stations For You',
    'Any podcasts you listen to will show up here': 'Any podcasts you listen to will show up here',
    'Need help finding something new?': 'Need help finding something new?',
    'Remove station': 'Remove station',
    '{name} removed from Your Library': '{name} removed from Your Library',
    'Followed Stations': 'Followed Stations',
    'Browse Live Radio': 'Browse Live Radio',
    'Browse Artist Radio': 'Browse Artist Radio',
    'Need help finding stations to save?': 'Need help finding stations to save?',
    'Your followed live and artist radio stations will show up here': 'Your followed live and artist radio stations will show up here',
    '{albumName} removed from Your Library': '{albumName} removed from Your Library',
    Remove: 'Remove',
    '{artistName} removed from Your Library': '{artistName} removed from Your Library',
    'ARTIST IN YOUR LIBRARY': 'ARTIST IN YOUR LIBRARY',
    'Add to playlist': 'Add to playlist',
    'Play Now': 'Play Now',
    '{songTitle} removed from Your Library': '{songTitle} removed from Your Library',
    'Need to find a new song?': 'Need to find a new song?',
    'Any song you save will show up here': 'Any song you save will show up here',
    'Playlist Radio': 'Playlist Radio',
    'View All': 'View All',
    Copied: 'Copied',
    'Read more on iHeartRadio': 'Read more on iHeartRadio',
    'Link Copied!': 'Link Copied!',
    'Copy Link': 'Copy Link',
    'Code Copied!': 'Code Copied!',
    Embed: 'Embed',
    'Follow on iHeartRadio': 'Follow on iHeartRadio',
    'Playback not supported by device or browser.': 'Playback not supported by device or browser.',
    'Method {method} not supported.': 'Method {method} not supported.',
    'View iHeart.com': 'View iHeart.com',
    "Oops, that wasn't supposed to happen.": "Oops, that wasn't supposed to happen.",
    "Oops, this feature isn't supported yet.": "Oops, this feature isn't supported yet.",
    'Oops! We had problems playing this stream.': 'Oops! We had problems playing this stream.',
    'This station is not currently available in your country.': 'This station is not currently available in your country.',
    Retry: 'Retry',
    'View Troubleshooting': 'View Troubleshooting',
    hello: 'hello',
    'my friend': 'my friend',
    'Share this live station': 'Share this live station',
    'Create your own on iHeart': 'Create your own on iHeart',
    "We're sorry! This playlist is not available in your country.": "We're sorry! This playlist is not available in your country.",
    'Playlist Not Available': 'Playlist Not Available',
    'This playlist is unsupported in your current country.': 'This playlist is unsupported in your current country.',
    'Playback Unsupported In Current Country': 'Playback Unsupported In Current Country',
    'Featured artists in this playlist': 'Featured artists in this playlist',
    'View Playlist': 'View Playlist',
    'Share this episode': 'Share this episode',
    'Listen to more episodes': 'Listen to more episodes',
    'This show has no episodes available yet.': '',
    'Share this podcast': 'Share this podcast'
  },
  es: {
    Facebook: 'Facebook',
    Google: 'Google',
    'Region Not Supported': 'Región no soportada',
    'Do not sell or share my personal information': 'No vender mi información personal',
    'Log In': 'Iniciar sesión',
    'Sorry, an error occurred. Please try again later.': 'Lo sentimos, ocurrió un error. Por favor, inténtalo de nuevo más tarde.',
    "Don't have an account?": '¿No tienes una cuenta?',
    'Sign up': 'Regístrate',
    'Forgot Password?': '¿Olvidaste tu contraseña?',
    Email: 'Correo electrónico',
    Password: 'Contraseña',
    'Sorry for the inconvenience, but we do not allow account sign-up for your current location.': 'Lamentamos el inconveniente, pero no aceptamos registro de usuario en tu ubicación actual.',
    'Visit our Help Center': 'Visita nuestro centro de ayuda',
    'Return to Log In': 'Regresar a inicio de sesión',
    Male: 'Masculino',
    Female: 'Femenino ',
    'Prefer not to say': 'Prefiero no decir',
    Other: 'Otro',
    '8 to 32 characters': '8 a 32 caracteres',
    'an upper case and lower case letter': 'una mayúscula y una minúscula',
    'a number (0-9)': 'un número (0-9)',
    Error: 'Error',
    Success: 'Correcto',
    'Password must have eight to thiry two characters': 'La contraseña debe tener de 8 a 32 caracteres',
    "Password doesn't have eight to thirty two characters": 'La contraseña no tiene de 8 a 32 caracteres',
    'Password has eight to thirty two characters': 'La contraseña tiene de 8 a 32 caracteres',
    'Password must have an uppercase and lower case letter': 'La contraseña debe tener una mayúscula y una minúscula',
    "Password doesn't have an upper case and lower case letter": 'La contraseña no tiene una mayúscula ni una minúsula',
    'Password has an upper case and lower case letter': 'La contraseña tiene una mayúscula y una minúscula',
    'Password must have a number between zero and nine': 'La contraseña debe tener un número entre cero y nuevo.',
    "Password doesn't have a number between zero and nine": 'La contraseña no tiene un número entre cero y nueve. ',
    'password has a number between zero and nine': 'La contraseña tiene un número entre cero y nueve',
    'Please fill out all form fields': 'Por favor llena todos los campos del formulario',
    "Password shouldn't have common words or passwords": 'La contraseña no debe tener palabras comunes.',
    'Password can only have English letters, numbers, spaces, and special characters from: . ! ? - _ , ; : /  ( ) [ ] ~ @ # $ % ^ & * + = \\': 'La contraseña solo puede tener letras, números, espacios y los siguientes caracteres especiales: . ! ? - _ , ; : / ( ) [ ] ~ @ # $ % ^ & * + = \\',
    'Invalid Email Address': 'Correo electrónico no válido',
    'Birth Year': 'Año de nac',
    'Terms of Service': 'Términos de servicio',
    'Privacy Policy': 'Política de Privacidad',
    'Sign Up': 'Regístrate',
    'Postal Code': 'Código Postal',
    'Invalid Postal Code': 'Código postal no válido',
    'Invalid ZIP Code': 'Código postal no válido',
    'Have an account?': '¿Tienes una cuenta?',
    'Login unsuccessful. Please try again later.': 'No se pudo iniciar sesión. Por favor intenta nuevamente más tarde.',
    'Update successful.': 'Actualización exitosa.',
    'Your account has been linked to {provider}.': 'Tu cuenta se ha asociado a {provider}.',
    'This {accountType} account is already linked to an iHeartRadio account': 'Este cuenta {accountType} ya está vinculada a una cuenta de iHeartRadio ',
    '{accountType} account linking failed. Please try again later.': 'Error al vincular la cuenta {accountType}. Por favor Inténtalo más tarde.',
    'Your account has been unlinked from {provider}.': 'Tu cuenta ya no está asociada a {provider}.',
    '{accountType} account unlinking failed. Please try again later.': '{accountType} la desvinculación de cuentas falló. Por favor Inténtalo más tarde.',
    'Login unsuccessful. Window closed before completion. Please try again.': 'No se pudo iniciar sesión. Windows se cerró antes de terminar. Por favor intenta nuevamente.',
    'Do You Have a Popup Blocker?': '¿Tienes un bloqueador de ventanas emergentes (popup)?',
    "To log in with your Facebook or Google account, ensure you've allowed popups from www.iheart.com, refresh, and then try again.": 'Para iniciar sesión con tu cuenta de Facebook o Google, asegúrate de que has permitido ventanas emergentes (popups) de www.iheart.com. Depués recarga y vuelve a intentar.',
    'Feat. {artistNames} and more': 'Feat. {artistNames} y más',
    'Add to Playlist': 'Añadir a Playlist',
    'Episode Info': 'Información del episodio',
    Lyrics: 'Letras',
    'Rename your Favorites Radio station now.': 'Cambia el nombre de tu Radio de Favoritos ahora.',
    'This song is not available to start your station.': 'Esta canción no está disponible para iniciar tu estación.',
    'Play this on web with All Access': 'Toca esto en web con All Access',
    'Subscribe today for unlimited access to playlists': 'Suscríbete hoy para tener acceso ilimitado a playlists',
    'Play the whole album with iHeart All Access.': 'Escucha todo el álbum con iHeart All Access',
    'Listen History': 'Historial de escucha',
    'Custom Stations Limit Exceeded': 'Has excedido el límite de estaciones personalizadas.',
    'Please delete some stations from your account under {listenHistoryLink} before creating new stations.': 'Por favor borra algunas estaciones de tu cuenta {listenHistoryLink} antes de crear nuevas estaciones.',
    'Sorry, this station has run out of songs to play.': 'Lo sentimos, esta estación no tiene más canciones para tocar.',
    Oops: 'Uy',
    "We don't have enough info to create a custom station right now. We'll let our DJs know.": 'No tenemos suficiente información para crear una estación personalizada en este momento. Avisaremos a nuestros programadores.',
    'Replay failed': 'Repetición fallida',
    'An unknown error occurred. Please try again later.': 'Se produjo un error desconocido. Por favor inténtalo más tarde.',
    'Update Account': 'Actualizar cuenta',
    'Please enter a valid email': 'Por favor introduce un correo electrónico válido',
    'New Email': 'Nuevo correo electrónico',
    'Please enter the same email again': 'Por favor ingresa el mismo correo electrónico otra vez',
    'Confirm New Email': 'Confirma el nuevo correo electrónico',
    'Password must be 6 - 32 characters': 'La contraseña debe tener de 6 a  32 caracteres',
    'New Password (6-32 Characters)': 'Nueva contraseña (6-32 caracteres)',
    Confirm: 'Confirmar',
    Cancel: 'Cancelar',
    'Cannot add duplicate tracks to a playlist': 'No se puede añadir canciones duplicadas a una playlist.',
    'Added {contentType} to {playlistName}': 'Se agregó {contentType} a {playlistName}',
    'Added song to {playlistName}': 'Canción agregada a {playlistName}',
    Ok: 'OK',
    'Success!': '¡Éxito!',
    'Your email and password have been added to your account.': 'Tu correo y password han sido agregados a tu cuenta.',
    'Your email has been saved.': 'Tu correo se ha guardado',
    'Your contact information has been saved.': 'Tu información de contacto se ha guardado',
    'Try Again': 'Volver a intentar',
    'For assistance, please visit our {helplink}.': 'Para asistencia, por favor visita nuestro {helplink}',
    'Help Link': 'Enlace de ayuda',
    'Oops!': '¡Uy!',
    'Get Started': 'Empezar',
    'You can now enjoy the full benefits of iHeart All Access for Family.': 'Puedes disfrutar de todos los beneficios de iHeart All Access Familiar',
    'Welcome to the Family!': 'Bienvenido a la Familia!',
    'Your password has been saved.': 'Tu contraseña se ha guardado',
    'Station Not Available': 'Estación no disponible',
    "We're sorry! This station is not available in your country.": '¡Lo sentimos! Esta estación no está disponible en tu país.',
    'Updated {updateDateAndTime}': 'Actualizado {updateDateAndTime}',
    'Please complete the CAPTCHA': 'Por favor completa el CAPTCHA',
    'Check Your Email': 'Revisa tu correo electrónico',
    'Forgot Password': 'Contraseña olvidada',
    'Enter your email address below to log in by email or reset your password.': 'Escribe tu dirección de correo electrónico para iniciar sesión de esta forma o restablecer su contraseña.',
    'Enter your email address below to reset your password.': 'Escribe tu dirección de correo electrónico para restablecer tu contraseña.',
    "If an account exists, you'll receive a password reset link shortly. Check your spam folder if it takes more than a minute or two.": 'Si una cuenta existe, recibirás una liga para restablecer la contraseña. Revisa tu carpeta de correo no deseado si tarda más de dos minutos en llegar.',
    Okay: 'Ok',
    'Back to login': 'Volver a inicio de sesión',
    Submit: 'Enviar',
    'Or, {backToLoginLink}': 'O, {backToLoginLink}',
    Gender: 'Género',
    'Music and radio on demand': 'Música y radio en demanda',
    'Please log in first.': 'Por favor inicia sesión primero.',
    Login: 'Iniciar sesión',
    'To listen to thousands of live radio and artist stations, create a free account.': 'Para escuchar miles de estaciones en vivo y estaciones de artistas crea una cuenta gratuita',
    'To listen to thousands of live radio stations, create a free account.': 'Para escuchar miles de estaciones en vivo, crea una cuenta gratuita.',
    'Connect with your favorites': 'Conéctate con tu música favorita',
    'To easily find your favorite and recently listened to stations, create a free account.': 'Para encontrar fácilmente tus favoritos y lo que has escuchado recientemente, crea una cuenta gratuita.',
    'Access your stations': 'Accede a tus estaciones',
    'To view recently listened to stations and songs, create a free account.': 'Para ver las estaciones recientemente escuchadas, crea una cuenta gratuita.',
    "See what you've been listening to": 'Mira lo que has estado escuchando.',
    'To access your favorites anytime, anywhere, create a free account.': 'Para acceder a tus favoritos en cualquier momento, en cualquier lugar, crear una cuenta gratuita.',
    'Save stations you love': 'Guardar estaciones que te encantan. ',
    'To hear more music you like, create a free account.': 'Para escuchar más música que te gusta, crea una cuenta gratuita.',
    'Personalize your listening': 'Personaliza lo que escuchas',
    'To listen to this artist and more, without interruption, create your free account.': 'Para escuchar a este artista y más, sin interrupción, crea tu cuenta gratuita.',
    'Listen to {stationName}': 'Escuchar {stationName}',
    'Listen to Artist Stations': 'Escuchar Estaciones de Artistas',
    'To listen to music based on this song, create your free account.': 'Para escuchar música basada en esta canción, crea tu cuenta gratuita.',
    'Listen to songs like {stationName}': 'Escuchar canciones como {stationName}',
    'Listen to songs like this': 'Escuchar canciones como esta',
    'To listen to this podcast and more, create your free account.': 'Para escuchar este podcast y más, crea tu cuenta gratuita.',
    'Listen to Podcasts': 'Escuchar Podcasts',
    'To hear all your favorite songs and artists on your own personal station, create a free account.': 'Para escuchar todas tus canciones y artistas favoritos en tu propia estación personal, crea una cuenta gratuita.',
    'Listen to {stationName}!': 'Escuchar {stationName}',
    'Listen to Your Favorites Radio!': '¡Escucha tus estaciones favoritas!',
    'To listen, create a free account.': 'Para escuchar, crear una cuenta gratuita.',
    'Listen to Favorites Radio!': '¡Escuchar mis estaciones favoritas!',
    'All your favorite music. All your favorite stations. All free.': 'Toda tu música favorita. Todas sus estaciones favoritas. Todo gratis.',
    'Create Your iHeart Account': 'Crea tu cuenta de iHeart',
    'Please create a free account.': 'Por favor crea una cuenta gratuita.',
    'Register first': 'Regístrate primero',
    'Create a FREE account to add songs and albums to your playlist': 'Crea una cuenta GRATUITA para añadir canciones y álbumes a tu playlist',
    'Welcome to the All Access FREE Preview Weekend. Create your FREE account and listen to millions of songs, live radio, playlists, and podcasts – enjoy All Access, FREE this weekend only.': 'Bienvenido a All Access FREE Preview Weedend.  ¡Crea una cuenta gratuita y escucha millones de canciones, estaciones en vivo, playlists, y podcasts – Disfruta gratis de All Access solo este fin de semana.',
    'Your Halloween treat is here': 'Tu promoción de Halloween está aquí',
    "To join the Family Plan you've been invited to, please create a free account": 'Para unirte al Plan Familiar que te han invitado, por favor crea una cuenta.',
    'Create a free account to join your Family Plan': 'Crea una cuenta gratuita para unirte al Plan Familiar',
    'Please correct all password errors': 'Por favor corrige los errores en la contraseña',
    'Sorry, you are not eligible to register for iHeart': 'Lo sentimos, no eres elegible para registrarte en iHeart.',
    'Postal Code (optional)': 'Código Postal (opcional)',
    'Zip Code': 'Código Postal',
    'Please enter a valid birth year': 'Por favor introduzca un año de nacimiento válido',
    'Receive email updates on stations I listen to and more from iHeart.': 'Recibe correos de iHeart con noticias de las estaciones que escuchas.',
    'By creating an account, you also agree to our {termsOfServiceLink} and {privacyPolicyLink}.': 'Al crear una cuenta también está de acuerdo con nuestros {termsOfServiceLink} y {privacyPolicyLink}.',
    'iHeartRadio is provided by iHeartMedia and Bell Media. This app collects info to locate (e.g., IP address), identify (e.g., web activities), and profile (e.g., iHeartRadio use) you to enhance the site, analyze usage, and enable marketing (e.g., contextual advertising). By registering, you consent to the processing of your personal information for these purposes and agree to the {termsOfService}, {iHeartPolicy} and {bellPolicy}.': 'iHeartRadio es proveído por iHeartMedia y Bell Media. Esta aplicación recolecta información para ubicar (p. ej. dirección IP), identificar (p. ej. actividades web), y crear perfil (p. ej. uso de iHeartRadio) para mejorar el sito, analizar el uso, y habilitar acciones de mercadotecnia (p. ej. publicidad contextual). Al registrarte, aceptas el procesamiento de tu información personal para estos propósitos y aceptas también los {termsOfService}, {iHeartPolicy} y {bellPolicy}.',
    'Or Connect With': 'O conectar con',
    'Login with Facebook': 'Conéctate con Facebook',
    'Login with Google': 'Conéctate con Google',
    'Added By iHeart': 'Agregado por iHeart',
    'Suggestions based on the songs you added.': 'Sugrencias con base en las canciones agregadas.',
    'You have All Access': 'Tienes All Access',
    'Upgrade to All Access': 'Mejora a All Access',
    'Want to keep enjoying these perks after the weekend?': '¿Quieres mantener estos beneficios después del fin de semana?',
    'Listen offline, unlimited skips, save and play songs from the radio.': 'Escucha sin conexión, saltos ilimitados, guarda y reproduce canciones de la radio',
    'Learn More': 'Aprender más',
    'Start 30 Day Free Trial': 'Inicia la prueba gratis de30 días.',
    'We use cookies.': 'Utilizamos cookies',
    'This website uses cookies for the purposes of enhancing the site, analyzing usage, and marketing, including contextual advertising. If you click “Accept All Cookies,” you also consent to the use of cookies and processing of your personal information by iHeartMedia and Bell Media to locate (e.g., IP address), identify (e.g., web activities), and profile (e.g., iHeartRadio use) you for the purpose of targeted advertising. {termsOfService}, {iHeartPolicy} and {bellPolicy}.': 'Este sitio de internet utiliza cookies con el propósito de mejorar el sitio, analizar el uso, y hacer mercadotecnia, incluyendo publicidad contextual. Al hacer clic en "Aceptar todas las cookies", también aceptas el uso de cookies y procesamiento de tu información personal por iHeartMedia y Bell Media para ubicar (p. ej. dirección IP), identificar (p. ej. actividades web), y crear tu perfil (p. ej. uso de iHeartRadio) con el propósito de ofrecer publicidad dirigida.{termsOfService}, {iHeartPolicy} y {bellPolicy}',
    'Accept All Cookies': 'Aceptar todas las cookies',
    'Cookies and Advertising': 'Cookies y Publicidad',
    'No Thanks': 'No gracias',
    Agree: 'Acepto',
    'You will lose unlimited skips, replay, and access to your playlist': 'Perderás los saltos ilimitados, replay y acceso a tu playlist.',
    'You will lose offline listening, unlimited playlists, and replay': 'Perderás la escucha sin conexión, playlists ilimitados y replay.',
    'Are you sure you want to cancel?': '¿Seguro que quieres cancelar?',
    'Your subscription ends on {date}': 'Tu suscripción termina en {date}',
    'Your playlists will be waiting for you should you decide to subscribe again.': 'Tus playlists te estarán esperando si decides suscribirte otra vez',
    'Keep Current Plan': 'Mantener el plan actual.',
    'Got It': 'Lo tengo!',
    'Cancel Plan': 'Cancelar plan',
    'Trending - Just Now': 'Tendencias - Ahora mismo',
    'Help Center': 'Centro de ayuda',
    'There was an error updating your email': 'Ocurrió un error actualizando tu correo',
    'Update Contact Information': 'Actualizar información de contacto',
    'Please enter your first name': 'Por favor ingresa tu nombre',
    'Add your first name': 'Agrega tu nombre',
    'First Name': 'Nombre',
    'Please enter your last name': 'Por favor ingresa tu apellido',
    'Add your last name': 'Agrega tu apellido',
    'Last Name': 'Apellido',
    'Please enter a valid phone number': 'Por favor ingresa un código postal válido',
    'Phone Number': 'Número de teléfono',
    'Please enter a valid postal code': 'Por favor ingresa un código postal válido',
    'Visit Our {helpCenterLink}': 'Visita nuestro {helpCenterLink}',
    'This email address is already associated with an iHeart account': 'Este correo electrónico ya está asociado a una cuenta de iHeart',
    'Emails must match': 'Los correos electrónicos deben coincidir',
    'Change Email': 'Cambiar correo electrónico',
    'Please enter the same value again': 'Por favor introduce el mismo valor nuevamente',
    'Please enter your password': 'Por favor ingresa tu contraseña',
    'Or, Visit Our {helpCenterLink}': 'O visita nuestro {helpCenterLink}',
    "Password doesn't match our records": 'La contraseña no coincide con nuestros registros',
    "Uh-oh passwords don't match": 'Las contraseñas no coinciden',
    'Your new password cannot be the same as your current password': 'Tu nueva contraseña no puede ser la misma que la actual',
    'Password can only have English letters, numbers, spaces, and special characters from: dot, exclamation point, question mark, dash, underscore, comma, semicolon, colon, forward slash, backslash, open parenthesis, close parenthesis, open bracket, close bracket, tilde, at sign, number sign, dollar sign, percent sign, caret, ampersand, asterisk, plus sign, equal sign, apostrophe, quotation mark, grave accent, vertical bar, open curly bracket, close curly bracket, less than sign, and greater than sign': 'La contraseña solo puede tener letras, números, espacios y los siguientes caracteres especiales: punto, signo de exclamación, signo de interrogación, guion, guion bajo, coma, punto y coma, dos puntos, barra oblicua, barra invertida, apertura de paréntesis, cierre de paréntesis, tilde, arroba, signo de número, signo de moneda, signo de porcentaje, intercalación, ampersand, asterisco, signo de más, signo de igual, apóstrofe, comillas, acento grave, barra vertical, apertura de corchete, cierre de corchete, signo menor que y signo mayor que.',
    'Update Password': 'Actualizar contraseña',
    'Old Password': 'Contraseña anterior',
    'New Password': 'Nueva contraseña',
    'Confirm Password': 'Confirmar contraseña ',
    Update: 'Actualizar',
    'Are you sure you want to continue?': '¿Estás seguro que deseas continuar?',
    'Signed Out': 'Sesión concluida',
    'You have been signed out. Please log back in.': 'Has cerrado sesión. Por favor vuelve a iniciar sesión.',
    '© {fourDigitYear} iHeartMedia, Inc.': '© {fourDigitYear} iHeartMedia, Inc.',
    Help: 'Ayuda',
    'Terms of Use': 'Términos de Uso',
    'Playlist "{playlistName}" created': 'Playlist "{playlistName}" creada',
    'Create unlimited playlists with iHeart All Access.': 'Crea playlists ilimitadas con iHeart All Access',
    'There was an error creating your playlist': 'Ocurrió un error al crear tu playlist',
    'Device Limit': 'Límite de dispositivos',
    "This stream stopped because you're listening on another device.": 'Esta reproducción se detuvo porque estás escuchando en otro dispositivo.',
    'Listen on this device': 'Escuchar en este dispositivo',
    'Got it': '¡Entendido!',
    'Album saved to Your Library': 'Álbum salvado a Tu Colección',
    'Save Album': 'Guardar álbum',
    'Saved {name} to Your Library': '{name} se ha salvado en Tu Colección',
    'Removed {name} from Your Library': 'Se eliminó {name} de Tu Colección',
    'Follow Artist': 'Seguir Artista',
    'Remove Artist': 'Eliminar Artista',
    'Not for Me': 'No es para mí',
    Delete: 'Borrar',
    'Follow station': 'Seguir estación',
    'Unfollow station': 'Dejar de seguir estación',
    Connect: ' Conectar',
    'Call ': 'Llamada',
    'Text ': 'Texto',
    'Edit Playlist': 'Editar Playlist',
    'Delete Playlist': 'Borrar Playlist',
    'Unfollow Playlist': 'Dejar de seguir Playlist',
    'Follow Playlist': 'Seguir Playlist',
    'Followed {title}': 'Has seguido {title}',
    'Unfollowed {title}': 'Has dejado de seguir {title}',
    'Follow Podcast': 'Seguir Podcast',
    'Unfollow Podcast': 'Dejar de seguir Podcast',
    'No added songs yet': 'No has agregado canciones todavía',
    'Tap ': 'Haz clic',
    Save: 'Guardar',
    ' on the player to add a song to My Playlist': 'en el reproductor para añadir la canción a Mi Playlist',
    'Click on the': 'Haz click en',
    'button next to a song': 'el botón al lado de una canción',
    'and select Add to Playlist.': 'y selecciona Añadir a Playlist',
    'Find Music': 'Encuentra música',
    'Uh oh, we can’t find the page you’re looking for.': 'Uy, no podemos encontrar la página que buscas.',
    'How about this page instead: ': 'Qué tal esta página en su lugar: ',
    'Read more': ' Leer más ',
    Explicit: 'Explícito',
    E: 'E',
    'Invite sent to {email}': 'Invitación enviada a {email}',
    'Add family member...': 'Agregar miembro de la familia...',
    'email@example.com': 'email@example.com',
    'Send Invite': 'Enviar invitación',
    You: 'Tú ',
    Active: 'Activo',
    '{email} has been removed.': '{email} ha sido eliminado.',
    'Remove Member': 'Quitar miembro',
    'Cancel Invite': 'Cancelar invitación',
    'Resend Invite': 'Reenviar invitación',
    'Invite was sent on ': 'La invitación fue enviada en',
    'Active ✓': 'Activo ✓',
    'Pending...': 'Pendiente...',
    'Invitees must be 14 years or older': 'Los invitados deben tener 14 años o más',
    Owner: 'Dueño',
    Following: 'Siguiendo',
    Follow: 'Seguir',
    'Music, radio and podcasts, all free. Listen online or download the iHeart App.': 'Música, radio y podcasts, todo gratis. Escucha en línea o descarga la aplicación iHeart.',
    'Connect with Facebook': 'Conéctate con Facebook',
    'Connect with Twitter': 'Conéctate con Twitter',
    'iHeart Instagram': 'iHeart Instagram',
    'iHeart Youtube': 'iHeart Youtube',
    Explore: 'Explorar',
    iHeart: 'iHeart',
    'Live Radio': 'Estaciones en Vivo',
    Podcasts: 'Podcasts',
    'On Demand Podcasts': 'Podcasts en demanda',
    'Artist Radio': 'Estación de Artista',
    Playlists: 'Playlists',
    Genres: 'Género',
    'Browse by Genre': 'Buscar por género',
    Exclusives: 'Exclusivo ',
    News: 'Noticias',
    'iHeart News': 'Noticias de iHeart',
    Features: 'Presenta',
    'Featured Exclusives on iHeart': 'Exclusivas presentadas en iHeart',
    Events: 'Eventos',
    'iHeart Events': 'Eventos de iHeart',
    Contests: 'Concursos',
    'iHeart Contests': 'Concursos de iHeart',
    Photos: 'Fotos',
    'iHeart Photos': 'Fotos de iHeart',
    Information: 'Información',
    About: 'Acerca de ',
    'About iHeart': 'Acerca de iHeart',
    Advertise: 'Anunciarse',
    'Advertise on iHeart': 'Anúnciate en iHeart',
    Blog: 'Blog',
    'iHeart Blog': 'Blog de iHeart',
    'Brand Guidelines': 'Lineamientos de la marca',
    'iHeart Logo and Guidelines': 'Logotipo de iHeart y Lineamientos',
    'Contest Guidelines': 'Reglas del concurso',
    'iHeart Contest Rules': 'Reglas de concurso de iHeart',
    'Subscription Offers': 'Ofertas de suscripción',
    Jobs: 'Trabajos',
    'iHeart Jobs': 'Trabajos en iHeart',
    'Get the App': 'Obtener la aplicación',
    Automotive: 'Automotor',
    'iHeart in your house': 'iHeart en tu casa',
    Home: 'Página Principal',
    'iHeart in your car': 'iHeart en tu automóvil.',
    Mobile: 'Móvil',
    'iHeart on your phone or tablet': 'iHeart en tu teléfono o tableta',
    Wearables: 'Wearables',
    'iHeart wearables': 'Wearables de iHeart',
    'Your Library': 'Tu Colección',
    'Saved {name} to {savedStationsLink}': 'Se guardó {name} en {savedStationsLink}',
    'Removed {name} from {savedStationsLink}': 'Se eliminó {name} de {savedStationsLink}',
    'Followed {podcastName}': 'Has seguido {podcastName}',
    'Unfollowed {podcastName}': 'Has dejado de seguir {podcastName}',
    "We won't play this song again on this station.": 'No volveremos a tocar esta canción en esta estación.',
    'Thanks for your feedback.': 'Gracias por tus comentarios.',
    'favorite songs': 'canciones favoritas',
    "We'll play more like it. Check out your {favoriteSongsLink} to see everything you've liked.": 'Tocaremos más como esto. Echa un vistazo a tu {favoriteSongsLink} para ver todo lo que te ha gustado.',
    "We'll let our DJs know you've heard enough of this song.": 'Le avisaremos a nuestros programadores que ya has escuchado mucho esta canción.',
    "Check out your {favoriteSongsLink} to see everything you've liked.": 'Echa un vistazo a tu {favoriteSongsLink} para ver todo lo que te ha gustado.',
    "Glad you like it. We'll let our DJs know.": 'Nos alegra que te guste. Le informaremos a nuestros programadores.',
    "OK, we'll adjust your music mix.": 'Bien, ajustaremos tu mezcla de música.',
    'Favorites Radio': 'Estaciones Favoritas',
    'Great, we’ll add this song to your own {favoritesRadioLink}!': 'Genial, vamos a añadir esta canción a su {favoritesRadioLink}!',
    'Great, we’ll play you more songs like this': 'Genial, tocaremos más canciones como ésta',
    'We appreciate you letting us know.': 'Agradecemos que nos avises.',
    'Nice! You’ll hear this song more often.': '¡Excelente! Escucharás está canción más seguido.',
    "You've reached your skip limit.": 'Has alcanzado tu límite de saltos.',
    "We're sorry, you can't skip any more songs right now. You can still thumb down a song. Want to know more? Visit {helpLink}": 'Lo sentimos, no puedes saltarte más canciones ahora. Puedes seguir indicando que te gustan las canciones. ¿Quieres saber más? Visita {helpLink}',
    'Report this issue.': 'Reporta este problema',
    "There's an error with episodes for this show. {reportLink}": 'Hay un error con los episodios en este podcast {reportLink}',
    "There's an error with this content. {reportLink}": 'Hay un error con este contenido {reportLink}',
    'Playlist followed and saved to {link}': 'Playlists seguidas y guardadas en {link}',
    'Playlist unfollowed and removed from {link}': 'Playlists no seguidas y eliminadas de {link}',
    'Click here': 'hacer clic aquí ',
    'Having trouble logging into iHeart with your Facebook or Google account? {clickHereLink} for help.': '¿Tienes problemas para iniciar sesión en iHeart con tu cuenta de Facebook o Google? {clickHereLink} para obtener ayuda.',
    More: 'Más',
    Subscribe: 'Suscríbete',
    Upgrade: 'Mejorar',
    'Upgrade Now': 'Mejora ahora',
    Settings: 'Configuración.',
    'Log Out': 'Cerrar sesión',
    Account: 'Cuenta',
    'Sponsor Content': 'Contenido del patrocinador',
    'Mark As Unplayed': 'Marcar como no reproducido',
    'Mark as Played': 'Marcar como reproducido',
    'This station is not currently available': 'Esta estación no está disponible actualmente',
    'Please pick a different station or listen to this station using our free iHeart app': 'Por favor escoge una estación diferente o escucha esta estación usando nuestra aplicación gratuita iHeart',
    'Listen in App': 'Escuchar en la aplicación',
    "You haven't thumbed up any songs yet.": ' Aún no has indicado que te gusta alguna canción.',
    'Favorite Songs': 'Canciones Favoritas',
    NEW: 'NUEVO',
    'Welcome to': 'Bienvenido a',
    'Your Favorites Radio!': '¡Tus estaciones favoritas!',
    'All your favorites and thumbs up in one station. The more you interact with iHeart, the better it gets.': 'Todas las canciones que te gustaron y tus favoritos en una estación. Cuanto más interactúes con iHeart, mejor lo hará.',
    'Please name your station now:': 'Por favor, da un nombre a tu estación:',
    'On Air': 'Al Aire',
    Share: 'Compartir',
    'Go To Album': 'Ir a Álbum',
    'Go To Artist': 'Ir a Artista',
    'Go To Station': 'Ir a la estación',
    'Unfollow Station': 'Dejar de seguir Estacíón',
    'Follow Station': 'Seguir Estación',
    'Go To Your Library': 'Ir a Tu Colección',
    'Go To Playlist': 'Ir a Playlist',
    'Share Episode': 'Compartir episodio',
    'Share from': 'Compartir desde',
    Transcript: 'Transcripción',
    Pause: 'Pausa',
    Stop: 'Parar',
    Play: 'Tocar',
    'Play Newest': 'Más reciente',
    'Create unlimited playlists. Try iHeart All Access.': 'Crea playlists ilimitadas. Prueba iHeart All Access',
    'My Music': 'Mi Música',
    'Theme Radio': 'Tema de Radio',
    Playlist: 'Playlist',
    'Playlist by': 'Playlist por',
    Podcast: 'Podcast',
    'Thanks for listening!': '¡Gracias por escuchar!',
    Rename: 'Renombrar',
    Edit: 'Editar',
    'Add to Another Playlist': 'Agregar a otro Playlist',
    Shuffle: 'Aleatorio',
    'Added By You': 'Agregada por Ti',
    'Recently Played': 'Tocado Recientemente',
    'You like {station} on Facebook': 'Te gusta {station} en Facebook',
    'You listened to {station}': 'Escuchaste {station}',
    'You are near {city}': 'Estás cerca de {city}',
    'You like {genre}': 'Te gusta {genre}',
    'Just For You': 'Sólo Para Ti.',
    'Popular On {iHeart}': 'Popular en {iHeart}',
    'Discover how you can save and replay music on your favorite radio stations, or download and listen offline to millions of songs!': 'Descubre cómo puedes guardar y volver a escuchar música en tus estaciones de radio favoritas, o descargar y escuchar millones de canciones sin conexión!',
    'iHeart Plus & All Access - Upgrade to a Free Trial': 'IHeart Plus y All Access - Mejora a una prueba gratuita',
    'Playlist deleted': 'Playlist ha sido borrado ',
    'Select a song to replay': 'Seleccione una canción para repetirla',
    'Save Playlist': 'Guardar Playlist',
    All: 'Todos',
    'Live Stations': 'Estaciones en vivo',
    Artists: 'Artistas',
    Albums: 'Álbumes',
    Songs: 'Canciones',
    'artists, stations or podcasts': 'artistas, estaciones o podcasts',
    'stations or podcasts': 'estaciones o podcasts',
    podcasts: 'podcasts',
    search: 'buscar',
    'Album{bulletSeparator}{artistName}': 'Album{bulletSeparator}{artistName}',
    'Top Result': 'Mejor resultado',
    'Search Results': 'Resultados de la búsqueda',
    'Show More Results': 'Mostrar más resultados',
    'Search iHeart for artists, stations or podcasts.': 'Busca artistas, estaciones o podcasts en iHeart.',
    'Search iHeart for stations or podcasts.': 'Busca estaciones o podcasts en iHeart.',
    'Search iHeart for podcasts.': '  Busca podcasts en iHeart',
    'There are no results for "{searchQuery}"': 'No hay resultados para "{searchQuery}"',
    'Check your spelling or try another search': 'Revisa la ortografía o intenta otra búsqueda',
    'Show all Live Stations': 'Mostrar todas las Estaciones en Vivo',
    'Show all Artists': 'Mostrar todos los Artistas',
    'Show all Albums': 'Mostrar todos los Álbumes',
    'Show all Songs': 'Mostrar toda las Canciones',
    'Show all Playlists': 'Mostrar todas las Playlists',
    'Show all Podcasts': 'Mostrar todos los Podcasts',
    'Show all {title}': 'Mostrar todo {title}',
    'Embed Widget': 'Incrustar widget',
    Twitter: 'Twitter',
    'See {moreTiles} of {itemCount}': 'Ver {moreTiles} de {itemCount}',
    'See All': 'Ve todo',
    'See Less': 'Ver menos',
    'For You': 'Para Ti',
    'Get The App': 'Obtener la aplicación',
    'Connect with ': 'Conectar con',
    'on your favorite social network ': 'en tu red social favorita',
    ' Song': 'Canción',
    'Keep Thumbing!': '¡Sige indicando que te gusta! ',
    'Thumbing up songs personalizes the stations you listen to and adds them to Your Favorites Radio.': 'Indicar que te gustan las canciones, personaliza las estaciones que escuchas y las añade a tu Radio de Favoritos',
    '1 song': '1 canción',
    'All your favorite songs and artists.': 'Todas tus canciones y artistas favoritos.',
    'Show Less': 'Mostrar menos',
    'Show More': 'Mostrar más',
    Schedule: ' Programación ',
    'Listen offline': 'Escuchar sin conexión',
    'Unlimited skips': 'Saltos ilimitados',
    'Save songs from radio to playlists': 'Guarda canciones de la radio en playlists',
    'Instantly replay songs from radio': 'Vuelve a escuchar canciones de la radio',
    'Create unlimited playlists': 'Crea playlists ilimitados',
    'Play millions of songs on demand': 'Toca millones de canciones en demanda',
    'Now you can create your own playlists with All Access!': '¡Ahora puedes crear tus propias playlists con All Access!',
    'Start Free 30 Day Trial': 'Iniciar prueba gratuita de 30 días',
    'Subscribe to All Access': 'Suscríbete a All Access',
    'is now better than ever!': '¡ahora es mejor que nunca!',
    'All your favorite music, podcasts, and radio stations available for free. Listen to thousands of live radio stations or create your own artist stations and playlists. Get the latest music and trending news, from your favorite artists and bands.': 'Toda tu música favorita, podcasts, y estaciones disponibles de forma gratuita. Escucha tus estaciones favoritas en vivo o crea tus propias estaciones de artistas y playlists. Obtén las últimas noticias de música y tendencias de tus artistas y grupos favoritos.',
    'Stream thousands of the best live radio stations and custom artist stations for FREE - all in one app.': 'Escucha miles de las mejores estaciones en vivo y estaciones de artistas personalizados de forma GRATUITA-todo en una aplicación.',
    'Stream thousands of the best live radio stations for FREE, plus unlimited music on demand - all in one app.': 'Escucha miles de las mejores estaciones en vivo GRATIS, además de música ilimitada a petición-todo en una aplicación.',
    'Stream thousands of the best live radio stations for FREE - all in one app.': 'Escucha miles de las mejores estaciones en vivo GRATIS-todo en una aplicación.',
    'All Genres': 'Todos los géneros',
    Featured: 'Destacados',
    Popular: 'Popular',
    'You are now logged in as {profileName}': 'Ahora estás conectado como {profileName}',
    Artist: 'Artista',
    'Song by': 'Canción de',
    Song: 'Canción',
    'Live Station': 'Estación en vivo',
    'Album by': 'Álbum de',
    Album: 'Álbum',
    'Email or password is invalid. Please try again.': 'El correo electrónico o la contraseña no son válidos. Por favor inténtalo de nuevo.',
    'There was an issue playing the audio, please check your connection and try again.': 'Hubo un problema al reproducir el audio, por favor revisa tu conexión e intenta nuevamente.',
    'There was a connection problem. Please reconnect and try again.': 'Hubo un problema en la conexión. Por favor conéctate e intenta nuevamente. ',
    'See More': 'Ver más',
    'all access': 'All Access',
    'Best Playlists': 'Mejores Playlists',
    'concert pictures': 'Fotos del concierto',
    'create playlists': 'Crear playlists',
    'custom radio station': 'Estación de radio personalizada',
    'event pictures': 'Fotos del evento',
    'favorite music': 'Música favorita',
    'Music Playlists': 'Playlist de música ',
    'my music': 'Mi Música',
    'Now Playing': 'Tocando Ahora',
    'On Demand': 'En Demanda',
    'Online Playlists': 'Playlist en línea',
    'paid music': 'Música pagada',
    'paid songs': 'Canciones pagadas',
    'personal radio station': 'Estación de radio personal',
    'personalized radio station': 'Estación de radio personalizada',
    'play songs': 'Tocar canciones',
    'Top Playlists': 'Top Playlists',
    'recent photos': 'Fotos recientes',
    'recent pictures': 'Fotos recientes',
    'saved music': 'música guardada',
    'saved songs': 'canciones guardadas',
    'top playlists': 'Los mejores playlists',
    App: 'App',
    Bands: 'La Banda',
    Best: 'Mejor',
    Discover: 'Descubrir',
    Download: 'Descargar',
    Free: 'Gratis',
    Influencers: 'Influencers',
    Influences: 'Influencias',
    Internet: 'Internet',
    Listen: 'Escuchar',
    Live: 'En vivo ',
    Music: 'Música',
    Online: 'En línea',
    Personalized: 'Personalizado',
    photos: 'Fotos',
    pictures: 'Fotos',
    Radio: 'Radio',
    Related: 'Relacionado',
    Similar: 'Similar',
    Station: 'Estación',
    Stations: 'Estaciones',
    stream: 'Transmitir',
    Streaming: 'Transmitiendo',
    Talk: 'Hablar',
    Top: 'Mejores',
    January: 'Enero',
    February: 'Febrero',
    March: 'Marzo',
    April: 'Abril',
    May: 'Mayo',
    June: 'Junio',
    July: 'Julio',
    August: 'Agosto',
    September: 'Septiembre',
    October: 'Octubre',
    November: 'Noviembre',
    December: 'Diciembre',
    Jan: 'Ene',
    Feb: 'Feb',
    Mar: 'Mar',
    Apr: 'Abr',
    Jun: 'Jun',
    Jul: 'Jul',
    Aug: 'Ago',
    Sep: 'Sep',
    Oct: 'Oct',
    Nov: 'Nov',
    Dec: 'Dic',
    'Password must have': 'La contraseña debe tener',
    'iHeart: Listen to Free Radio Stations & Music Online | iHeart': 'iHeart: escucha estaciones de radio gratuitas y música en línea | iHeart',
    '1 Song': '1 Canción',
    'Listen to music you love! Create a custom radio station based on your favorite album by {artistName} on iHeartRadio!': '¡Escucha la música que amas! ¡Crea una estación de radio personalizada basada en tu álbum favorito de {artistName} en iHeartRadio!',
    'Stream Free Music from Albums by {artistName}': 'Escucha música gratis basada en álbumes de {artistName}',
    'Create Artist Radio Stations': 'Crea estaciones de Radio de Artistas',
    'Popular {genre} Artists': 'Artistas populares de {genre}',
    'Artist Radio gives you the power to create custom stations from your favorite songs, albums or musical artists. Listen for free on iHeart!': 'Radio de Artista te da el poder para crear estaciones personalizadas con base en tus canciones favoritas, álbumes o artistas . Escucha de forma gratuita en iHeart!',
    'Create a personalized custom music station from thousands of {name} artists you love, only on iHeart!': 'Crea una estación de música personalizada a partir de miles de {name} artistas que amas, solo en iHeart!',
    'Create Custom Radio Stations on Artist Radio!': 'Crea estaciones de radio personalizadas en Radio de Artista!',
    'Listen to the Best {name} Music for Free on iHeart': 'Escucha la mejor {name} música gratuitamente en iHeart',
    '{artistName}': '{artistName}',
    '{name}, News, Music, Headlines, iHeartRadio, iHeart, Radio': '{name}, Noticias, Música, Titulares, iHeartRadio, iHeart, Radio',
    'Latest {name} news from iHeart': 'Últimas {name} noticias de iHeart',
    'Read the latest News on {name} Online': 'Lea las últimas noticias de {name} en línea',
    Biography: 'Biografía',
    'Similar Artists': 'Artistas similares',
    'Latest Release': 'Último lanzamiento ',
    'Popular On': 'Popular en',
    "This artist doesn't have any top songs.": 'Este artista no tiene mejores canciones.',
    'Top Songs': 'Mejores canciones',
    'Stream Music from Artists Like {artistName}': 'Escucha música de artistas como {artistName}',
    "Listen to music you'll love! Create a custom radio station from your favorite songs by {artistName} on iHeart.": 'Escucha la música que amarás! Crea una estación de radio personalizada a partir de tus canciones favoritas por {artistName} en iHeart.',
    'Stream Free Songs by {artistName} & Similar Artists': 'Escucha canciones gratis de {artistName} y artistas similares ',
    'Almost there! Verify your zipcode to unlock your spot on the Family Plan': '¡Ya casi! Verifica tu código postal para desbloquear tu lugar en el plan familiar',
    'In order to add you to the plan, we need to make sure that you live at the same address as the plan owner. Please input your zipcode below to verify and unlock your account.': 'Para agregarte al plan, necesitamos asegurarnos de que vives en la misma dirección que el propietario del plan. Por favor, ingresa tu código postal para verificar y desbloquear tu cuenta.',
    'Household Zipcode': 'Código postal del hogar',
    'e.g 90210...': 'ej. 90210',
    'help site': 'Sitio de ayuda',
    "It looks like you're already subscribed": 'Parece que ya estás suscrito',
    "You must cancel your active subscription before you can continue. You can find out how to cancel your subscription at {supportLink}. Once you've cancelled your current subscription plan you will need to wait until your current subscription plan ends.": 'Debes cancelar tu suscripción activa antes de poder continuar. Puedes averiguar cómo cancelar tu suscripción en {supportLink}. Una vez que hayas cancelado tu plan de suscripción actual, deberás esperar hasta que finalice el plan de suscripción actual.',
    'If you have any questions, please {helplink} contact us.': 'Si tiene alguna pregunta, por favor {helplink} póngase en contacto con nosotros.',
    'Please create a free account, or sign in': 'Por favor crea una cuenta gratuita o inicia sesión.',
    'To accept the Family Plan invitation, you must be logged in.': 'Para aceptar la invitación al plan familiar debes tener sesión iniciada.',
    'Welcome to Your Favorites Radio, a personalized station containing your favorite songs and artists. The more you thumb up and save stations on iHeart, the better your listening experience will be.': 'Bienvenido a tu Radio de Favoritos, una estación personalizada con tus canciones y artistas favoritos. Entre más indiques que te gustan canciones o sigas estaciones en iHeart, mejor será tu experiencia de escucha. ',
    'Listen to {title}. Thumb up songs to add them to your own Your Favorites Radio station. Find it under My Stations.': 'Escucha {title}. Indica que te gustan canciones para agregarlas a tu Radio de Favoritos. Encuéntrala en Estaciones Seguidas.',
    'Featured Artists On This Station': 'Artistas destacados en esta estación.',
    "{name}'s Favorites Radio": 'Radio de Favoritos de {name}',
    'iHeartRadio Mobile Apps': 'Aplicaciones móviles de iHeartRadio',
    'Made for You': 'Hecho para ti',
    'Listen to Stations For You': 'Escucha estaciones Para Ti',
    'Change genre preferences {prefLink}': 'Cambiar preferencias de género {prefLink}',
    here: 'aquí',
    'iHeart: Listen to Free Radio Stations & Music Online': 'iHeart: escucha estaciones de radio y música gratis en línea',
    'All Your Favorite Music. All Your Favorite Stations. All FREE. Listen to thousands of live stations or create your own artist stations. Let the music play!': 'Toda tu Música Favorita. Todas tus Estaciones Favoritas. TODO GRATIS. Escucha miles de  estaciones en vivo o crea tu propia estación de artista. Deja que suene la música!',
    'Improve Your Recommendations': 'Mejorar tus recomendaciones',
    'Change Preferences': 'Cambiar preferencias',
    'Change Genre Preferences': 'Cambiar preferencias de género',
    'Find Stations by Genre': 'Buscar estaciones por género',
    'Finding your ideal station is quick and easy. Search for stations by genre & stream them online for free on iHeart.': 'Encontrar tu estación ideal es rápido y fácil. Busca estaciones por género y escúchalas en línea gratis en iHeart.',
    'Discover Music and Radio Stations by Genre on iHeart': 'Descubre música y estaciones de radio por género en iHeart',
    'Pick Genre': 'Selección de géneros',
    'Tell us all the genres you like.': 'Dinos todos los géneros que te gustan.',
    "We'll suggest stations just For You.": 'Te sugeriremos estaciones solo Para Ti.',
    'Get Stations': 'Obtener estaciones',
    'Find and stream {genreName} music stations for free, only on iHeart.': 'Busca y escucha estaciones de {genreName} de forma gratuita, sólo en iHeart.',
    'Listen to {genreName} Radio Stations for Free': 'Escuchar estaciones de radio de  {genreName} gratis',
    'Find {genreName} Stations': 'Encontrar estaciones de {genreName} ',
    'Featured {genreName} Stations': 'Estaciones de {genreName} destacadas',
    'Top {genreName} Stations': 'Mejores estaciones de {genreName}',
    'Top {genreName} Artists': 'Mejores Arstistas de {genreName}',
    'More Genres': 'Más géneros',
    'Latest News': 'Últimas Noticias',
    'Partner Content': 'Contenido de afiliado',
    'All Cities': 'Todas las ciudades',
    'for {currentGenre}': 'para {currentGenre}',
    'in {currentMarket}': 'en {currentMarket}',
    'near you': 'cerca de ti',
    'Find top stations {genreFilterDescription} {marketFilterDescription} {noFiltersMessage} ({numStations})': 'Buscar mejores estaciones {genreFilterDescription} {marketFilterDescription} {noFiltersMessage} ({numStations})',
    'Listen to Live Radio': 'Escuchar estaciones en vivo',
    'No stations currently available for this filter': 'No hay estaciones disponibles actualmente para este filtro',
    'Listen to the Best Live Radio Stations in {countryAbbreviation}': 'Escucha las mejores estaciones de radio en vivo en {countryAbbreviation}',
    'Listen to {genreName} Radio Stations in {city}': 'Escucha {genreName} estaciones de radio en {ciudad}',
    'Listen to Top Radio Stations in {city}, Free': 'Escucha las mejores estaciones de radio en {City}, gratis',
    'Live {genreName} Radio Stations in {countryAbbreviation}': 'Estaciones de radio en vivo de {genreName} en {countryAbbreviation}',
    'Listen to hundreds of the best live radio stations{countryName}, for free! Search for stations near you & around the country.': 'Escucha cientos de las mejores estaciones de radio en vivo de{countryName}, gratis! Busca estaciones cercanas y de todo el país.',
    'Listen to the Best Live Radio{country} for Free on iHeart.': 'Escucha la mejor radio en vivo de {country} gratis en iHeart.',
    'Stream the best live {genreName} radio stations in {city} online for free, only on iHeart!': 'Escucha las mejores estaciones de radio de {genreName} en {city} en línea gratuitamente, solo en iHeart!',
    'Listen to Top {genreName} Radio Stations in {city}': 'Escucha las mejores estaciones de {genreName} en {city}',
    'Listen to the best live radio stations in {city}. Stream online for free, only on iHeart!': 'Escucha las mejores estaciones de radio en vivo en {city}. Conéctate en línea gratuitamente, solo en iHeart!',
    'Listen to Top Radio Stations in {city} for Free': 'Escucha las mejores estaciones de radio en {City}, gratis',
    'Stream the best live {genreName} radio stations in {countryAbbreviation} for free on iHeart.': 'Escucha las mejores estaciones de radio en vivo de {genreName} en {countryAbbreviation} gratuitamente en iHeart',
    'Listen to Free, Live {genreName} Radio Stations in {countryAbbreviation} on iHeart': 'Escucha gratuitamente, estaciones de radio en vivo de {genreName} en {countryAbbreviation} en iHeart',
    'Contests and Promotions': 'Concursos y Promociones',
    'If you like {stationName}...': 'Si te gusta {stationName}...',
    'Similar Stations': 'Estaciones similiares',
    '{stationName}, {stateAbbreviation}, {city}, {genre}': '{stationName}, {stateAbbreviation}, {city}, {genre}',
    Genre: 'Género',
    Location: 'Ubicación',
    'On Air Schedule': 'Programación al aire',
    'Share Song': 'Compartir canción.',
    'Get the latest music news - rock, pop, hip-hop, rock, alternative, and country music news from iHeart. Get the latest music and trending news, from your favorite artists and bands.': 'Recibe las últimas noticias de música: rock, pop, hip-hop, alternativa y música country en iHeart. Obtén las últimas noticias de música y tendencias de tus artistas y grupos favoritos.',
    'Breaking Music News, Celeb and Entertainment News and Gossip': 'Noticias de última hora, celebridades y espectáculos.',
    'Advertise With Us': 'Anúnciate con nosotros',
    'Discover thousands of top playlists with songs handcrafted by your favorite personalities and music experts on iHeartRadio.': 'Descubre miles de playlists hechos a mano por tus personalidades y expertos en música de iHeartRadio.',
    'Listen to the Best Online Music Playlists': 'Escucha los mejores Playlists de música en línea',
    'Recommended For You': 'Recomendado Para Ti',
    'Moods & Activities': 'Estados de ánimo y actividades',
    Decades: 'Décadas',
    'Featured Playlists': 'Playlists destacadas',
    By: 'Por',
    'Your songs shuffled with songs we think you’ll like. The more songs you add, the better our picks get.': 'Tus canciones mezcladas con canciones que creemos que te gustarán. Entre más canciones agregues, mejores selecciones haremos.',
    'Introducing a new playlist experience': 'Presentando una experiencia nueva de playlist',
    'Upgrade for this playlist': 'Mejora para este playlist',
    'Song saved to Your Library': 'Canción guardada en Tu Colección',
    'Song removed from playlist': 'Canción eliminada de la playlist',
    'Go to Artist': 'Ir al Artista',
    'Go to Album': 'Ir al Álbum',
    'Remove from Playlist': 'Eliminar del Playlist',
    SONG: 'CANCIÓN',
    'ALBUM TITLE': 'TÍTULO DEL ÁLBUM',
    ARTIST: 'ARTISTA',
    DURATION: 'DURACIÓN',
    Done: 'Hecho',
    'Discover and stream the best {categoryTitle} playlists, handcrafted by the music experts at iHeart.': 'Descubre y escucha las mejores playlists de {categoryTitle} hechas a mano por expertos en iHeart.',
    '{categoryTitle}, {categoryTitle} Playlists, Stream {categoryTitle} Music, Play {categoryTitle} Songs, Top {categoryTitle} Playlists, Best { categoryTitle} Playlists': '{categoryTitle}, {categoryTitle} playlists, escucha música de {categoryTitle}, oye canciones de {categoryTitle}, los top playlists de {categoryTitle}, los mejores playlists de { categoryTitle}',
    'Top {categoryTitle} Playlists; Find Your Perfect Music Mix': 'Mejores playlists de {categoryTitle}; encuentra tu mezcla de música perfecta',
    Host: 'Locutor',
    'iHeart Podcast Networks': 'iHeart Podcast Networks',
    '{name}, Blog, News, Music, Headlines, iHeartRadio, iHeart, Radio': '{name}, Blog, Noticias, Música, Titulares, iHeartRadio, iHeart, Radio',
    '{name} Blog, News & Videos': '{name} Blog, Noticias y Videos',
    'Go behind the scenes, get exclusive stories and learn more about the show!': 'Mira detrás del escenario, lee historias exclusivas y aprende más sobre el espectáculo!',
    Played: 'Reproducido',
    'Listen to the best {categoryName} radio shows, free and on demand, only on iHeart.': 'Escucha los mejores programas de radio de {categoryName}, gratuitamente y en demanda, solo en iHeart.',
    'Listen to the Best Free {categoryName} Podcasts': 'Escucha los mejores podcasts de {categoryName} gratis',
    'On Demand, On-Demand, Show, Shows, Personalities, Programs, Listen, iHeartRadio, iHeart': 'On Demand, En Demanda, Espectáculo, Espectáculos, Personalidades, Programas, Escucha, iHeartRadio, iHeart',
    'Discover Top Podcasts': 'Descubre los mejores podcasts',
    'Listen to the latest episodes from top podcasts. Get news, sports & lifestyle info from your favorite personalities for free!': 'Escucha los últimos episodios de los mejores podcasts. ¡Obtén noticias, deportes y estilo de vida de tus personalidades favoritas gratis!',
    'Listen to the Best Podcasts Online for Free on iHeart': 'Escucha los mejores podcasts en línea de forma gratuita en iHeart',
    'Listen to the Best Podcasts & Shows Online, Free': 'Escucha los mejores podcasts y programas en línea, gratis',
    'Talk, Show, Host, Personality, On Demand, Topic, Category, Health, News, Politics, Comedy, Station, Internet, Online, iHeartRadio, iHeart': 'Hablada, Show, locutor, personalidad, en demanda, tema, categoría, salud, noticias, política, comedia, estación, Internet, en línea, iHeartRadio, iHeart',
    'Stream Top Podcasts': 'Escucha los mejores podcasts',
    'All Topics': 'Todos los temas',
    '{name} Podcasts': '{name} Podcasts',
    Topics: 'Temas',
    'All Episodes': 'Todos los episodios',
    "These episodes aren't here yet...": 'Estos episodios todavía no están aquí',
    "Follow this podcast to be notified when they're ready": 'Sigue este podcast para recibir una notificación cuando estén disponibles',
    'Share Podcast': 'Compartir podcast',
    'The Latest News': 'Noticias recientes',
    'Listen to {episodeTitle} | {showTitle} | Podcasts': 'Escuchar {episodeTitle} | {showTitle} | Podcasts',
    '{episodeTitle},{showTitle},Talk,Radio,Listen,On Demand,iHeartRadio,iHeart': '{episodeTitle},{showTitle},Habla,Radio,Escucha, En demanda,iHeartRadio,iHeart',
    '{title}, Talk, Radio, Listen, On Demand, iHeartRadio, iHeart': '{título}, Hablar, Radio, Escuchar,  a Petición, iHeartRadio, iHeart',
    Episodes: 'Episodios',
    'Episode Transcript': 'Transcripción de episodio',
    'Available transcripts are automatically generated. Complete accuracy is not guaranteed.': 'Las transcripciones se generan automáticamente. No se garantiza completa exactitud.',
    'Error Loading Transcript': 'Error al cargar la transcripción',
    'We had a problem rendering this page, please try refreshing your browser': 'Tuvimos un problema al mostrar esta página, por favor trata de recargar tu navegador.',
    'Refresh the page': 'Refrescar la página',
    'Report this issue': 'Reportar este problema',
    'Popular Podcasts': 'Podcasts Populares',
    'Stream the best podcasts from your favorite stations': 'Escucha los mejores podcasts de tus estaciones favoritas',
    'Follow Us On': 'Síguenos en ',
    'Show Links': 'Muestra las ligas',
    'Settings | See Your Music Profile': 'Configuración | Ver tu perfil musical',
    'Log in and see which live radio stations and custom artist stations you love. You can also view your listening history. All free.': 'Inicia sesión y escucha las estaciones de radio o de artista que te encantan. También puedes ver la historia de lo que has escuchado. Todo gratis. ',
    Connections: 'Conexiones',
    Unlink: 'Desconectar',
    Link: 'Conectar',
    'Genre added.': 'Género agregado',
    'Genre removed.': 'Género eliminado.',
    "You've added {genreName} to your genres.": 'Has agregado {genreName} a tus géneros.',
    "You've removed {genreName} from your genres.": 'Has eliminado {genreName} de tus géneros.',
    'Your preferences have been changed.': 'Tus preferencias han sido cambiadas.',
    'Not provided': 'No proporcionado',
    'Publish my listening activity to my Facebook music dashboard': 'Publicar mi actividad de escucha en mi panel de música de Facebook',
    'Update your password, genre selection, and modify your subscription below.': 'Actualizar tu contraseña, selección de género y modificación de suscripción a continuación.',
    "You're currently signed in with a social account, add an email and password to have full access to your account": 'Actualmente tienes sesión iniciada con una cuenta de redes sociales. Agrega un correo y contraseña para tener acceso completo a tu cuenta.',
    'Add New Email': 'Agrega nuevo correo',
    'Contact Information': 'Información de contacto',
    'Update your contact information to personalize your experience and easily enter iHeart contests and promotions.': 'Actualiza tu información de contacto para personalizar tu experiencia y participar en concursos y promociones de iHeart.',
    'First and Last Name': 'Nombre y apellido',
    'Add your full name': 'Ingresa tu nombre completo',
    'Add your postal code': 'Agrega tu código postal',
    'My Genres': 'Mis géneros',
    'Tell us all your favorite genres so we can make iHeart perfect for you.': 'Cuéntanos todos tus géneros favoritos de modo que podamos hacer iHeart perfecto para ti',
    'View Recommendations': 'Ver recomendaciones',
    'Billing History': 'Historial de facturación',
    'Subscribe Again': 'Suscribirse de nuevo',
    'Update Payment': 'Actualizar pago',
    Unknown: 'Desconocido',
    'Unknown Plan Description': 'Descripción del plan desconocida',
    'iHeart Family Plan': 'Plan Familiar iHeart',
    'You are enjoying an unlimited music experience with an iHeart Family Plan.': 'Estás disfrutando de una experiencia musical ilimitada con un plan familiar de iHeart.',
    'iHeart All Access': 'iHeart All Access',
    'You are enjoying an unlimited music experience by subscribing to iHeart All Access.': 'Estás disfrutando de una experiencia musical ilimitada al estar suscrito a iHeart All Access.',
    'iHeart Plus': 'iHeart Plus',
    'You are subscribed to iHeart Plus. Switch to iHeart All Access to enjoy an unlimited music experience on your computer.': 'Estás suscrito a iHeart Plus. Cámbiate a iHeart All Access para disfrutar de una experienica de música ilimitada en tu computadora.',
    'iHeart Free': 'iHeart gratuito',
    'Subscribe to enjoy unlimited music.': 'Suscríbete para disfrutar de música ilimitada.',
    'Your subscription ends on {subscriptionEndsString}.': 'Tu suscripción termina en {subscriptionEndsString}.',
    'Family Members': 'Miembros de la familia',
    Subscription: 'Suscripción',
    'Your Favorite Songs | Log In & See': 'Tus canciones favoritas | Inicia sesión y ve',
    'Log in or sign up and find the songs you love here, only on iHeart.': 'Inicia sesión o regístrate y encuentra las canciones que amas aquí, sólo en iHeart.',
    'iHeart Codes Coupons and Special Offers for Unlimited Music': 'Cupones de códigos iHeart y ofertas especiales para música ilimitada.',
    'Find iHeart Radio special offers, codes and coupons for unlimited music on demand and all access to iHeart Plus. These special offers provide access to millions of songs, thousands of radio and artists stations with unlimited skips and offline listening!': 'Encuentra ofertas especiales de iHeart, códigos y cupones para música ilimitada en demanda y acceso a iHeart Plus. Estas ofertas especiales proporcionan acceso a millones de canciones, miles de etaciones de radio y estaciones de artistas, con saltos ilimitados y disponibilidad sin conexión.',
    'There was an error validating your request.': 'Ocurrió un error validando tu petición',
    'Please enter a valid email address': 'Por favor ingresa un correo electrónico válido',
    'Please fix the following errors:': 'Por favor corrige los siguientes errores:',
    'Start Your Free 30 Day Trial': 'Inicia tu prueba gratuita de 30 días.',
    'Start Your Family Plan': 'Inicia tu Plan Familiar',
    'Sign up for iHeart All Access Annual Plan': 'Regístrate para el plan anual de iHeart All Access',
    View: 'Ver',
    'Offer Terms': 'Términos de la oferta',
    'Email Address': 'Correo electrónico',
    'Enter email address': 'Ingresa correo electrónico',
    'Card Details': 'Detalles de tarjeta',
    'Zip / Postal Code': 'Código postal',
    'Enter Here': 'Ingresa aquí',
    'Yes, please automatically renew my subscription annually (subject to {termsLink})': 'Sí, por favor renueva mi suscripción anual automáticamente (sujeto a {termsLink})',
    'terms & conditions': 'términos y condiciones',
    'Charges will appear on your credit card statement as "IHEART BILLING"': 'Los cargos en tu tarjeta de crédito aparecerán como "IHEART BILLING"',
    'You can cancel at any time by going to "Settings" and following the cancellation instructions. By clicking "Subscribe" below, you agree to the conditions above and the iHeart ': 'Puedes cancelar en cualquier momento ingresando a "Configuración" y siguiendo las instrucciones de cancelación. Al dar clic en "Suscribir" abajo, estás aceptando las condiciones de iHeart y las arriba indicadas.',
    Processing: 'Procesando',
    'Enter your email address below to log in by email or reset your password': 'Ingresa tu correo electrónico abajo para iniciar sesión o restablecer tu contraseña.',
    email: 'Correo electrónico',
    Send: 'Enviar',
    'Don’t use common passwords, like “Password123!”': 'No uses contraseñas comunes como "Password123"',
    'Your password reset link is invalid or expired. Please try': 'La liga para restablecer la contraseña es inválida o ha expirado. Por favor intenta',
    'resetting your password': 'restablecer tu contraseña',
    'again.': 'otra vez.',
    'Create a New Password': 'Crear una nueva contraseña',
    'Your password reset link has expired': 'La liga para restablecer la contraseña ha expirado',
    'An unknown error occured. Please try again later': 'Ocurrió un error inesperado. Por favor intenta más tarde',
    'Your password has been updated': 'Tu contraseña ha sido actualizada',
    'Confirm New Password': 'Confirmar nueva contraseña',
    Create: 'Crear',
    'Custom radio stations based off your favorite artists and bands.': 'Estaciones de radio personalizadas en base a tus artistas y bandas favoritos.',
    'View More Artists': 'Ver más artistas',
    'Bring iHeart with you anywhere': 'Lleva iHeart contigo a cualquier lugar',
    'The free iHeart app is available on over 200 platforms, including Web, iOS, Android, Alexa, automobiles and more.': 'La aplicación gratuita iHeart está disponible en más de 200 plataformas, incluyendo Web, iOS, Android, Alexa, automóviles y mucho más.',
    'Download on the': 'Descargar en el',
    'Download on': 'Descargar en',
    'View All Apps': 'Ver todas las apps',
    'Upcoming Events': 'Próximos Eventos',
    'Listen to Your Favorite Music, Podcasts, and Radio Stations for Free! – iHeart': 'Escucha tu música favorita, podcasts y estaciones de radio gratis! - iHeart',
    'iHeart. All your favorite music, podcasts, and radio stations available for free. Listen to thousands of live radio stations or create your own artist stations and playlists. Get the latest music and trending news, from your favorite artists and bands.': 'iHeart. Toda tu música favorita, estaciones de radio y podcasts de forma gratuita.. Escucha miles de estaciones o crea tus estaciones de artistas y playlists. Recibe las noticias de tus artistas y bandas favoritas. ',
    'Get Started Free': 'Empieza gratis',
    'All your favorite music, radio and podcasts, all free.': 'Toda tu música favorita, radio y podcasts, todo gratis.',
    'Your browser does not support video playback': 'Tu navegador no soporta la reproducción de video',
    'The best live stations from around the country, any time, any place.': 'Las mejores estaciones en vivo de todo el país, en cualquier momento y en cualquier lugar.',
    'View More Live Stations': 'Ver más estaciones en vivo',
    'New on iHeart': 'Nuevo en iHeart',
    'Latest Music News': 'Últimas noticias de la música',
    'View More Music News': 'Ver más noticias de música',
    'Popular and exclusive podcasts in a variety of genres and topics.': 'Podcasts populares y exclusivos en una variedad de géneros y temas',
    'View More Podcasts': 'Ver más Podcasts',
    '{count} song{plural}': '{count}canción{plural}',
    'Browse Artists': 'Explora Artistas',
    'Need to find a great new album?': '¿Necesitas encontrar un nuevo álbum?',
    'Any albums you save will show up here': 'Los álbumes que salves aparecerán aquí',
    'Need to find an artist you love?': '¿Necesitas encontrar un artista que te encanta?',
    'Any artists you follow will show up here': 'Los artistas que sigas aparecerán aquí',
    'Access your music library and create unlimited playlists with iHeart All Access': 'Accede a tu Colección de música y crea playlists ilimitados con iHeart All Access.',
    'song{pluralSongs}': 'canción{pluralSongs}',
    by: 'por',
    'Browse Playlists': 'Explora las Playlists',
    'Need a playlist to fit your mood?': '¿Necesitas una playlist para tu estado de ánimo?',
    'Any playlists you follow will show up here': 'Las playlists que sigas aparecerán aquí',
    'Browse Podcasts': 'Explora los Podcasts',
    'Need a great new podcast to start?': '¿Necesitas un podcast nuevo para empezar?',
    'Any podcasts you follow will show up here': 'Los podcasts que sigas aparecerán aquí',
    'Any radio, music, or podcasts you listen to will show up here': 'Cualquier estación, música o podcast que escuches aparecerá aquí',
    'Browse Stations For You': 'Explora estaciones Para Ti',
    'Any podcasts you listen to will show up here': 'Cualquier podcast que escuches aparecerá aquí',
    'Need help finding something new?': '¿Necesitas ayuda para encontrar algo nuevo?',
    'Remove station': 'Eliminar estación',
    '{name} removed from Your Library': '{name} se ha eliminado de Tu Colección',
    'Followed Stations': 'Estaciones seguidas',
    'Browse Live Radio': 'Explora Radio en Vivo',
    'Browse Artist Radio': 'Explora Radio de Artistas',
    'Need help finding stations to save?': '¿Necesitas ayuda para encontrar estaciones que quieras guardar?',
    'Your followed live and artist radio stations will show up here': 'Las estaciones en vivo y de artista que sigues, aparecerán aquí.',
    '{albumName} removed from Your Library': '{albumName}se ha eliminado de Tu Colección',
    Remove: 'Eliminar',
    '{artistName} removed from Your Library': '{artistName} se ha eliminado de Tu Colección',
    'ARTIST IN YOUR LIBRARY': 'ARTISTA EN TU COLECCIÓN',
    'Add to playlist': 'Añadir a playlist',
    'Play Now': 'Reproducir ahora',
    '{songTitle} removed from Your Library': '{songTitle} se ha eliminado de Tu Colección',
    'Need to find a new song?': '¿Necesitas encontrar una nueva canción?',
    'Any song you save will show up here': 'Cualquier canción que guardes aparecerá aquí',
    'Playlist Radio': 'Radio de Playlist',
    'View All': 'Ver todo',
    Copied: 'Copiado',
    'Read more on iHeartRadio': 'Lee más en iHeartRadio',
    'Link Copied!': '¡Liga copiada!',
    'Copy Link': 'Copiar liga',
    'Code Copied!': '¡Código copiado!',
    Embed: 'Incrustar',
    'Follow on iHeartRadio': 'Seguir en iHeartRadio',
    'Playback not supported by device or browser.': 'No se puede reproducir en este dispositivo o navegador.',
    'Method {method} not supported.': 'Method {method} not supported.',
    'View iHeart.com': 'Ver iHeart.com',
    "Oops, that wasn't supposed to happen.": 'Uy, eso no debió haber pasado.',
    "Oops, this feature isn't supported yet.": 'Uy, esta función aún no está disponible',
    'Oops! We had problems playing this stream.': '¡Uy! Tuvimos problemas para tocar eso.',
    'This station is not currently available in your country.': 'Esta estación no está disponible en tu país.',
    Retry: 'Reintentar',
    'View Troubleshooting': 'Ver solución de problemas',
    hello: 'hola',
    'my friend': 'mi amigo',
    'Share this live station': 'Comparte esta estación',
    'Create your own on iHeart': 'Crea tu propia en iHeart',
    "We're sorry! This playlist is not available in your country.": '¡Lo sentimos! Esta playlist no está disponible en tu país.',
    'Playlist Not Available': 'Playlist no disponible',
    'This playlist is unsupported in your current country.': 'Este playlist no está disponible en el país actual.',
    'Playback Unsupported In Current Country': 'Reproducción no disponible en el país actual.',
    'Featured artists in this playlist': 'Artistas destacados en esta playlist',
    'View Playlist': 'Ver playlist',
    'Share this episode': 'Comparte este episodio',
    'Listen to more episodes': 'Escucha más episodios',
    'This show has no episodes available yet.': 'Este podcast no tiene episodios disponibles todavía.',
    'Share this podcast': 'Compartir podcast'
  },
  fr: {
    Facebook: 'Facebook',
    Google: 'Google',
    'Region Not Supported': 'Région non prise en charge',
    'Do not sell or share my personal information': 'Ne pas vendre mon information personnelle',
    'Log In': 'Connexion',
    'Sorry, an error occurred. Please try again later.': "Désolés, une erreur s'est produite. Veuillez réessayer plus tard.",
    "Don't have an account?": "Vous n'avez pas de compte?",
    'Sign up': 'Abonnez-vous',
    'Forgot Password?': 'Mot de passe oublié?',
    Email: 'Adresse courriel',
    Password: 'Mot de passe',
    'Sorry for the inconvenience, but we do not allow account sign-up for your current location.': "Désolés de l'inconvénient, mais nous ne permettons pas la création de comptes pour votre emplacement. ",
    'Visit our Help Center': "Consulter notre Centre d'aide",
    'Return to Log In': 'Revenir à la connexion',
    Male: 'Homme',
    Female: 'Femme',
    'Prefer not to say': 'Je préfère ne pas répondre',
    Other: 'Autre',
    '8 to 32 characters': '8 à 32 caractères',
    'an upper case and lower case letter': 'une lettre majuscule et une minuscule',
    'a number (0-9)': 'un chiffre (0-9)',
    Error: 'Erreur',
    Success: 'Succès',
    'Password must have eight to thiry two characters': 'Le mot de passe doit comporter de huit à trente-deux caractères',
    "Password doesn't have eight to thirty two characters": 'Le mot de passe ne comporte pas de huit à trente-deux caractères',
    'Password has eight to thirty two characters': 'Le mot de passe comporte de huit à trente-deux caractères',
    'Password must have an uppercase and lower case letter': 'Le mot de passe doit comprendre une lettre majuscule et une minuscule',
    "Password doesn't have an upper case and lower case letter": 'Le mot de passe ne comprend pas une lettre majuscule et une minuscule',
    'Password has an upper case and lower case letter': 'Le mot de passe comprend une lettre majuscule et une minuscule',
    'Password must have a number between zero and nine': 'Le mot de passe doit comprendre un chiffre entre zéro et neuf',
    "Password doesn't have a number between zero and nine": 'Le mot de passe ne comprend pas un chiffre entre zéro et neuf',
    'password has a number between zero and nine': 'Le mot de passe comprend un chiffre entre zéro et neuf',
    'Please fill out all form fields': 'Veuillez remplir tous les champs du formulaire ',
    "Password shouldn't have common words or passwords": 'Le mot de passe ne devrait pas être un mot ou un mot de passe commun',
    'Password can only have English letters, numbers, spaces, and special characters from: . ! ? - _ , ; : /  ( ) [ ] ~ @ # $ % ^ & * + = \\': 'Le mot de passe ne peut contenir que des lettres latines, des chiffres, des espaces et des caractères spéciaux de la liste suivante : . ! ? - _ , ; : / ( ) [ ] ~ @ # $ % ^ & * + = \\',
    'Invalid Email Address': 'Adresse courriel invalide',
    'Birth Year': 'Année de naissance',
    'Terms of Service': "Conditions d'utilisation",
    'Privacy Policy': 'Politique de confidentialité',
    'Sign Up': 'Abonnez-vous',
    'Postal Code': 'Code postal',
    'Invalid Postal Code': 'Code postal invalide',
    'Invalid ZIP Code': 'Code postal invalide',
    'Have an account?': 'Vous avez déjà un compte?',
    'Login unsuccessful. Please try again later.': 'Échec de la connexion. Veuillez réessayer.',
    'Update successful.': 'Mise à jour réussie.',
    'Your account has been linked to {provider}.': 'Votre compte a été lié à {provider}.',
    'This {accountType} account is already linked to an iHeartRadio account': 'Ce compte {accountType} est déjà lié à un compte iHeartRadio',
    '{accountType} account linking failed. Please try again later.': 'Connexion du compte {accountType} a échoué. Veuillez réessayer plus tard.',
    'Your account has been unlinked from {provider}.': 'Votre compte a été délié de {provider}.',
    '{accountType} account unlinking failed. Please try again later.': 'Déconnexion du compte {accountType} a échoué. Veuillez réessayer plus tard.',
    'Login unsuccessful. Window closed before completion. Please try again.': "Échec de la connexion. La fenêtre a été fermée avant la fin de l'opération. Veuillez réessayer.",
    'Do You Have a Popup Blocker?': 'Avez-vous un bloqueur de pop-ups?',
    "To log in with your Facebook or Google account, ensure you've allowed popups from www.iheart.com, refresh, and then try again.": 'Pour vous connecter avec votre compte Facebook ou Google, assurez vous que vous pouvez voir les pop-ups de www.iheart.com, rafraîchissez la page et réessayer.',
    'Feat. {artistNames} and more': 'avec {artistNames} et plus',
    'Add to Playlist': "Ajouter à la liste d'écoute",
    'Episode Info': "Info de l'épisode",
    Lyrics: 'Paroles',
    'Rename your Favorites Radio station now.': 'Renommez votre station de favoris maintenant.',
    'This song is not available to start your station.': "Cette chanson n'est pas disponible pour démarrer votre station.",
    'Play this on web with All Access': 'Jouez ceci sur le web avec Tout Accès',
    'Subscribe today for unlimited access to playlists': "Abonnez-vous aujourd'hui pour un accès illimité aux listes d'écoute",
    'Play the whole album with iHeart All Access.': "Jouez l'album au complet avec Tout Accès iHeartRadio.",
    'Listen History': "Historique d'écoute",
    'Custom Stations Limit Exceeded': 'Limite de stations personnalisées dépassée',
    'Please delete some stations from your account under {listenHistoryLink} before creating new stations.': "Veuillez supprimer des stations de votre compte sous {listenHistoryLink} avant d'en créer de nouvelles.",
    'Sorry, this station has run out of songs to play.': "Désolés, cette station n'a plus de chansons à jouer.",
    Oops: 'Oups',
    "We don't have enough info to create a custom station right now. We'll let our DJs know.": "Nous n'avons pas assez d'information pour créer une station personnalisée en ce moment. Nous informerons nos animateurs.",
    'Replay failed': 'Échec de la répétition',
    'An unknown error occurred. Please try again later.': "Une erreur inattendue s'est produite. Veuillez réessayer plus tard.",
    'Update Account': 'Mettre le compte à jour',
    'Please enter a valid email': 'Veuillez entrer une adresse courriel valide',
    'New Email': 'Nouvelle adresse courriel',
    'Please enter the same email again': 'Veuillez entrer le même courriel de nouveau',
    'Confirm New Email': 'Confirmer la nouvelle adresse courriel',
    'Password must be 6 - 32 characters': 'Le mot de passe doit avoir entre 6 et 32 caractères',
    'New Password (6-32 Characters)': 'Nouveau mot de passe (6-32 caractères)',
    Confirm: 'Confirmez',
    Cancel: 'Annuler',
    'Cannot add duplicate tracks to a playlist': "Les chansons ne peuvent être ajoutées en double dans une liste d'écoute",
    'Added {contentType} to {playlistName}': '{contentType} ajouté à {playlistName}',
    'Added song to {playlistName}': 'Chanson ajoutée à {playlistName}',
    Ok: 'Ok',
    'Success!': 'Succès!',
    'Your email and password have been added to your account.': 'Votre courriel et votre mot de passe ont été ajoutés à votre compte.',
    'Your email has been saved.': 'Votre courriel a été sauvegardé.',
    'Your contact information has been saved.': 'Vos coordonnées ont été sauvegardées.',
    'Try Again': 'Ré-essayer',
    'For assistance, please visit our {helplink}.': "Pour de l'aide, allez à {helplink}.",
    'Help Link': "Lien pour de l'aide",
    'Oops!': 'Oups!',
    'Get Started': 'Débuter',
    'You can now enjoy the full benefits of iHeart All Access for Family.': 'Vous avez maintenant accès à tous les bénéfices de Tout Accès iHeartRadio pour la famille.',
    'Welcome to the Family!': 'Bienvenue à la famille!',
    'Your password has been saved.': 'Votre mot de passe a été sauvegardé.',
    'Station Not Available': 'Station non disponible',
    "We're sorry! This station is not available in your country.": "Désolés, cette station n'est pas disponible dans votre pays!",
    'Updated {updateDateAndTime}': 'Mis à jour le {updateDateAndTime}',
    'Please complete the CAPTCHA': 'Veuillez compléter le CAPTCHA',
    'Check Your Email': 'Vérifier vos courriels',
    'Forgot Password': 'Mot de passe oublié',
    'Enter your email address below to log in by email or reset your password.': 'Entrez votre adresse courriel ci-dessous pour vous connecter par courriel ou pour réinitialiser votre mot de passe.',
    'Enter your email address below to reset your password.': 'Entrez votre adresse courriel ci-dessous pour réinitialiser votre mot de passe.',
    "If an account exists, you'll receive a password reset link shortly. Check your spam folder if it takes more than a minute or two.": "Si un compte existe, vous recevrez un lien de réinitialisation du mot de passe. Vérifiez votre dossier de pourriel si vous ne le recevez pas d'ici une ou deux minutes.",
    Okay: "D'accord",
    'Back to login': 'Retour à la page de connection',
    Submit: 'Soumettre',
    'Or, {backToLoginLink}': 'Ou, {backToLoginLink}',
    Gender: 'Sexe',
    'Music and radio on demand': 'Musique et radio sur demande',
    'Please log in first.': "Veuillez vous connecter d'abord.",
    Login: 'Connexion',
    'To listen to thousands of live radio and artist stations, create a free account.': "Créez un compte gratuit pour écouter des milliers de stations de radio et d'artistes en direct.",
    'To listen to thousands of live radio stations, create a free account.': 'Créez un compte gratuit pour écouter des milliers de stations de radio en direct.',
    'Connect with your favorites': 'Connectez-vous à la musique que vous aimez',
    'To easily find your favorite and recently listened to stations, create a free account.': 'Pour facilement trouver vos stations préférées et celles que vous avez écoutées récemment, créez un compte gratuit.',
    'Access your stations': 'Accédez à vos stations',
    'To view recently listened to stations and songs, create a free account.': 'Pour voir les stations et les chansons que vous avez écoutées récemment, créez un compte gratuit.',
    "See what you've been listening to": 'Voyez ce que vous avez écouté',
    'To access your favorites anytime, anywhere, create a free account.': "Pour avoir accès à vos favoris n'importe quand, n'importe où, créez un compte gratuit.",
    'Save stations you love': 'Sauvegardez les stations que vous aimez',
    'To hear more music you like, create a free account.': 'Pour écouter encore plus de musique que vous aimez, créez un compte gratuit.',
    'Personalize your listening': 'Personnalisez votre écoute',
    'To listen to this artist and more, without interruption, create your free account.': 'Pour écouter cet artiste et plus, sans interruption, créez votre compte gratuit.',
    'Listen to {stationName}': 'Écoutez {stationName}',
    'Listen to Artist Stations': "Écoutez des stations d'artistes",
    'To listen to music based on this song, create your free account.': 'Pour écouter de la musique choisie à partir de cette chanson, créez un compte gratuit.',
    'Listen to songs like {stationName}': 'Écoutez des chansons comme {stationName}',
    'Listen to songs like this': 'Écoutez des chansons comme celle-ci',
    'To listen to this podcast and more, create your free account.': 'Pour écouter ce balado et plus, créez ce compte gratuit.',
    'Listen to Podcasts': 'Écoutez des balados',
    'To hear all your favorite songs and artists on your own personal station, create a free account.': 'Pour entendre toutes vos chansons et vos artistes préférés sur votre propre station, créez un compte gratuit.',
    'Listen to {stationName}!': 'Écoutez {stationName}!',
    'Listen to Your Favorites Radio!': 'Écoutez votre radio de chansons préférées!',
    'To listen, create a free account.': 'Créez un compte gratuit pour commencer à écouter de la musique.',
    'Listen to Favorites Radio!': 'Écoutez votre radio de chansons préférées!',
    'All your favorite music. All your favorite stations. All free.': 'Toute votre musique préférée. Toutes vos stations préférées. Tout gratuit.',
    'Create Your iHeart Account': 'Créez votre compte iHeartRadio',
    'Please create a free account.': 'Veuillez créer un compte gratuit.',
    'Register first': "Abonnez-vous d'abord",
    'Create a FREE account to add songs and albums to your playlist': "Créez un compte GRATUIT pour ajouter des chansons et des albums à votre liste d'écoute",
    'Welcome to the All Access FREE Preview Weekend. Create your FREE account and listen to millions of songs, live radio, playlists, and podcasts – enjoy All Access, FREE this weekend only.': "Bienvenue à notre fin de semaine d'essai de Tout Accès gratuit. Créez votre compte GRATUIT et écoutez des millions de chansons, de la radio en direct, des listes d'écoute et des balados - tout cela, gratuitement, seulement pour cette fin de semaine.",
    'Your Halloween treat is here': "Votre gâterie d'Halloween est arrivée",
    "To join the Family Plan you've been invited to, please create a free account": 'Pour adhérer au Plan famille auquel vous avez été invité, veuillez créer un compte gratuit',
    'Create a free account to join your Family Plan': 'Créez un compte gratuit pour adhérer à votre Plan famille',
    'Please correct all password errors': 'Veuillez corriger toutes les erreurs dans le mot de passe',
    'Sorry, you are not eligible to register for iHeart': "Désolés, vous n'êtes pas éligible pour un compte iHeartRadio",
    'Postal Code (optional)': 'Code postal (optionnel)',
    'Zip Code': 'Code postal',
    'Please enter a valid birth year': 'Entrez une année de naissance valide',
    'Receive email updates on stations I listen to and more from iHeart.': "Recevoir des nouvelles par courriel de iHeartRadio à propos des stations que j'écoute et plus encore.",
    'By creating an account, you also agree to our {termsOfServiceLink} and {privacyPolicyLink}.': 'En créant un compte, vous acceptez également nos {termsOfServiceLink} et {privacyPolicyLink}.',
    'iHeartRadio is provided by iHeartMedia and Bell Media. This app collects info to locate (e.g., IP address), identify (e.g., web activities), and profile (e.g., iHeartRadio use) you to enhance the site, analyze usage, and enable marketing (e.g., contextual advertising). By registering, you consent to the processing of your personal information for these purposes and agree to the {termsOfService}, {iHeartPolicy} and {bellPolicy}.': "iHeartRadio est fourni par iHeartMedia et Bell Média. Cette application recueille des informations pour vous localiser (par exemple, votre adresse IP), vous identifier (par exemple, vos activités sur le web) et établir votre profil (par exemple, votre utilisation de iHeartRadio) afin d'améliorer le site, d'analyser son utilisation et de permettre le marketing (par exemple, la publicité contextuelle). En vous inscrivant, vous consentez au traitement de vos informations personnelles à ces fins et acceptez les {termsOfService}, la {iHeartPolicy} et la {bellPolicy}.",
    'Or Connect With': 'Ou connectez-vous avec',
    'Login with Facebook': 'Se connecter avec Facebook',
    'Login with Google': 'Se connecter avec Google',
    'Added By iHeart': 'Ajouté par iHeartRadio',
    'Suggestions based on the songs you added.': 'Des suggestions basées sur les chansons que vous avez ajoutées.',
    'You have All Access': 'Vous avez Tout Accès',
    'Upgrade to All Access': 'Passez à Tout Accès',
    'Want to keep enjoying these perks after the weekend?': 'Vous voulez continuer de profiter de ces avantages après cette fin de semaine?',
    'Listen offline, unlimited skips, save and play songs from the radio.': 'Écouter hors ligne, des sauts illimités, sauvegarder et jouer des chansons de la radio.',
    'Learn More': 'Apprenez-en plus',
    'Start 30 Day Free Trial': "Commencer l'essai gratuit de 30 jours",
    'We use cookies.': 'Nous utilisons des témoins (cookies).',
    'This website uses cookies for the purposes of enhancing the site, analyzing usage, and marketing, including contextual advertising. If you click “Accept All Cookies,” you also consent to the use of cookies and processing of your personal information by iHeartMedia and Bell Media to locate (e.g., IP address), identify (e.g., web activities), and profile (e.g., iHeartRadio use) you for the purpose of targeted advertising. {termsOfService}, {iHeartPolicy} and {bellPolicy}.': "Ce site Web utilise des témoins (cookies) dans le but d'améliorer le site, d'analyser l'utilisation et de faire de la publicité, y compris de la publicité contextuelle. Si vous cliquez sur \"Accepter tous les témoins\", vous consentez également à l'utilisation de témoins et au traitement de vos informations personnelles par iHeartMedia et Bell Media pour vous localiser (par exemple, adresse IP), vous identifier (par exemple, activités sur le Web) et vous profiler (par exemple, utilisation de iHeartRadio) à des fins de publicité ciblée. {termsOfService}, {iHeartPolicy} et {bellPolicy}.",
    'Accept All Cookies': 'Accepter tous les témoins',
    'Cookies and Advertising': 'Témoins et publicité',
    'No Thanks': 'Non merci',
    Agree: 'Accepter',
    'You will lose unlimited skips, replay, and access to your playlist': "Vous perdrez vos sauts illimités, vos ré-écoutes et l'accès à votre liste d'écoute",
    'You will lose offline listening, unlimited playlists, and replay': "Vous perdrez l'écoute hors ligne, les listes d'écoute illimitées et la possibilité de rejouer des chansons",
    'Are you sure you want to cancel?': 'Êtes-vous sûr de vouloir annuler?',
    'Your subscription ends on {date}': 'Your abonnement prend fin le {date}',
    'Your playlists will be waiting for you should you decide to subscribe again.': "Vos listes d'écoute seront encore là si vous décidez de vous abonner à nouveau.",
    'Keep Current Plan': 'Garder le plan actuel',
    'Got It': 'Compris',
    'Cancel Plan': 'Annuler le plan',
    'Trending - Just Now': "En vedette - à l'instant",
    'Help Center': "Centre d'aide",
    'There was an error updating your email': 'Il y a eu une erreur lors de la mise à jour de votre adresse courriel',
    'Update Contact Information': 'Mettre à jour les coordonnées',
    'Please enter your first name': 'Veuillez entrer votre prénom',
    'Add your first name': 'Ajouter votre prénom',
    'First Name': 'Prénom',
    'Please enter your last name': 'Veuillez entrer votre nom de famille',
    'Add your last name': 'Ajouter votre nom de famille',
    'Last Name': 'Nom de famille',
    'Please enter a valid phone number': 'Veuillez entrer un numéro de téléphone valide',
    'Phone Number': 'Numéro de téléphone',
    'Please enter a valid postal code': 'Veuillez entrer un code postal valide',
    'Visit Our {helpCenterLink}': 'Consultez notre {helpCenterLink}',
    'This email address is already associated with an iHeart account': 'Cette adresse courriel est déjà lié à un compte iHeart',
    'Emails must match': 'Les courriels doivent concorder',
    'Change Email': "Changer l'adresse courriel",
    'Please enter the same value again': 'Veuillez entrer la même valeur',
    'Please enter your password': 'Veuillez entrer votre mot de passe',
    'Or, Visit Our {helpCenterLink}': 'Ou, visitez notre {helpCenterLink}',
    "Password doesn't match our records": 'Le mot de passe ne correspond pas à celui dans nos dossiers',
    "Uh-oh passwords don't match": 'Oups, les mots de passe ne correspondent pas',
    'Your new password cannot be the same as your current password': 'Votre nouveau mot de passe ne peut pas être le même que votre mot de passe actuel',
    'Password can only have English letters, numbers, spaces, and special characters from: dot, exclamation point, question mark, dash, underscore, comma, semicolon, colon, forward slash, backslash, open parenthesis, close parenthesis, open bracket, close bracket, tilde, at sign, number sign, dollar sign, percent sign, caret, ampersand, asterisk, plus sign, equal sign, apostrophe, quotation mark, grave accent, vertical bar, open curly bracket, close curly bracket, less than sign, and greater than sign': 'Le mot de passe ne peut contenir que des lettres latines, des chiffres, des espaces et des caractères spéciaux de la liste suivante : point, point d\'exclamation, point d\'interrogation, tiret, trait de soulignement, virgule, point-virgule, deux-points, barre oblique avant, barre oblique arrière, parenthèse ouverte, parenthèse fermée, crochet ouvert, crochet fermé, dièse, arobase, signe de dollar, signe de pourcentage, caret, esperluette, astérisque, signe "plus", signe "égal", apostrophe, guillemet, accent grave, barre verticale, accolade ouverte, accolade fermée, signe "moins que" et signe "plus que"',
    'Update Password': 'Mise à jour du mot de passe',
    'Old Password': 'Ancien mot de passe ',
    'New Password': 'Nouveau mot de passe',
    'Confirm Password': 'Confirmez le mot de passe',
    Update: 'Mise à jour',
    'Are you sure you want to continue?': 'Êtes-vous sûr de vouloir continuer?',
    'Signed Out': 'Déconnexion',
    'You have been signed out. Please log back in.': 'Vous avez été déconnecté. Veuillez vous reconnecter.',
    '© {fourDigitYear} iHeartMedia, Inc.': '© {fourDigitYear} iHeartMedia, Inc.',
    Help: 'Aide',
    'Terms of Use': "Conditions d'utilisation",
    'Playlist "{playlistName}" created': 'Liste d\'écoute "{playlistName}" créée',
    'Create unlimited playlists with iHeart All Access.': "Créez des listes d'écoute illimitées avec Tout Accès iHeart.",
    'There was an error creating your playlist': "Il y a eu une erreur lors de la création de votre liste d'écoute",
    'Device Limit': "Limite d'appareils",
    "This stream stopped because you're listening on another device.": "Cette diffusion s'est arrêtée parce que vous écoutez aussi sur un autre appareil.",
    'Listen on this device': 'Écouter sur cet appareil',
    'Got it': "D'accord",
    'Album saved to Your Library': 'Album sauvegardé à votre librairie',
    'Save Album': "Sauvegarder l'album",
    'Saved {name} to Your Library': ' {name} sauvegardé dans votre librairie',
    'Removed {name} from Your Library': ' {name} effacé de votre librairie',
    'Follow Artist': "Suivre l'artiste",
    'Remove Artist': 'Supprimer cet artiste',
    'Not for Me': 'Pas pour moi',
    Delete: 'Supprimer',
    'Follow station': 'Suivre la station',
    'Unfollow station': 'Ne plus suivre la station',
    Connect: 'Se connecter',
    'Call ': 'Appeler',
    'Text ': 'Texter',
    'Edit Playlist': "Éditer la liste d'écoute",
    'Delete Playlist': "Supprimer la liste d'écoute",
    'Unfollow Playlist': "Ne plus suivre cette liste d'écoute",
    'Follow Playlist': "Suivre cette liste d'écoute",
    'Followed {title}': ' {title} suivi',
    'Unfollowed {title}': " {title} n'est plus suivi",
    'Follow Podcast': 'Suivre ce balado',
    'Unfollow Podcast': 'Ne plus suivre ce balado',
    'No added songs yet': 'Aucune chanson sauvegardée',
    'Tap ': 'Appuyez',
    Save: 'Sauvegarder',
    ' on the player to add a song to My Playlist': "sur le lecteur pour ajouter une chanson à votre liste d'écoute",
    'Click on the': 'Cliquez sur le',
    'button next to a song': "bouton à côté d'une chanson",
    'and select Add to Playlist.': "et choisissez Ajouter à la liste d'écoute.",
    'Find Music': 'Trouver de la musique',
    'Uh oh, we can’t find the page you’re looking for.': 'Oups, nous ne pouvons trouver la page que vous cherchez.',
    'How about this page instead: ': 'Peut-être cette page à la place :',
    'Read more': 'Lire plus',
    Explicit: 'Explicite',
    E: 'E',
    'Invite sent to {email}': 'Invitation envoyée à {email}',
    'Add family member...': 'Ajouter un membre de famille...',
    'email@example.com': 'courriel@exemple.com',
    'Send Invite': 'Envoyer des invitations',
    You: 'Vous',
    Active: 'Actif',
    '{email} has been removed.': '{email} a été enlevé.',
    'Remove Member': 'Retirer un membre',
    'Cancel Invite': "Annuler l'invitation",
    'Resend Invite': "Renvoyer l'invitation",
    'Invite was sent on ': 'Invitation envoyée',
    'Active ✓': 'Actif ✓',
    'Pending...': 'En attente...',
    'Invitees must be 14 years or older': 'Les invités doivent avoir 14 ans ou plus',
    Owner: 'Propriétaire',
    Following: 'Vous suivez',
    Follow: 'Suivre',
    'Music, radio and podcasts, all free. Listen online or download the iHeart App.': "Musique, radio et balados, tout cela gratuitement. Écoutez-nous en ligne ou téléchargez l'appli iHeart.",
    'Connect with Facebook': 'Se connecter avec Facebook',
    'Connect with Twitter': 'Se connecter avec Twitter',
    'iHeart Instagram': 'Instagram iHeart',
    'iHeart Youtube': 'Youtube iHeart',
    Explore: 'Explorer',
    iHeart: 'iHeart',
    'Live Radio': 'Radio en direct',
    Podcasts: 'Balados',
    'On Demand Podcasts': 'Balados sur demande',
    'Artist Radio': 'Radio artiste',
    Playlists: "Listes d'écoute",
    Genres: 'Genres',
    'Browse by Genre': 'Chercher par genre',
    Exclusives: 'Exclusifs',
    News: 'Nouvelles',
    'iHeart News': 'Nouvelles iHeart',
    Features: 'En vedette',
    'Featured Exclusives on iHeart': 'Exclusifs en vedette sur iHeart',
    Events: 'Événements',
    'iHeart Events': 'Événements iHeart',
    Contests: 'Concours',
    'iHeart Contests': 'Concours iHeart',
    Photos: 'Photos',
    'iHeart Photos': 'Photos iHeart',
    Information: 'Information',
    About: 'À propos',
    'About iHeart': 'À propos de iHeart',
    Advertise: 'Publicité',
    'Advertise on iHeart': 'Faire de la publicité sur iHeart',
    Blog: 'Blogue',
    'iHeart Blog': 'Blogue iHeart',
    'Brand Guidelines': 'Directives de marque',
    'iHeart Logo and Guidelines': 'Logo et directives iHeart',
    'Contest Guidelines': 'Directives de concours',
    'iHeart Contest Rules': 'Règles des concours iHeart',
    'Subscription Offers': "Offres d'abonnement",
    Jobs: 'Emplois',
    'iHeart Jobs': 'Emplois iHeart',
    'Get the App': "Télécharger l'appli",
    Automotive: 'Auto',
    'iHeart in your house': 'iHeart chez vous',
    Home: 'Page principale',
    'iHeart in your car': 'iHeart dans votre auto',
    Mobile: 'Mobile',
    'iHeart on your phone or tablet': 'iHeart sur votre téléphone ou votre tablette',
    Wearables: 'Portables',
    'iHeart wearables': 'Portables iHeart',
    'Your Library': 'Ma librairie',
    'Saved {name} to {savedStationsLink}': ' {name} sauvegardé à {savedStationsLink}',
    'Removed {name} from {savedStationsLink}': ' {name} retiré de {savedStationsLink}',
    'Followed {podcastName}': '{podcastName} suivi',
    'Unfollowed {podcastName}': "{podcastName} n'est plus suivi",
    "We won't play this song again on this station.": 'On ne jouera plus cette chanson sur cette radio.',
    'Thanks for your feedback.': 'Merci pour vos réactions',
    'favorite songs': 'chansons préférées',
    "We'll play more like it. Check out your {favoriteSongsLink} to see everything you've liked.": 'On jouera plus de chansons comme ça. Allez à {favoriteSongsLink} pour voir ce que vous avez aimé.',
    "We'll let our DJs know you've heard enough of this song.": 'Nous laisserons nos animateurs savoir que vous avez assez entendu cette chanson.',
    "Check out your {favoriteSongsLink} to see everything you've liked.": 'Allez à votre {favoriteSongsLink} pour voir tout ce que vous avez aimé.',
    "Glad you like it. We'll let our DJs know.": 'Contents que vous aimiez. Nous en informerons nos animateurs.',
    "OK, we'll adjust your music mix.": "D'accord, nous ajusterons votre mix de musique.",
    'Favorites Radio': 'Radio de chansons préférées',
    'Great, we’ll add this song to your own {favoritesRadioLink}!': 'Super, nous ajouterons cette chanson à votre propre {favoritesRadioLink}!',
    'Great, we’ll play you more songs like this': 'Super, on vous jouera plus de chansons comme celle-là',
    'We appreciate you letting us know.': "Merci de nous l'avoir fait savoir.",
    'Nice! You’ll hear this song more often.': 'Super! Vous entendrez cette chanson plus souvent.',
    "You've reached your skip limit.": 'Vous avez atteint votre limite de sauts.',
    "We're sorry, you can't skip any more songs right now. You can still thumb down a song. Want to know more? Visit {helpLink}": "Désolés, vous ne pouvez sauter d'autres chansons pour le moment. Vous pouvez encore donner un pouce vers le bas. Vous voulez en savoir plus? Visitez {helpLink}",
    'Report this issue.': 'Signaler ce problème.',
    "There's an error with episodes for this show. {reportLink}": 'Une erreur est survenue avec les épisodes de cette émission. {reportLink}',
    "There's an error with this content. {reportLink}": 'Une erreur est survenue avec ce contenu. {reportLink}',
    'Playlist followed and saved to {link}': "Liste d'écoute suivie sauvegardée à {link}",
    'Playlist unfollowed and removed from {link}': "Liste d'écoute non suivie et retirée de {link}",
    'Click here': 'Cliquez ici',
    'Having trouble logging into iHeart with your Facebook or Google account? {clickHereLink} for help.': "De la difficulté à vous connecter à iHeart par votre compte Facebook ou Google? {clickHereLink} pour de l'aide.",
    More: 'Plus',
    Subscribe: "S'abonner",
    Upgrade: 'Mise à jour',
    'Upgrade Now': "Améliorer l'expérience maintenant",
    Settings: 'Réglages',
    'Log Out': 'Déconnexion',
    Account: 'Compte',
    'Sponsor Content': 'Contenu commandité',
    'Mark As Unplayed': 'Marquer comme non joué',
    'Mark as Played': 'Marquer comme joué',
    'This station is not currently available': "Cette station n'est pas disponible en ce moment",
    'Please pick a different station or listen to this station using our free iHeart app': 'Choisissez une autre station ou écoutez celle-ci avec notre appli gratuite iHeart',
    'Listen in App': "Écouter avec l'appli",
    "You haven't thumbed up any songs yet.": "Vous n'avez pas encore donné de pouces en l'air.",
    'Favorite Songs': 'Chansons préférées',
    NEW: 'NOUVEAU',
    'Welcome to': 'Bienvenue à',
    'Your Favorites Radio!': 'Votre radio de chansons préférées!',
    'All your favorites and thumbs up in one station. The more you interact with iHeart, the better it gets.': 'Tous ce que vous aimez dans une seule station. Plus vous interagissez avec iHeart, plus la plateforme répond à vos goûts.',
    'Please name your station now:': 'Veuillez maintenant nommer votre station:',
    'On Air': 'En diffusion',
    Share: 'Partager',
    'Go To Album': "Aller à l'album",
    'Go To Artist': "Aller à l'artiste",
    'Go To Station': 'Aller à la station',
    'Unfollow Station': 'Ne plus suivre la station',
    'Follow Station': 'Suivre la station',
    'Go To Your Library': 'Aller à votre librairie',
    'Go To Playlist': "Aller à la liste d'écoute",
    'Share Episode': "Partager l'épisode",
    'Share from': 'Partager de',
    Transcript: 'Transcription',
    Pause: 'Pause',
    Stop: 'Arrêt',
    Play: 'Jouer',
    'Play Newest': 'Jouer les plus récents épisodes',
    'Create unlimited playlists. Try iHeart All Access.': "Créez des listes d'écoute illimitées. Essayez Tout Accès iHeart.",
    'My Music': 'Ma musique',
    'Theme Radio': 'Radio thématique',
    Playlist: "Liste d'écoute",
    'Playlist by': "Liste d'écoute par",
    Podcast: 'Balado',
    'Thanks for listening!': 'Merci de nous écouter!',
    Rename: 'Renommer',
    Edit: 'Éditer',
    'Add to Another Playlist': 'Ajouter à une autre playlist',
    Shuffle: 'Lecture aléatoire',
    'Added By You': 'Ajoutée par vous',
    'Recently Played': 'Récemment écoutées',
    'You like {station} on Facebook': 'Vous aimez {station} sur Facebook',
    'You listened to {station}': 'Vous avez écouté {station}',
    'You are near {city}': 'Vous êtes près de {city}',
    'You like {genre}': 'Vous aimez le {genre}',
    'Just For You': 'Juste pour vous',
    'Popular On {iHeart}': 'Populaire sur {iHeart}',
    'Discover how you can save and replay music on your favorite radio stations, or download and listen offline to millions of songs!': 'Découvrez comment sauvegarder et rejouer de la musique de vos stations radio préférées, ou téléchargez et écoutez des millions de chansons hors ligne!',
    'iHeart Plus & All Access - Upgrade to a Free Trial': 'iHeart Plus et Tout Accès - Passer à un essai gratuit',
    'Playlist deleted': "Liste d'écoute supprimée",
    'Select a song to replay': 'Choisissez une chanson à rejouer',
    'Save Playlist': "Sauvegarder la liste d'écoute",
    All: 'Tous',
    'Live Stations': 'Stations en direct',
    Artists: 'Artistes',
    Albums: 'Albums',
    Songs: 'Chansons',
    'artists, stations or podcasts': 'artistes, stations, et balados',
    'stations or podcasts': 'stations et balados',
    podcasts: 'balados',
    search: 'chercher',
    'Album{bulletSeparator}{artistName}': 'Album{bulletSeparator}{artistName}',
    'Top Result': 'Meilleur résultat',
    'Search Results': 'Résultats de recherche',
    'Show More Results': 'Afficher plus de résultats',
    'Search iHeart for artists, stations or podcasts.': 'Cherchez iHeart pour des artistes, stations, et balados.',
    'Search iHeart for stations or podcasts.': 'Cherchez iHeart pour des stations et balados.',
    'Search iHeart for podcasts.': 'Chercher sur iHeart pour des balados.',
    'There are no results for "{searchQuery}"': 'Il n\'y a pas de résultats pour  "{searchQuery}"',
    'Check your spelling or try another search': 'Vérifiez votre orthographe ou essayez une autre recherche',
    'Show all Live Stations': 'Montrer toutes les stations en direct',
    'Show all Artists': 'Montrer tous les artistes',
    'Show all Albums': 'Montrer tous les albums',
    'Show all Songs': 'Montrer toutes les chansons',
    'Show all Playlists': "Montrer toutes les listes d'écoute",
    'Show all Podcasts': 'Montrer tous les balados',
    'Show all {title}': 'Montrer tous {title}',
    'Embed Widget': 'Intégrer le widget',
    Twitter: 'Twitter',
    'See {moreTiles} of {itemCount}': 'Afficher {moreTiles} de {itemCount}',
    'See All': 'Tout afficher',
    'See Less': 'Afficher moins',
    'For You': 'Pour vous',
    'Get The App': "Téléchargez l'appli",
    'Connect with ': 'Connectez-vous à ',
    'on your favorite social network ': 'sur votre réseau social préféré',
    ' Song': 'Chanson',
    'Keep Thumbing!': 'Continuez de nous donner votre opinion!',
    'Thumbing up songs personalizes the stations you listen to and adds them to Your Favorites Radio.': "Donner un pouce en l'air personnalise les stations que vous écoutez et les ajoute à votre radio de chansons préférées.",
    '1 song': '1 chanson',
    'All your favorite songs and artists.': 'Toutes vos chansons et vos artistes préférés.',
    'Show Less': 'Afficher moins',
    'Show More': 'En voir plus',
    Schedule: 'Horaire',
    'Listen offline': 'Écouter hors ligne',
    'Unlimited skips': 'Sauts illimités',
    'Save songs from radio to playlists': "Sauvegarder des chansons de radio dans des listes d'écoute",
    'Instantly replay songs from radio': 'Rejouer instantanément des chansons de la radio',
    'Create unlimited playlists': "Créez autant de listes d'écoute que vous voulez",
    'Play millions of songs on demand': 'Jouez des millions de chansons sur demande',
    'Now you can create your own playlists with All Access!': "Maintenant vous pouvez créer vos propres listes d'écoute avec Tout Accès!",
    'Start Free 30 Day Trial': "Essayez une période d'essai gratuite de 30 jours",
    'Subscribe to All Access': 'Inscrivez-vous à Tout Accès',
    'is now better than ever!': 'est maintenant meilleur que jamais!',
    'All your favorite music, podcasts, and radio stations available for free. Listen to thousands of live radio stations or create your own artist stations and playlists. Get the latest music and trending news, from your favorite artists and bands.': 'Tous vos balados, votre musique et vos stations radio disponibles gratuitement. Écoutez des milliers de stations en direct ou créez votre propre station artiste. Trouvez les dernières nouvelles et tendances musicales, de vos artistes et groupes préférés. ',
    'Stream thousands of the best live radio stations and custom artist stations for FREE - all in one app.': 'Écoutez des milliers de stations de radio en diffusion continue gratuitement - tout dans une appli.',
    'Stream thousands of the best live radio stations for FREE, plus unlimited music on demand - all in one app.': 'Écoutez des milliers de stations de radio gratuitement, en plus de musique illimitée sur demande - tout dans une appli.',
    'Stream thousands of the best live radio stations for FREE - all in one app.': 'Écoutez des milliers de stations de radio gratuitement - tout dans une appli.',
    'All Genres': 'Tous les genres',
    Featured: 'En vedette',
    Popular: 'Populaire',
    'You are now logged in as {profileName}': 'Vous êtes connecté en tant que {profileName}',
    Artist: 'Artiste',
    'Song by': 'Chanson par',
    Song: 'Chanson',
    'Live Station': 'Station en direct',
    'Album by': 'Album par',
    Album: 'Album',
    'Email or password is invalid. Please try again.': 'Courriel ou mot de passe invalide. Veuillez réessayer.',
    'There was an issue playing the audio, please check your connection and try again.': 'Il y a eu un problème lors de la lecture de votre musique, vérifiez votre connection et ré-essayez',
    'There was a connection problem. Please reconnect and try again.': 'Il y a eu un problème de connexion. Veuillez vous reconnecter et réessayer.',
    'See More': 'Plus',
    'all access': 'tout accès',
    'Best Playlists': "Meilleures listes d'écoute",
    'concert pictures': 'photos de concerts',
    'create playlists': "créer des listes d'écoute",
    'custom radio station': 'station radio personnalisée',
    'event pictures': "photos d'événements",
    'favorite music': 'musique préférée',
    'Music Playlists': "Listes d'écoute musicales",
    'my music': 'ma musique',
    'Now Playing': 'Vous écoutez',
    'On Demand': 'Sur demande',
    'Online Playlists': "Listes d'écoute en ligne",
    'paid music': 'musique achetée',
    'paid songs': 'chansons achetées',
    'personal radio station': 'station radio personnalisée',
    'personalized radio station': 'station radio personnalisée',
    'play songs': 'jouer des chansons',
    'Top Playlists': "Listes d'écoute les plus populaires",
    'recent photos': 'photos récentes',
    'recent pictures': 'photos récentes',
    'saved music': 'musique sauvegardée',
    'saved songs': 'chansons sauvegardées',
    'top playlists': "meilleures listes d'écoute",
    App: 'Appli',
    Bands: 'Groupes',
    Best: 'Meilleur',
    Discover: 'Découvrir',
    Download: 'Télécharger',
    Free: 'Gratuit',
    Influencers: 'Influenceurs',
    Influences: 'Influences',
    Internet: 'Internet',
    Listen: 'Écouter',
    Live: 'En direct',
    Music: 'Musique',
    Online: 'En ligne',
    Personalized: 'Personnalisé',
    photos: 'photos',
    pictures: 'photos',
    Radio: 'Radio',
    Related: 'Connexe',
    Similar: 'Similaire',
    Station: 'Station',
    Stations: 'Stations',
    stream: 'diffusion',
    Streaming: 'En diffusion',
    Talk: 'Parler',
    Top: 'Meilleur',
    January: 'Janvier',
    February: 'Février',
    March: 'Mars',
    April: 'Avril',
    May: 'Mai',
    June: 'Juin',
    July: 'Juillet',
    August: 'Août',
    September: 'Septembre',
    October: 'Octobre',
    November: 'Novembre',
    December: 'Décembre',
    Jan: 'Jan',
    Feb: 'Fev',
    Mar: 'Mar',
    Apr: 'Avr',
    Jun: 'Jun',
    Jul: 'Jul',
    Aug: 'Août',
    Sep: 'Sep',
    Oct: 'Oct',
    Nov: 'Nov',
    Dec: 'Déc',
    'Password must have': 'Le mot de passe doit comprendre',
    'iHeart: Listen to Free Radio Stations & Music Online | iHeart': 'iHeart : Écoutez des stations de radio et de la musique en ligne gratuitement | iHeart',
    '1 Song': '1 chanson',
    'Listen to music you love! Create a custom radio station based on your favorite album by {artistName} on iHeartRadio!': 'Écoutez la musique que vous aimez! Créez une station de radio personnalisée avec votre album préféré de {artistName} sur iHeartRadio!',
    'Stream Free Music from Albums by {artistName}': "Écoutez de la musique gratuite en difussion d'albums par {artistName}",
    'Create Artist Radio Stations': "Créez des stations radio d'artistes",
    'Popular {genre} Artists': 'Artistes populaires de {genre}',
    'Artist Radio gives you the power to create custom stations from your favorite songs, albums or musical artists. Listen for free on iHeart!': "La radio d'artiste vous donne le pouvoir de créer des stations personnalisées à partir de vos chansons, albums ou artistes préférés. Écoutez gratuitement sur iHeart!",
    'Create a personalized custom music station from thousands of {name} artists you love, only on iHeart!': "Créez une station de musique personnalisée avec des milliers d'artistes {name} que vous aimez, seulement sur iHeart!",
    'Create Custom Radio Stations on Artist Radio!': 'Créez des stations personnalisées dans Radio artiste',
    'Listen to the Best {name} Music for Free on iHeart': 'Écoutez la meilleure musique  {name} gratuitement sur iHeart',
    '{artistName}': '{artistName}',
    '{name}, News, Music, Headlines, iHeartRadio, iHeart, Radio': '{name}, Nouvelless, Musique, Manchettes, iHeartRadio, iHeart, Radio',
    'Latest {name} news from iHeart': 'Les dernières nouvelles {name} de iHeart',
    'Read the latest News on {name} Online': 'Lisez les dernières nouvelles sur  {name} en ligne',
    Biography: 'Biographie',
    'Similar Artists': 'Artistes similaires',
    'Latest Release': 'Dernières sorties',
    'Popular On': 'Populaire sur',
    "This artist doesn't have any top songs.": "Cet artiste n'a pas de chansons sur les palmarès.",
    'Top Songs': 'Les meilleures chansons',
    'Stream Music from Artists Like {artistName}': "Écoutez de la musique en diffusion d'artistes comme {artistName}",
    "Listen to music you'll love! Create a custom radio station from your favorite songs by {artistName} on iHeart.": 'Écoutez la musique que vous aimez! Créez une station de radio personnalisée avec vos chansons préférées de {artistName} sur iHeart.',
    'Stream Free Songs by {artistName} & Similar Artists': 'Écoutez des chansons de {artistName} gratuitement en diffusion et des artistes similaires',
    'Almost there! Verify your zipcode to unlock your spot on the Family Plan': 'Presque arrivé! Vérifier votre code postal pour prendre votre place sur le plan familial',
    'In order to add you to the plan, we need to make sure that you live at the same address as the plan owner. Please input your zipcode below to verify and unlock your account.': "Pour vous ajouter au plan, nous devons nous assurer que vous vivez à la même adresse que l'abonné principal du plan. Veuillez entrer votre code postal ci-dessous pour vérifier et débarrer votre compte.",
    'Household Zipcode': 'Code postal de la maison',
    'e.g 90210...': 'ex : H1H 1H1...',
    'help site': 'site de soutien',
    "It looks like you're already subscribed": 'Il semble que vous soyez déjà abonnés',
    "You must cancel your active subscription before you can continue. You can find out how to cancel your subscription at {supportLink}. Once you've cancelled your current subscription plan you will need to wait until your current subscription plan ends.": "Vous devez annuler votre abonnement actif avant de continuer. Vous pouvez aller à {supportLink} pour apprendre comment annuler votre abonnement. Une fois , votre plan d'abonnement présent devra expirer avant de le changer.",
    'If you have any questions, please {helplink} contact us.': 'Si vous avez des questions, vous pouvnez nous contacter à :  {helplink} .',
    'Please create a free account, or sign in': 'Veuillez créer un compte gratuit ou vous connecter',
    'To accept the Family Plan invitation, you must be logged in.': "Pour accepter l'invitation au Plan familial, vous devez être connecté.",
    'Welcome to Your Favorites Radio, a personalized station containing your favorite songs and artists. The more you thumb up and save stations on iHeart, the better your listening experience will be.': "Bienvenue à votre station de chansons préférées, une station personnalisée avec vos chansons et artistes préférés. Plus vous sauvegardez des stations sur iHeart, plus plaisante sera votre expérience d'écoute.",
    'Listen to {title}. Thumb up songs to add them to your own Your Favorites Radio station. Find it under My Stations.': "Écoutez {title}. Donnez un pouce en l'air aux chansons que vous voulez ajouter à votre station de chansons préférées. Trouvez-la sous Mes stations.",
    'Featured Artists On This Station': 'Artistes en vedette sur cette station',
    "{name}'s Favorites Radio": 'La radio des chansons préférées de {name}',
    'iHeartRadio Mobile Apps': 'Applis mobiles iHeartRadio',
    'Made for You': 'Fait pour vous',
    'Listen to Stations For You': 'Écoutez des stations faites juste pour vous',
    'Change genre preferences {prefLink}': 'Changer mes préférences de genre {prefLink}',
    here: 'ici',
    'iHeart: Listen to Free Radio Stations & Music Online': 'iHeart : Écoutez des stations de radio et de la musique en ligne gratuitement',
    'All Your Favorite Music. All Your Favorite Stations. All FREE. Listen to thousands of live stations or create your own artist stations. Let the music play!': 'Toute votre musique préférée. Toutes vos stations préférées. Complétement gratuit. Écoutez des milliers de stations en direct ou créez votre propre station artiste. Que la musique joue!',
    'Improve Your Recommendations': 'Améliorer vos recommendations',
    'Change Preferences': 'Changer mes préférences',
    'Change Genre Preferences': 'Changer mes préférences de genres',
    'Find Stations by Genre': 'Trouvez des stations par genre',
    'Finding your ideal station is quick and easy. Search for stations by genre & stream them online for free on iHeart.': 'Trouver votre station idéale est facile et rapide. Cherchez pour des stations par genre et jouez-les en ligne gratuitement sur iHeart.',
    'Discover Music and Radio Stations by Genre on iHeart': 'Découvrez de la musique et des stations de radio par genre sur iHeart',
    'Pick Genre': 'Choisissez un genre',
    'Tell us all the genres you like.': 'Choisissez vos genres préférés.',
    "We'll suggest stations just For You.": 'Nous vous suggérerons des stations juste pour vous.',
    'Get Stations': 'Trouvez-moi des stations',
    'Find and stream {genreName} music stations for free, only on iHeart.': 'Trouvez et diffusez des stations de musique  {genreName} gratuitement, seulement sur iHeart.',
    'Listen to {genreName} Radio Stations for Free': 'Écoutez des stations de {genreName} gratuitement',
    'Find {genreName} Stations': 'Trouvez des stations de {genreName}',
    'Featured {genreName} Stations': 'Stations de {genreName} en vedette',
    'Top {genreName} Stations': 'Meilleures stations de {genreName}',
    'Top {genreName} Artists': 'Meilleurs artistes de {genreName}',
    'More Genres': 'Plus de genres',
    'Latest News': 'Dernières nouvelles',
    'Partner Content': 'Contenu partenaire',
    'All Cities': 'Toutes les villes',
    'for {currentGenre}': 'pour {currentGenre}',
    'in {currentMarket}': 'à {currentMarket}',
    'near you': 'près de vous',
    'Find top stations {genreFilterDescription} {marketFilterDescription} {noFiltersMessage} ({numStations})': 'Trouvez les meilleures stations {genreFilterDescription} {marketFilterDescription} {noFiltersMessage} ({numStations})',
    'Listen to Live Radio': 'Écoutez la radio en direct',
    'No stations currently available for this filter': 'Aucune station disponible en ce moment avec ce filtre',
    'Listen to the Best Live Radio Stations in {countryAbbreviation}': 'Écoutez les meilleures stations en direct au {countryAbbreviation}',
    'Listen to {genreName} Radio Stations in {city}': 'Écoutez des stations {genreName} à {city}',
    'Listen to Top Radio Stations in {city}, Free': 'Écoutez les meilleures stations radio à {city}, gratuitement',
    'Live {genreName} Radio Stations in {countryAbbreviation}': 'Stations radio de {genreName} en direct au {countryAbbreviation}',
    'Listen to hundreds of the best live radio stations{countryName}, for free! Search for stations near you & around the country.': 'Écoutez des centaines des meilleures stations en direct à travers le {countryName}, gratuitement! Cherchez pour des stations près de vous et à travers le pays.',
    'Listen to the Best Live Radio{country} for Free on iHeart.': 'Écoutez les meilleures stations en direct au {country}, gratuitement sur iHeart.',
    'Stream the best live {genreName} radio stations in {city} online for free, only on iHeart!': 'Faites jouer les meilleures stations en direct de {genreName} à {city} en ligne gratuitement, seulement sur iHeart!',
    'Listen to Top {genreName} Radio Stations in {city}': 'Écoutez les meilleures stations de {genreName} à {city}',
    'Listen to the best live radio stations in {city}. Stream online for free, only on iHeart!': 'Écoutez les meilleures stations radio en direct à {city}, faites les jouer gratuitement, seulement sur iHeart!',
    'Listen to Top Radio Stations in {city} for Free': 'Écoutez les meilleures stations radio à {city} gratuitement',
    'Stream the best live {genreName} radio stations in {countryAbbreviation} for free on iHeart.': 'Faites jouer la station radio de {genreName} en direct au {countryAbbreviation} gratuitement sur iHeart.',
    'Listen to Free, Live {genreName} Radio Stations in {countryAbbreviation} on iHeart': 'Écoutez gratuitement les stations de {genreName} en direct au {countryAbbreviation} sur iHeart',
    'Contests and Promotions': 'Concours et promotions',
    'If you like {stationName}...': 'Si vous aimez {stationName}...',
    'Similar Stations': 'Stations similaires',
    '{stationName}, {stateAbbreviation}, {city}, {genre}': '{stationName}, {stateAbbreviation}, {city}, {genre}',
    Genre: 'Genre',
    Location: 'Emplacement',
    'On Air Schedule': 'Horaire de diffusion en direct',
    'Share Song': 'Partager la chanson',
    'Get the latest music news - rock, pop, hip-hop, rock, alternative, and country music news from iHeart. Get the latest music and trending news, from your favorite artists and bands.': "Recevez les dernières nouvelles musicales - sur le rock, pop, hip-hop, rock, alternatif et le country d'iHeartRadio. Recevez les dernières nouvelles, sur vos artistes et vos groupes préférés.",
    'Breaking Music News, Celeb and Entertainment News and Gossip': "Les nouvelles de l'heure sur la musique, les célébrités, le divertissement et les potins",
    'Advertise With Us': "Vous aimeriez de l'espace de publicité?",
    'Discover thousands of top playlists with songs handcrafted by your favorite personalities and music experts on iHeartRadio.': "Découvrez des milliers de listes d'écoute avec des chansons spécialement choisies par vos personnalitées préférés et des experts musicaux sur iHeartRadio.",
    'Listen to the Best Online Music Playlists': "Écoutez les meilleures listes d'écoute musicales en ligne",
    'Recommended For You': 'Recommandés pour vous',
    'Moods & Activities': 'Ambiance et activités',
    Decades: 'Décennies',
    'Featured Playlists': "Listes d'écoute en vedette",
    By: 'Par',
    'Your songs shuffled with songs we think you’ll like. The more songs you add, the better our picks get.': "Vos chansons sont mélangées à des chansons que nous pensons que vous aimerez. Plus vous ajoutez de chansons, plus nos choix s'amélioreront.",
    'Introducing a new playlist experience': "Introduction d'une nouvelle expérience de liste d'écoute",
    'Upgrade for this playlist': "Passez à un plan premium pour cette liste d'écoute",
    'Song saved to Your Library': 'Chanson sauvegardée à votre librairie',
    'Song removed from playlist': "Chanson retirée de la liste d'écoute",
    'Go to Artist': "Allez à l'artiste",
    'Go to Album': 'Allez aux albums',
    'Remove from Playlist': "Supprimer de mes listes d'écoute",
    SONG: 'CHANSON',
    'ALBUM TITLE': 'TITRE DE L’ALBUM',
    ARTIST: 'ARTISTE',
    DURATION: 'DURÉE',
    Done: 'Terminé',
    'Discover and stream the best {categoryTitle} playlists, handcrafted by the music experts at iHeart.': "Découvrez et écoutez les meilleures listes d'écoute de {categoryTitle}, créées par des experts musicaux à iHeart.",
    '{categoryTitle}, {categoryTitle} Playlists, Stream {categoryTitle} Music, Play {categoryTitle} Songs, Top {categoryTitle} Playlists, Best { categoryTitle} Playlists': "{categoryTitle}, Listes d'écoute de {categoryTitle}, Écouter de la musique {categoryTitle} , Jouer des chansons {categoryTitle}, Meilleures listes d'écoute {categoryTitle}, Top listes d'écoute { categoryTitle}",
    'Top {categoryTitle} Playlists; Find Your Perfect Music Mix': "Meilleures listes d'écoute {categoryTitle}; Trouvez votre mix musical parfait",
    Host: 'Animateur et créateur',
    'iHeart Podcast Networks': 'Réseaux de balados iHeart',
    '{name}, Blog, News, Music, Headlines, iHeartRadio, iHeart, Radio': '{name}, Nouvelles, Musique, Manchettes, iHeartRadio, iHeart, Radio',
    '{name} Blog, News & Videos': 'Blogs, nouvelles et vidéos de {name} ',
    'Go behind the scenes, get exclusive stories and learn more about the show!': "Allez dans les coulisses, recevez des nouvelles exclusives et apprenez-en plus sur l'émission!",
    Played: 'Joué',
    'Listen to the best {categoryName} radio shows, free and on demand, only on iHeart.': 'Écoutez les meilleures émission de radio {categoryName}, gratuitement et sur demande, seulement sur iHeart.',
    'Listen to the Best Free {categoryName} Podcasts': 'Écoutez les meilleurs balados gratuits {categoryName}',
    'On Demand, On-Demand, Show, Shows, Personalities, Programs, Listen, iHeartRadio, iHeart': 'Sur demande, Sur-demande, Émission, Émissions, Personnalités, Programmes, Écouter, iHeartRadio, iHeart',
    'Discover Top Podcasts': 'Découvrez les balados les plus populaires',
    'Listen to the latest episodes from top podcasts. Get news, sports & lifestyle info from your favorite personalities for free!': "Écoutez les derniers épisodes des meilleurs balados. Recevez des nouvelles et de l'information sur les sports et de lifestyle de vos personnalités préférées gratuitement!",
    'Listen to the Best Podcasts Online for Free on iHeart': 'Écoutez les meilleurs balados en ligne gratuitement sur iHeart',
    'Listen to the Best Podcasts & Shows Online, Free': 'Écoutez les meilleurs balados et émissions en ligne, gratuitement',
    'Talk, Show, Host, Personality, On Demand, Topic, Category, Health, News, Politics, Comedy, Station, Internet, Online, iHeartRadio, iHeart': 'Parlé, Émission, Hôte, Personnalité, Sur demande, Sujet, Catégorie, Santé, Nouvelles, Politique, Comédie, Station, Internet, En ligne, iHeartRadio, iHeart',
    'Stream Top Podcasts': 'Écoutez les meilleurs balados',
    'All Topics': 'Tous les sujets',
    '{name} Podcasts': 'Balados {name} ',
    Topics: 'Sujets',
    'All Episodes': 'Tous les épisodes',
    "These episodes aren't here yet...": 'Ces épisodes ne sont pas encore arrivés...',
    "Follow this podcast to be notified when they're ready": "Suivez ce balado pour recevoir des notifications lorsqu'ils sont prêts",
    'Share Podcast': 'Partager le balado',
    'The Latest News': 'Dernières nouvelles',
    'Listen to {episodeTitle} | {showTitle} | Podcasts': 'Écoutez le balado {episodeTitle} | {showTitle}',
    '{episodeTitle},{showTitle},Talk,Radio,Listen,On Demand,iHeartRadio,iHeart': '{episodeTitle},{showTitle},Parler,Radio,Écouter,Sur demande,iHeartRadio,iHeart',
    '{title}, Talk, Radio, Listen, On Demand, iHeartRadio, iHeart': '{title}, Parler, Radio, Écouter, Sur demande, iHeartRadio, iHeart',
    Episodes: 'Épisodes',
    'Episode Transcript': "Transcription de l'épisode",
    'Available transcripts are automatically generated. Complete accuracy is not guaranteed.': "Les transcriptions disponibles sont générées automatiquement. Leur fidélité n'est pas garantie.",
    'Error Loading Transcript': 'Erreur lors du chargement de la transcription',
    'We had a problem rendering this page, please try refreshing your browser': 'Un problème est survenu lors du chargement de cette page, veuillez rafraîchir celle-ci dans votre fureteur',
    'Refresh the page': 'Rafraîchir la page',
    'Report this issue': 'Signaler ce problème',
    'Popular Podcasts': 'Balados populaires',
    'Stream the best podcasts from your favorite stations': 'Écoutez les meilleurs balados en diffusion directe de vos stations préférées',
    'Follow Us On': 'Suivez-nous sur ',
    'Show Links': 'Montrer les liens',
    'Settings | See Your Music Profile': 'Réglages | Voyez votre profil musical',
    'Log in and see which live radio stations and custom artist stations you love. You can also view your listening history. All free.': "Connectez-vous et venez voir quelles stations de radio en direct ou d'artistes que vous aimez. Vous pouvez aussi voir votre historique musicale. Tout gratuitement.",
    Connections: 'Connexions',
    Unlink: 'Délier',
    Link: 'Lier',
    'Genre added.': 'Genre ajouté.',
    'Genre removed.': 'Genre supprimé.',
    "You've added {genreName} to your genres.": 'Vous avez ajouté {genreName} à vos genres.',
    "You've removed {genreName} from your genres.": 'Vous avez supprimé {genreName} de vos genres.',
    'Your preferences have been changed.': 'Vos préférences ont été changées.',
    'Not provided': 'Non fourni',
    'Publish my listening activity to my Facebook music dashboard': "Publier mes activités d'écoute sur mon dashboard musical Facebook",
    'Update your password, genre selection, and modify your subscription below.': 'Ci-dessous vous pourrez mettre votre mot de passe et votre sélection de genre à jour ou modifiez votre inscription.',
    "You're currently signed in with a social account, add an email and password to have full access to your account": "Vous êtes connecté par un compte de réseau social, ajoutez un courriel et un mot de passe pour avoir l'accès complet à votre compte",
    'Add New Email': 'Ajouter une nouvelle adresse courriel',
    'Contact Information': 'Coordonnées',
    'Update your contact information to personalize your experience and easily enter iHeart contests and promotions.': 'Mettez à jour vos coordonnées pour personnaliser votre expérience et participez facilement aux concours et aux promotions iHeart',
    'First and Last Name': 'Prénom et nom de famille',
    'Add your full name': 'Ajouter votre nom complet',
    'Add your postal code': 'Ajouter votre code postal',
    'My Genres': 'Mes genres musicaux',
    'Tell us all your favorite genres so we can make iHeart perfect for you.': "Dites-nous quels sont vos genres préférés et iHeart s'adaptera parfaitement à vos goûts.",
    'View Recommendations': 'Voir les recommendations',
    'Billing History': 'Historique de paiement',
    'Subscribe Again': "S'abonner à nouveau",
    'Update Payment': 'Mettre votre paiement à jour',
    Unknown: 'Inconnu',
    'Unknown Plan Description': 'Description de plan inconnu',
    'iHeart Family Plan': 'Plan familial iHeart',
    'You are enjoying an unlimited music experience with an iHeart Family Plan.': "Vous bénéficiez d'une expérience musicale illimitée en vous abonnant au plan familial iHeart.",
    'iHeart All Access': 'Tout Accès iHeart',
    'You are enjoying an unlimited music experience by subscribing to iHeart All Access.': "Vous bénéficiez d'une expérience musicale illimitée en vous abonnant à iHeart Tout Accès.",
    'iHeart Plus': 'iHeart Plus',
    'You are subscribed to iHeart Plus. Switch to iHeart All Access to enjoy an unlimited music experience on your computer.': "Vous êtes abonné à iHeart Plus. Changer pour iHeart Tout Accès pour bénéficier d'une expérience musicale illimitée sur votre ordinateur.",
    'iHeart Free': 'iHeart gratuit',
    'Subscribe to enjoy unlimited music.': 'Abonnez-vous pour bénéficier de musique illimitée.',
    'Your subscription ends on {subscriptionEndsString}.': 'Your abonnement finit le {subscriptionEndsString}.',
    'Family Members': 'Membres de famille',
    Subscription: 'Abonnement',
    'Your Favorite Songs | Log In & See': 'Vos chansons préférées | Se connecter pour voir',
    'Log in or sign up and find the songs you love here, only on iHeart.': 'Connectez-vous ou inscrivez-vous et trouvez les chansons que vous aimez, seulement sur iHeart.',
    'iHeart Codes Coupons and Special Offers for Unlimited Music': 'Coupons et offres spéciales iHeart pour de la musique illimitée',
    'Find iHeart Radio special offers, codes and coupons for unlimited music on demand and all access to iHeart Plus. These special offers provide access to millions of songs, thousands of radio and artists stations with unlimited skips and offline listening!': "Trouver des offres spéciales d'iHeart, des codes et des coupons pour de la musique illimité et pour l'accès complet à iHeart Plus. Ces offres spéciales donnent accès à des millions de chansons, des milliers de stations radio et d'artistes avec des sauts illimitiés et de l'écoute hors ligne.",
    'There was an error validating your request.': 'Il y a eu une erreur lors de la validation de votre requête.',
    'Please enter a valid email address': 'Veuillez entrer une adresse courriel valide',
    'Please fix the following errors:': 'Veuillez corriger les erreurs suivantes :',
    'Start Your Free 30 Day Trial': "Commencez votre période d'essai gratuite de 30 jours",
    'Start Your Family Plan': 'Commencez votre plan familial',
    'Sign up for iHeart All Access Annual Plan': "S'abonner au Plan annuel Tout Accès iHeart",
    View: 'Afficher',
    'Offer Terms': "Conditions de l'offre",
    'Email Address': 'Adresse courriel',
    'Enter email address': 'Entrer une adresse courriel',
    'Card Details': 'Détails de la carte',
    'Zip / Postal Code': 'Code postal',
    'Enter Here': 'Saisir ici',
    'Yes, please automatically renew my subscription annually (subject to {termsLink})': 'Oui, veuillez renouveller automatiquement mon abonnement à chaque année (conformément à {termsLink})',
    'terms & conditions': 'conditions',
    'Charges will appear on your credit card statement as "IHEART BILLING"': 'Les frais apparaissent sur votre relevé de carte de crédit comme "IHEART BILLING"',
    'You can cancel at any time by going to "Settings" and following the cancellation instructions. By clicking "Subscribe" below, you agree to the conditions above and the iHeart ': "Vous pouvez annuler en tout temps en allant dans les Réglages en suivant les instructions d'annulation. En cliquant sur « S'abonner » ci-dessous, vous acceptez les conditions ci-dessus",
    Processing: 'En traitement',
    'Enter your email address below to log in by email or reset your password': 'Entrez votre adresse courriel ci-dessous pour vous connecter par courriel ou pour réinitialiser votre mot de passe.',
    email: 'courriel',
    Send: 'Envoyer',
    'Don’t use common passwords, like “Password123!”': "N'utilisez pas de mots de passe communs, comme “Password123!”",
    'Your password reset link is invalid or expired. Please try': 'Votre lien de réinitialisation du mot de passe est invalide ou expiré. Veuillez essayer de',
    'resetting your password': 'réinitialiser votre mot de passe',
    'again.': 'de nouveau.',
    'Create a New Password': 'Créer nouveau mot de passe',
    'Your password reset link has expired': 'Votre lien de réinitialisation du mot de passe est expiré',
    'An unknown error occured. Please try again later': "Une erreur inattendue s'est produite. Veuillez réessayer plus tard.",
    'Your password has been updated': 'Votre mot de passe a été mis à jour.',
    'Confirm New Password': 'Confirmer le nouveau mot de passe',
    Create: 'Créer',
    'Custom radio stations based off your favorite artists and bands.': 'Des stations radio personnalisées créées à partir de vos artistes et groupes préférés.',
    'View More Artists': "Voir plus d'artistes",
    'Bring iHeart with you anywhere': "Amenez iHeart avec vous n'importe où",
    'The free iHeart app is available on over 200 platforms, including Web, iOS, Android, Alexa, automobiles and more.': "L'appli gratuite iHeart est disponible sur plus de 200 plateformes, incluant Web, iOS, Android, Alexa, les plateformes voiture et plus.",
    'Download on the': 'Télécharger sur le',
    'Download on': 'Télécharger sur',
    'View All Apps': 'Voir toutes les applis',
    'Upcoming Events': 'Événements à venir',
    'Listen to Your Favorite Music, Podcasts, and Radio Stations for Free! – iHeart': 'Écoutez votre musique, votre radio et vos balados préférés en ligne gratuitement - iHeart',
    'iHeart. All your favorite music, podcasts, and radio stations available for free. Listen to thousands of live radio stations or create your own artist stations and playlists. Get the latest music and trending news, from your favorite artists and bands.': 'iHeart. Tous vos balados, votre musique et vos stations radio disponible gratuitement. Écoutez des milliers de stations en direct ou créez votre propre station artiste. Trouver les dernières nouvelles et tendances musicales, de vos artistes et groupes préférés. ',
    'Get Started Free': 'Commencer gratuitement',
    'All your favorite music, radio and podcasts, all free.': 'Toute votre musique, vos radios et vos balados préférés, tout cela gratuitement.',
    'Your browser does not support video playback': 'Votre navigateur ne supporte pas la lecture de vidéos',
    'The best live stations from around the country, any time, any place.': "Les meilleures stations radio du pays, à écouter n'importe quand, n'importe où",
    'View More Live Stations': 'Voir plus de stations en direct',
    'New on iHeart': 'Nouveau sur iHeart',
    'Latest Music News': 'Les dernières nouvelles musicales',
    'View More Music News': 'Voir plus de nouvelles musicales',
    'Popular and exclusive podcasts in a variety of genres and topics.': 'Des balados populaires et exclusifs de tout genre et sur tous les sujets.',
    'View More Podcasts': 'Voir plus de balados',
    '{count} song{plural}': '{count} chanson{plural}',
    'Browse Artists': 'Parcourir les artistes',
    'Need to find a great new album?': "Besoin d'un excellent nouvel album?",
    'Any albums you save will show up here': 'Les albums que vous sauvegardez apparaîtront ici',
    'Need to find an artist you love?': 'Besoin de trouver un artiste que vous aimez?',
    'Any artists you follow will show up here': 'Les artistes que vous suivez apparaîtront ici',
    'Access your music library and create unlimited playlists with iHeart All Access': 'Accédez à votre librairie musicale et créez autant de playlists que vous voulez avec Tout Accès iHeart',
    'song{pluralSongs}': 'chanson{pluralSongs}',
    by: 'par',
    'Browse Playlists': "Parcourir les listes d'écoute",
    'Need a playlist to fit your mood?': "Besoin d'une liste d'écoute qui va avec votre humeur?",
    'Any playlists you follow will show up here': "Les listes d'écoute que vous suivez apparaîtront ici",
    'Browse Podcasts': 'Parcourir les balados',
    'Need a great new podcast to start?': "Besoin d'un excellent nouveau balado?",
    'Any podcasts you follow will show up here': "Les listes d'écoute que vous suivez apparaîtront ici",
    'Any radio, music, or podcasts you listen to will show up here': 'Tout balado, station ou musique que vous écoutez apparaîtra ici',
    'Browse Stations For You': 'Parcourir les stations choisies pour vous',
    'Any podcasts you listen to will show up here': 'Tout balado que vous écoutez apparaîtra ici',
    'Need help finding something new?': 'Besoin de trouver quelque chose de neuf?',
    'Remove station': 'Supprimer la station',
    '{name} removed from Your Library': '{name} effacé de votre librairie',
    'Followed Stations': 'Stations suivies',
    'Browse Live Radio': 'Parcourir la radio en direct',
    'Browse Artist Radio': "Parcourir la radio d'artiste",
    'Need help finding stations to save?': "Besoin d'aide pour trouver des stations à sauvergarder?",
    'Your followed live and artist radio stations will show up here': "Vos stations en direct et d'artistes sauvegardées apparaîtront ici",
    '{albumName} removed from Your Library': '{albumName} effacé de votre librairie',
    Remove: 'Supprimer',
    '{artistName} removed from Your Library': '{artistName} effacé de votre librairie',
    'ARTIST IN YOUR LIBRARY': 'ARTISTE DANS VOTRE LIBRAIRIE',
    'Add to playlist': "Ajouter à la liste d'écoute",
    'Play Now': 'Jouer maintenant',
    '{songTitle} removed from Your Library': '{songTitle} effacée de votre librairie',
    'Need to find a new song?': 'Vous voulez trouver une nouvelle chanson?',
    'Any song you save will show up here': 'Toute chanson sauvegardée sera affichée ici',
    'Playlist Radio': "Radio de listes d'écoute",
    'View All': 'Afficher tout',
    Copied: 'Copié',
    'Read more on iHeartRadio': 'Lisez-en plus sur iHeartRadio',
    'Link Copied!': 'Lien copié!',
    'Copy Link': 'Copier le lien',
    'Code Copied!': 'Code copié!',
    Embed: 'Intégrer',
    'Follow on iHeartRadio': 'Suivre sur iHeartRadio',
    'Playback not supported by device or browser.': "La lecture n'est pas prise en charge par l'appareil ou le fureteur.",
    'Method {method} not supported.': "La méthode {method} n'est pas prise en charge.",
    'View iHeart.com': 'Voir iHeart.com',
    "Oops, that wasn't supposed to happen.": "Oups, cela n'était pas supposé arriver.",
    "Oops, this feature isn't supported yet.": "Oups, cette fonctionnalité n'est pas encore prise en charge.",
    'Oops! We had problems playing this stream.': 'Oups, nous avons des problèmes avec cette diffusion.',
    'This station is not currently available in your country.': "Cette station n'est pas offerte dans votre pays.",
    Retry: 'Ré-essayer',
    'View Troubleshooting': 'Voir la section de dépannage',
    hello: 'bonjour',
    'my friend': 'mon ami',
    'Share this live station': 'Partager cette station en direct',
    'Create your own on iHeart': 'Créez votre propre station sur iHeart',
    "We're sorry! This playlist is not available in your country.": "Désolés, cette liste d'écoute n'est pas offerte dans votre pays!",
    'Playlist Not Available': "Liste d'écoute non disponible",
    'This playlist is unsupported in your current country.': "Cette liste d'écoute n'est pas prise en charge dans votre pays.",
    'Playback Unsupported In Current Country': "La lecture n'est pas prise en charge dans le pays actuel",
    'Featured artists in this playlist': "Les artistes en vedette dans cette liste d'écoute",
    'View Playlist': "Afficher la liste d'écoute",
    'Share this episode': 'Partager cet épisode',
    'Listen to more episodes': "Écouter plus d'épisodes",
    'This show has no episodes available yet.': "Cette émission n'a pas encore d'épisodes.",
    'Share this podcast': 'Partager le balado'
  },
  options: {
    plural_rule: 'n > 1',
    plural_number: '2'
  }
};

export { translations$1 as activate, translations as www };
